<template>
  <AdminLayout>
    <div class="mx-auto max-w-7xl px-4 pt-8 sm:px-6 lg:px-8">
      <div class="space-y-6">
        <div class="flex items-center gap-x-2">
          <h1 class="text-2xl font-bold leading-7 text-black">
            Amazon Gift Checkout
          </h1>
        </div>

        <div class="space-y-4">
          <AmazonTable />
        </div>
      </div>
    </div>
  </AdminLayout>
</template>

<script>
import AdminLayout from "@/layouts/Admin.vue";
import AmazonTable from "../../components/admin/AmazonTable.vue";

export default {
  components: {
    AdminLayout,
    AmazonTable,
  },
  data() {
    return {};
  },
};
</script>
