<template>
  <div class="grid grid-cols-6 gap-y-3">
    <div
      @click="$emit('clicked-palette', palette)"
      v-for="(palette, index) in colorPalettes"
      :key="index"
      class="flex h-12 w-12 cursor-pointer rounded-full border border-black transition-opacity hover:opacity-70"
    >
      <div
        v-show="
          currentLayout == 'split' ||
          currentLayout == 'split 2' ||
          currentLayout == 'center'
        "
        v-for="(color, index2) in palette"
        :key="index2"
        class="h-full w-1/2"
        :class="index2 == 'background' ? 'rounded-l-full' : 'rounded-r-full'"
        :style="{ background: color }"
      ></div>
      <div
        v-show="currentLayout == 'full' && index2 == 'paragraph'"
        v-for="(color, index2) in palette"
        :key="index2"
        class="h-full w-full rounded-full"
        :style="{ background: color }"
      ></div>
    </div>
  </div>
</template>

<script>
import colors from "../../mixins/colors.js";
export default {
  props: ["event"],
  mixins: [colors],
  emits: ["clicked-palette"],
};
</script>
