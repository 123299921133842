<template>
  <div class="onboarding-list">
    <div class="bg-white" v-loading.fullscreen.lock="loading">
      <div class="">
        <div class="">
          <div>
            <h2 class="text-2xl font-bold tracking-tight text-gray-900">
              {{ `Hey ${event.name_host1} & ${event.name_host2} 👋` }}
            </h2>
            <p class="mt-1 text-gray-500">
              Here are some errands to run before your wedding...
            </p>
          </div>
          <div class="mt-4">
            <dl class="space-y-1">
              <div v-for="(item, index) in items" :key="item.id">
                <dt
                  @click="actionTask(item)"
                  :class="
                    item.completed
                      ? 'pointer-events-none border-0 text-gray-500 line-through'
                      : ''
                  "
                  class="inline-block text-gray-900"
                >
                  {{ index + 1 }}.
                  <span
                    class="pb-1 transition-opacity hover:opacity-50"
                    :class="
                      item.completed
                        ? 'pointer-events-none border-0 text-gray-500 line-through'
                        : 'cursor-pointer border-b border-red-300'
                    "
                  >
                    {{ item.link }}
                  </span>
                  {{ item.task }}
                  <span class="ml-2 mt-2 inline-block md:mt-0">
                    <el-tag
                      v-if="
                        event.discount > 0 &&
                        item.id === 'pay' &&
                        event.is_paid === false
                      "
                      type="success"
                      >{{ event.discount }}% DISCOUNT APPLIED
                    </el-tag>
                  </span>
                </dt>
                <!-- <dd class="mt-2 text-base text-gray-500">{{ item.answer }}</dd> -->
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
    <el-dialog v-model="countryDialogVisible" width="40%">
      <template #header="{}">
        <div>
          <h4>Confirm Your Country</h4>
        </div>
      </template>
      <div class="space-y-4">
        <p>
          Confirm the country that will be used for your registry. The country
          chosen will also determine the currency of your registry.
        </p>
        <p>
          Once you click <span class="font-bold">Verify Details</span>, you
          won't be able to change your country anymore.
        </p>
        <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div class="col-span-full space-y-4">
            <div>
              <el-select
                v-model="event.country_code"
                filterable
                class="mt-1 w-full"
                size="large"
                @change="updateCountry"
              >
                <el-option
                  v-for="item in countryDropdownOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                  <span style="float: left">{{ item.label }}</span>
                  <span style="float: right"
                    ><country-flag
                      :country="item.value.toLowerCase()"
                      size="small"
                  /></span>
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <el-button
          @click="postStripe()"
          :disabled="loading"
          type="primary"
          size="large"
          plain
          class="w-full"
          >Verify Details</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import CountryFlag from "vue-country-flag-next";

export default {
  props: ["event"],
  emits: ["show-pricing-modal"],
  components: {
    CountryFlag,
  },
  data() {
    return {
      loading: false,
      countryDialogVisible: false,
    };
  },
  computed: {
    items() {
      let items = [
        {
          id: "addGift",
          link: "Add your first gift.",
          task: "🎁",
          completed: this.event && this.event.gifts.length > 0 ? true : false,
        },
        {
          id: "editRegistry",
          link: "Customize your Design",
          task: "🎨",
          completed:
            this.event && this.event.has_been_edited > 0 ? true : false,
        },
        {
          id: "pay",
          link: "Upgrade to our paid plan. 💳",
          // task: "to go live. 💳",
          completed: this.event.is_paid,
        },
        {
          id: "verify",
          link: "Verify your bank details. 💸",
          // task: "to get paid. 💸",
          completed: this.event.stripe_registration_status == "complete",
        },
        {
          id: "married",
          link: "Get married.",
          task: "💍",
        },
      ];

      if (
        this.browserIsChrome() &&
        this.event &&
        !this.event.properties.closeChromeAlert
      ) {
        items.splice(0, 0, {
          id: "extension",
          link: "Install our Chrome extension to save time when adding gifts.",
          task: "🏃‍♀️",
          completed: this.event && this.event.properties.extension_installed,
        });
      }

      return items;
    },
  },
  methods: {
    actionTask(item) {
      if (item.id == "addGift") {
        this.$router.push({ name: "gifts" });
      } else if (item.id == "editRegistry") {
        this.$router.push({ name: "registry" });
      } else if (item.id == "pay") {
        this.$emit("show-pricing-modal");
      } else if (item.id == "verify") {
        this.event.stripe_registration_status == "incomplete"
          ? (this.countryDialogVisible = true)
          : this.postStripe();
      }
      if (item.id == "extension") {
        window.open(
          "https://chrome.google.com/webstore/detail/reggie/mmifkbflgeigbmjgoobbjdojjgakeijp"
        );
      } else if (item.id == "married") {
        this.$confetti.start();
        setTimeout(() => {
          this.$confetti.stop();
        }, 3000);
      }
    },

    updateCountry() {
      this.$axiosAuth
        .patch(`host/event/${this.event.event_id}/`, {
          country_code: this.event.country_code,
        })
        .then((res) => {
          ElNotification({
            title: "Success",
            message: "Country updated successfully",
            type: "success",
          });
        })
        .catch((err) => {
          ElNotification({
            title: "Error",
            message: err.response.data.non_field_errors[0],
            type: "error",
          });
        });
    },

    postStripe() {
      this.loading = true;
      this.$axiosAuth
        .post(`/host/stripe/account-onboard/`, {
          country: this.event.country_code,
        })
        .then((res) => {
          let stripeURL = res.data.url;
          window.location.replace(stripeURL);
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
.onboarding-list {
  .el-tag {
    @apply border border-[#a8f170] bg-[#D1FAB3]	!important;
  }
  .el-tag__content {
    @apply text-sm font-bold text-[#228403] !important;
  }
}
</style>
