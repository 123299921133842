<template>
  <main
    class="min-h-screen bg-cover bg-center"
    style="
      background-image: url('https://i.giphy.com/media/xfAgILDksDJJu/giphy.webp');
    "
  >
    <div
      class="flex min-h-screen flex-col items-center justify-center bg-black/70"
    >
      <div
        class="mx-auto max-w-7xl px-4 py-16 text-center sm:px-6 sm:py-24 lg:px-8 lg:py-48"
      >
        <h1
          class="mt-2 text-4xl font-bold tracking-tight text-red-400 sm:text-5xl"
        >
          404
        </h1>
        <p class="text-md mt-2 font-medium text-white md:text-xl">
          There's no page here, but if you refresh it may work. 😉
        </p>
        <div class="mt-6">
          <router-link :to="{ name: 'dashboard' }">
            <el-button type="primary" size="large" plain>Home</el-button>
          </router-link>
        </div>
      </div>
    </div>
  </main>
</template>
