export default {
  data() {
    return {
      amazonLocale: {
        AU: {
          countryName: "Australia",
          domain: "https://www.amazon.com.au",
          associateTag: "lovereggie-22",
        },
        NZ: {
          countryName: "New Zealand",
          domain: "https://www.amazon.com.au",
          associateTag: "lovereggie-22",
        },
        GB: {
          countryName: "United Kingdom",
          domain: "https://www.amazon.co.uk",
          associateTag: "lovereggie-21",
        },
        US: {
          countryName: "United States",
          domain: "https://www.amazon.com",
          associateTag: "lovereggie-20",
        },
      },
    };
  },
};
