<template>
  <div>
    <div class="flex flex-1 flex-col">
      <main class="flex-1 pb-8">
        <div>
          <!-- Activity table (small breakpoint and up) -->
          <div class="">
            <div>
              <div class="flex flex-col">
                <div
                  class="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg"
                >
                  <table class="min-w-full divide-y divide-gray-200 bg-white">
                    <thead class="bg-gray-50">
                      <tr>
                        <th
                          class="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                          scope="col"
                        >
                          Amount
                        </th>
                        <th
                          class="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                          scope="col"
                        >
                          Status
                        </th>
                        <th
                          class="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                          scope="col"
                        >
                          Date initiated
                        </th>
                        <th
                          class="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                          scope="col"
                        >
                          Estimated date of arrival
                        </th>
                      </tr>
                    </thead>
                    <tbody
                      v-if="payouts.length > 0"
                      class="divide-y divide-gray-200 bg-white"
                    >
                      <tr
                        v-for="payout in payouts"
                        :key="payout.id"
                        class="bg-white"
                      >
                        <td class="px-6 py-4 text-sm text-gray-900">
                          <p>
                            {{
                              `${formatCurrency(
                                payout.amount_fmt,
                                countryCode,
                                currency
                              )}`
                            }}
                          </p>
                        </td>
                        <td class="px-6 py-4 text-sm text-gray-900">
                          <div class="flex items-center">
                            <p
                              class="capitalize text-gray-900 group-hover:text-gray-900"
                              :class="[
                                payout.status == 'paid'
                                  ? statusStyles.success
                                  : '',
                                payout.status == 'processing'
                                  ? statusStyles.processing
                                  : '',
                                payout.status == 'failed'
                                  ? statusStyles.failed
                                  : '',
                              ]"
                            >
                              {{ payout.status }}
                            </p>
                          </div>
                        </td>
                        <td class="px-6 py-4 text-sm text-gray-500">
                          <span class="text-gray-900">{{
                            formatDate(payout.created * 1000)
                          }}</span>
                        </td>
                        <td class="px-6 py-4 text-sm text-gray-900">
                          <span
                            class="text-gray-900 group-hover:text-gray-900"
                            >{{ formatDate(payout.arrival_date * 1000) }}</span
                          >
                        </td>
                      </tr>
                    </tbody>
                    <tr v-else>
                      <p
                        class="w-full overflow-visible whitespace-nowrap bg-transparent p-6"
                      >
                        No payouts yet
                      </p>
                    </tr>
                  </table>
                  <!-- Pagination -->
                  <!-- <nav
                    class="
                      flex
                      items-center
                      justify-between
                      border-t border-gray-200
                      bg-white
                      px-4
                      py-3
                      sm:px-6
                    "
                    aria-label="Pagination"
                  >
                    <div class="hidden sm:block">
                      <p class="text-sm text-gray-700">
                        Showing
                        {{ " " }}
                        <span class="font-medium">1</span>
                        {{ " " }}
                        to
                        {{ " " }}
                        <span class="font-medium">10</span>
                        {{ " " }}
                        of
                        {{ " " }}
                        <span class="font-medium">20</span>
                        {{ " " }}
                        results
                      </p>
                    </div>
                    <div class="flex flex-1 justify-between sm:justify-end">
                      <a
                        href="#"
                        class="
                          relative
                          inline-flex
                          items-center
                          rounded-md
                          border border-gray-300
                          bg-white
                          px-4
                          py-2
                          text-sm
                          font-medium
                          text-gray-700
                          hover:bg-gray-50
                        "
                        >Previous</a
                      >
                      <a
                        href="#"
                        class="
                          relative
                          ml-3
                          inline-flex
                          items-center
                          rounded-md
                          border border-gray-300
                          bg-white
                          px-4
                          py-2
                          text-sm
                          font-medium
                          text-gray-700
                          hover:bg-gray-50
                        "
                        >Next</a
                      >
                    </div>
                  </nav> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["payouts", "countryCode", "currency"],
  data() {
    return {
      loading: false,
      statusStyles: {
        success: "bg-green-100 text-green-800 px-4 py-2 rounded-full",
        processing: "bg-yellow-100 text-yellow-800 px-4 py-2 rounded-full",
        failed: "bg-gray-100 text-gray-800 px-4 py-2 rounded-full",
      },
    };
  },
};
</script>
