// Element plus manual import since on demand import doesn't work for these
import { ElNotification } from "element-plus";
import "element-plus/es/components/notification/style/css";

//Element plus sass import for theme override
import '@/assets/element.scss'

export default {
  install: (app, options) => {
    app.use(ElNotification);
  },
};
