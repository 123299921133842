<script setup>
import { onMounted, ref } from "vue";

defineProps(["modelValue", "label", "placeholder"]);

defineEmits(["update:modelValue"]);

const input = ref(null);
</script>

<template>
  <div>
    <label
      :for="label"
      class="block text-sm font-medium text-gray-700"
      >{{ label }}</label
    >
    <div class="relative mt-1 rounded-md shadow-sm">
      <div
        class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
      >
        <span class="text-sm text-gray-500">lovereggie.com/</span>
      </div>
      <input
        :id="label"
        type="text"
        class="block h-10 w-full rounded-md border border-gray-300 pl-[115px] pr-6 text-sm focus:border-primary focus:ring-primary"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        ref="input"
      />
    </div>
  </div>
</template>
