<template>
  <div class="gift-slide-over">
    <el-drawer
      v-model="drawer"
      :lock-scroll="false"
      :modal="true"
      modal-class="overlay"
      :size="drawerSize"
      :before-close="handleClose"
      @close="$emit('close-slide')"
    >
      <template #header>
        <h4>{{ this.method == "add" ? "Add Gift" : "Edit Gift" }}</h4>
      </template>
      <template #default>
        <div class="divide-y divide-gray-200">
          <div class="space-y-6 pb-2">
            <el-alert
              v-if="
                !event.properties.closeChromeAlert &&
                !event.properties.extension_installed
              "
              type="info"
              effect="dark"
              @close="patchEvent()"
            >
              <template #title>
                <a
                  href="https://chrome.google.com/webstore/detail/reggie/mmifkbflgeigbmjgoobbjdojjgakeijp"
                  target="_blank"
                >
                  {{
                    browserIsChrome()
                      ? "Download the Reggie Chrome Extension"
                      : "Switch to Google Chrome to save time"
                  }}
                </a>
              </template>
              <a
                href="https://chrome.google.com/webstore/detail/reggie/mmifkbflgeigbmjgoobbjdojjgakeijp"
                target="_blank"
              >
                <p>
                  {{
                    browserIsChrome()
                      ? "Save time by adding gifts from any website in one click"
                      : "If you use Chrome, you can download our extension that allows you to add gifts from any website in one click."
                  }}
                </p>
              </a>
            </el-alert>

            <div>
              <p class="mb-2 block text-lg font-medium text-gray-900">
                Select a gift type
              </p>
              <!-- <el-radio-group v-model="gift.type" size="large">
                <el-radio-button
                  v-for="option in giftTypes.types"
                  :key="option.name"
                  :label="option.key"
                  ><h3 class="text-sm capitalize lg:text-base">
                    {{ option.name }}
                  </h3>
                  <p class="text-xs lg:text-sm">
                    {{ option.description }}
                  </p>
                </el-radio-button>
              </el-radio-group> -->

              <el-segmented v-model="gift.type" :options="giftTypes.types">
                <template #default="{ item }">
                  <div class="flex flex-col items-center p-4">
                    <div class="text-sm capitalize lg:text-base">
                      {{ item.name }}
                    </div>
                    <p class="text-xs lg:text-sm">
                      {{ item.description }}
                    </p>
                  </div>
                </template>
              </el-segmented>

              <!-- <el-checkbox
                v-if="gift.type == 'multi'"
                label="Single purchase"
                size="large"
              /> -->
            </div>
            <el-divider />
            <div>
              <p class="mb-2 block text-lg font-medium text-gray-900">
                Add gift details
              </p>
              <el-form
                @submit.prevent
                class="gift-form"
                :model="gift"
                ref="form"
                :rules="formRules"
                label-position="top"
              >
                <el-form-item label="Name" prop="name">
                  <el-input v-model="gift.name" size="large" />
                </el-form-item>
                <el-form-item
                  v-if="gift.type != 'unlimited'"
                  label="Amount"
                  prop="amount_fmt"
                >
                  <el-input
                    v-model="gift.amount_fmt"
                    size="large"
                    type="number"
                    min="1"
                  >
                    <template
                      v-if="currencyIsPreffix(gift.country_code, gift.currency)"
                      #prefix
                    >
                      <p>
                        {{
                          formatCurrency(
                            0,
                            gift.country_code,
                            gift.currency,
                            true
                          )
                        }}
                      </p>
                    </template>
                    <template v-else #suffix>
                      {{
                        formatCurrency(
                          0,
                          gift.country_code,
                          gift.currency,
                          true
                        )
                      }}
                    </template>
                  </el-input>
                </el-form-item>
                <el-form-item label="Description" prop="description">
                  <el-input
                    :rows="5"
                    type="textarea"
                    v-model="gift.description"
                  />
                </el-form-item>
              </el-form>
            </div>

            <div>
              <h3 class="mb-2 text-sm font-medium text-gray-900">Gift Image</h3>
              <el-radio-group
                v-if="userMobile"
                v-model="gift.properties.img_source"
                class="image-group-mobile mb-4"
              >
                <el-radio
                  class="capitalize"
                  v-for="option in imageOptions"
                  :key="option.key"
                  :label="option.key"
                  border
                  >{{ option.name }}</el-radio
                >
              </el-radio-group>
              <el-radio-group
                v-else
                v-model="gift.properties.img_source"
                class="mb-4"
              >
                <el-radio-button
                  class="capitalize"
                  v-for="option in imageOptions"
                  :key="option.key"
                  :label="option.key"
                  >{{ option.name }}</el-radio-button
                >
              </el-radio-group>
              <p
                v-if="
                  gift.properties.img_source == 'img' &&
                  this.gift.img &&
                  !showImageUploader
                "
                class="mb-4"
              >
                Click
                <span
                  @click="showImageUploader = true"
                  class="cursor-pointer font-bold text-primary underline"
                  >here</span
                >
                to replace your current image.
              </p>
              <ImageUpload
                v-if="
                  (gift.properties.img_source == 'img' &&
                    this.gift.img == null) ||
                  (showImageUploader && gift.properties.img_source == 'img')
                "
                @imageUploaded="
                  (file) => {
                    handleImage(file[0], 'gift');
                  }
                "
              />

              <!-- Show preview of image in slideover only on mobile -->
              <div
                v-if="gift.imgPreview"
                class="mt-4 aspect-video w-full overflow-hidden rounded-lg border border-gray-200 lg:hidden"
              >
                <div
                  v-lazy:background-image="gift.imgPreview"
                  class="pointer-events-none flex h-full w-full items-center justify-center bg-cover bg-center object-scale-down"
                ></div>
              </div>
              <el-input
                v-if="gift.properties.img_source == 'img_url'"
                v-model="gift.img_url"
                type="text"
                placeholder="Enter a link to an image"
              />

              <div v-if="gift.properties.img_source == 'img_url_stock'">
                <ImageGallerySearch
                  :initialSearch="gift.name"
                  @imageSelected="
                    (url) => {
                      handleStockImage(url, 'gift');
                    }
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="space-x-4">
          <el-button
            v-if="gift"
            @click="showDeleteModal()"
            type="danger"
            size="large"
            plain
          >
            <p class="text-lg">Delete</p>
          </el-button>
          <el-button @click="validateForm('form')" type="primary" size="large">
            <p class="text-lg">Save</p>
          </el-button>
        </div>
      </template>
    </el-drawer>
  </div>
</template>

<script>
import ImageUpload from "@/components/admin/ImageUpload.vue";
import ImageGallerySearch from "@/components/admin/ImageGallerySearch.vue";
import { ElMessageBox } from "element-plus";
import ImageUploadMixin from "@/mixins/ImageUpload.js";
import { capitalize } from "vue";

export default {
  components: {
    ImageUpload,
    ImageGallerySearch,
    ElMessageBox,
  },
  props: ["gift", "event", "index", "method", "open"],
  emits: ["close-slide"],
  mixins: [ImageUploadMixin],

  data() {
    return {
      drawer: null,
      giftCopy: null,
      formRules: {
        name: [
          {
            required: true,
            message: "This field is required",
            trigger: ["submit"],
          },
        ],
        amount_fmt: [
          {
            required: true,
            message: "Amount must be greater than 0",
            trigger: ["submit"],
          },
        ],
      },
      giftTypes: {
        types: [
          {
            name: "Gift",
            description: "Item with a set price",
            key: "multi",
            value: "multi",
          },
          {
            name: "Fund",
            description: "Item with no set price",
            key: "unlimited",
            value: "unlimited",
          },
        ],
      },
      modal: {
        show: false,
      },
    };
  },

  computed: {
    drawerSize() {
      return this.userMobile ? "100%" : "485px";
    },
  },

  mounted() {
    this.drawer = this.open;
    this.giftCopy = JSON.parse(JSON.stringify(this.gift));
  },

  created() {
    const unwatch = this.$watch(
      "gift",
      (param) => {
        this.gift.draft = true;
        unwatch();
      },
      { deep: true }
    );
  },

  methods: {
    validateForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submit();
        } else {
          return false;
        }
      });
    },

    submit() {
      this.method == "edit" ? this.editGift() : this.addGift();
    },

    addGift() {
      const formData = new FormData();
      Object.keys(this.gift).forEach((key) => {
        if (
          key === "properties" ||
          key === "img_url_stock" ||
          key === "amazon_blob"
        ) {
          formData.append(key, JSON.stringify(this.gift[key]));
        } else if (
          key === "img" &&
          Object.keys(this.gift).some((key) => key === "imageUploaded")
        ) {
          formData.append("img", this.gift[key]);
        } else if (
          key === "img" &&
          Object.keys(this.gift).some((key) => key != "imageUploaded")
        ) {
          // do nothing as we don't won't to reupload the image as it throws an error
        } else if (key === "amount_fmt") {
          formData.append("amount", this.gift[key]);
        } else {
          formData.append(key, this.gift[key]);
        }
      });
      this.$axiosAuth
        .post("/host/gift/", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          // update the current gift with all the new values including ID
          this.gift.gift_id = res.data.gift_id;
          this.gift.amount = res.data.amount;
          this.gift.amount_fmt = res.data.amount_fmt;
          ElNotification({
            title: "Success",
            message: "Gift added successfully",
            type: "success",
          });
          this.gift.draft = false;
          this.$emit("close-slide", {});
        })
        .catch((err) => {
          let errors = err.response.data;
          let errorList = "";
          Object.keys(errors).forEach((key) => {
            errorList += capitalize(key) + ": " + errors[key][0] + " ";
          });
          ElNotification({
            title: "Error",
            message: errorList,
            type: "error",
          });
        });
    },

    editGift() {
      const formData = new FormData();
      Object.keys(this.gift).forEach((key) => {
        if (key === "properties" || key === "img_url_stock") {
          formData.append(key, JSON.stringify(this.gift[key]));
        } else if (
          key === "img" &&
          Object.keys(this.gift).some((key) => key === "imageUploaded")
        ) {
          formData.append("img", this.gift[key]);
        } else if (
          key === "img" &&
          Object.keys(this.gift).some((key) => key != "imageUploaded")
        ) {
          // do nothing as we don't won't to reupload the image as it throws an error
        } else if (key === "amount_fmt") {
          formData.append("amount", this.gift[key]);
        } else if (key === "amount") {
          //do nothing as we added above
        } else {
          formData.append(key, this.gift[key]);
        }
      });

      this.$axiosAuth
        .patch(`host/gift/${this.gift.gift_id}/`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          ElNotification({
            title: "Success",
            message: "Changes saved",
            type: "success",
          });
          this.gift.draft = false;
          this.$emit("close-slide", { index: this.index });
        })

        .catch((err) => {
          if (err.response.data.non_field_errors) {
            ElNotification({
              title: "Error",
              message: err.response.data.non_field_errors[0],
              type: "error",
            });
          } else {
            let errors = err.response.data;
            let errorList = "";
            Object.keys(errors).forEach((key) => {
              errorList += capitalize(key) + ": " + errors[key][0] + " ";
            });
            ElNotification({
              title: "Error",
              message: errorList,
              type: "error",
            });
          }
        });
    },

    showDeleteModal() {
      ElMessageBox.confirm(
        "Are you sure you want to delete this gift?",
        "Warning",
        {
          confirmButtonText: "Delete",
          cancelButtonText: "Cancel",
          type: "warning",
          closeOnClickModal: false,
          closeOnPressEscape: false,
        }
      )
        .then(() => {
          this.deleteGift();
        })
        .catch(() => {});
    },

    handleClose() {
      if (this.gift.draft) {
        this.showSaveModal();
      } else {
        this.drawer = false;
      }
    },

    showSaveModal() {
      ElMessageBox.confirm("Do you want to save?", "Warning", {
        confirmButtonText: "Save Changes",
        cancelButtonText: "Discard Changes",
        type: "warning",
        closeOnClickModal: false,
        closeOnPressEscape: false,
      })
        .then(() => {
          this.validateForm("form");
        })
        .catch(() => {
          var self = this;

          //if this a new gift that user wants to discard, send event to parent to remove it
          if (self.method == "add") {
            this.$emit("remove-new-gift");
            this.drawer = false;
            return;
          }

          //otherwise replace gift with old values
          Object.keys(this.gift).forEach(function (key) {
            self.gift[key] = self.giftCopy[key];
          });
          this.drawer = false;
          this.gift.draft = false;
        });
    },

    deleteGift() {
      //if this a new gift that user wants to discard, send event to parent to remove it
      if (this.method == "add") {
        this.$emit("remove-new-gift");
        this.drawer = false;
        return;
      }

      this.$axiosAuth
        .delete(`host/gift/${this.gift.gift_id}/`)
        .then((res) => {
          ElNotification({
            title: "Success",
            message: "Gift deleted",
            type: "success",
          });
          this.$emit("close-slide", { index: this.index, delete: true });
        })
        .catch((err) => {
          ElNotification({
            title: "Error",
            message: err.response.data,
            type: "error",
          });
        });
    },

    patchEvent() {
      this.event.properties.closeChromeAlert = true;
      this.$axiosAuth.patch(`host/event/${this.event.event_id}/`, {
        properties: this.event.properties,
      });
    },
  },
};
</script>

<style>
.gift-slide-over {
  .el-drawer {
    top: 48px !important;
    height: calc(100% - 48px) !important;
  }

  .el-drawer__header {
    margin-bottom: 0 !important;
    @apply bg-primary p-5 text-white;
  }
  .gift-form {
    .el-input {
      @apply h-12 text-base text-black !important;
    }
    .el-textarea__inner {
      @apply text-base text-black !important;
    }
  }
  .el-overlay {
    background-color: transparent !important;
  }
  .el-alert {
    @apply border border-[#eda391] bg-[#fbede9]	!important;
  }
  .el-alert__title {
    @apply text-sm text-primary !important;
  }
  .el-alert__description,
  .el-alert__close-btn {
    @apply text-xs text-primary !important;
  }

  .image-group-mobile {
    @apply flex-col items-start gap-y-2 !important;
  }
}
</style>
