<template>
  <div class="gift-template-component">
    <div v-if="!showSelected" class="mb-4 flex space-x-4">
      <el-input
        v-model="search"
        placeholder="Search for a gift"
        @change="triggerSearch"
      />
      <el-button
        type="primary"
        size="large"
        plain
        @click="triggerSearch"
        :disabled="loading || search.length == 0"
        >Search</el-button
      >
    </div>

    <div
      v-if="loading"
      v-loading="loading"
      element-loading-text="
       Hang in there, your results will show in a few seconds... "
      class="h-96"
    ></div>
    <div class="flex h-96 w-full flex-col items-center" v-else>
      <div
        v-if="products.length == 0"
        class="flex max-w-xl flex-col items-center"
      >
        <img class="mt-8 w-36" src="../../assets/img/amazon-logo.png" alt="" />
        <p class="mt-2 text-xl font-bold">
          Add items from Amazon directly to your registry.
        </p>
        <ul class="text-medium mt-4 list-inside space-y-2 text-base">
          <li class="flex gap-x-2">
            <span>🔎</span>
            <span>
              Use the search bar above to find Amazon products to add to your
              registry.</span
            >
          </li>
          <li class="flex gap-x-2">
            <span>🎁</span>
            <span
              >Your guests will be able to contribute to them like any other
              gift on Reggie, and you will receive their contribution as a
              payout to your bank account.</span
            >
          </li>
          <li class="flex gap-x-2">
            <span>💸</span>
            <span
              >Once you're ready, you can go to the
              <router-link
                class="cursor-pointer border-b border-red-300 font-bold"
                :to="{ name: 'amazon-checkout' }"
              >
                Amazon Gift Checkout
              </router-link>
              page to purchase your gifts directly from Amazon. Reggie doesn't
              purchase the gifts from Amazon for you.</span
            >
          </li>
        </ul>
      </div>

      <ul
        v-else
        role="list"
        class="mt-2 grid grid-cols-1 gap-x-4 gap-y-4 overflow-y-auto p-1 lg:grid-cols-3 xl:gap-x-6"
        :class="endpoint == 'public' ? 'h-72' : 'h-96'"
      >
        <!-- on onboarding, no gifts selected, show button to add gift -->
        <div
          v-if="showSelected && selectedProducts.length == 0"
          @click="showSelected = false"
          class="cursor-pointer"
        >
          <div
            class="relative block w-full rounded-lg border-2 border-dashed border-gray-300 px-2 py-6 text-center focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="{1.5}"
              stroke="currentColor"
              class="mx-auto h-12 w-12 text-red-400"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 11.25v8.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 109.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1114.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z"
              />
            </svg>

            <span class="mt-2 block text-xs font-medium text-gray-900"
              >No products selected. Click to add your first one.</span
            >
          </div>
        </div>
        <!-- otherwise show gifts -->
        <li
          v-for="product in showSelected ? selectedProducts : products"
          :key="product.template_id"
          class="relative"
          @click="selectProducts(product)"
        >
          <div
            class="group block aspect-square overflow-hidden rounded-lg border border-gray-100 bg-white p-1"
            :class="
              product.selected ? 'ring-2 ring-red-500 ring-offset-2' : ' '
            "
          >
            <img
              :src="product.mainImageUrl"
              alt=""
              class="h-full w-full cursor-pointer object-contain group-hover:opacity-75"
            />
          </div>

          <div class="mt-2 text-sm text-gray-900">
            <div class="truncate">{{ product.title }}</div>
            <div v-if="product.price && endpoint == 'host'">
              {{
                formatCurrency(
                  product.price.value,
                  countryCode,
                  product.price.currency
                )
              }}
              <a
                :href="product.url"
                target="_blank"
                class="ml-1 inline-block w-8"
              >
                <img src="../../assets/img/amazon-logo.png" />
              </a>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div
      v-if="endpoint == 'host' && products.length > 0"
      class="mt-6 flex w-full flex-col-reverse gap-y-2 lg:flex-row lg:justify-between lg:gap-y-0"
    >
      <el-switch
        v-model="showSelected"
        active-text="Show Selected Gifts"
      ></el-switch>
      <div class="space-x-2">
        <el-button v-if="!userMobile" @click="$emit('close-modal')"
          >Cancel</el-button
        >
        <el-button
          class="w-full lg:w-auto"
          type="primary"
          @click="emitSelectedGifts"
          :disabled="selectedProducts.length == 0"
        >
          {{
            selectedProducts.length > 1
              ? `Select ${selectedProducts.length} gifts`
              : "Select gift"
          }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Present } from "@element-plus/icons-vue";

export default {
  data() {
    return {
      products: [],
      tags: [],
      search: "",
      showSelected: false,
      loading: false,
      loadingMessage: false,
    };
  },
  mounted() {},
  methods: {
    triggerSearch() {
      if (this.selectedProducts.length == 0) {
        this.getData();
        return;
      }
      ElMessageBox.alert(
        "You have selected gifts already. Add these gifts before searching again.",
        "Warning",
        {
          confirmButtonText: "Add selected gifts",
          type: "warning",
        }
      ).then(() => {
        this.emitSelectedGifts();
      });
    },
    getData() {
      if (this.selectedProducts.length > 0) {
      }
      this.loading = true;
      //if request takes more than 3 seconds, set loading Message to true
      setTimeout(() => {
        if (this.loading) {
          this.loadingMessage = true;
        }
      }, 5000);
      this.$axiosAuth
        .get(
          `host/amazon-query/?terms=${this.search}&domain=${this.countryCode}`
        )
        .then((res) => {
          this.products =
            res.data.data.amazonProductSearchResults.productResults.results;
          this.loading = false;
          this.loadingMessage = false;
        });
    },
    // clearFilters() {
    //   this.search = "";
    //   // this.tags.forEach((tag) => (tag.checked = false));
    // },
    selectProducts(product) {
      !product.selected
        ? (product.selected = true)
        : (product.selected = false);
    },
    clearSelectedProducts() {
      this.products.forEach((gift) => (gift.selected = false));
    },
    emitSelectedGifts() {
      if (this.selectedProducts.length == 1) {
        this.$emit("addGift", this.selectedProducts[0]);
        this.products = [];
        this.search = "";
      } else {
        this.addProducts();
      }
    },
    emitEmptyGift() {
      this.$emit("addGift", null);
    },
    addProducts() {
      let formattedProducts = this.selectedProducts.map((product) => {
        return {
          name: product.title,
          type: "multi",
          amount: product.price.value,
          img_url: product.mainImageUrl,
          country_code: this.countryCode,
          currency: product.price.currency,
          properties: { img_source: "img_url", pinned: false },
          amazon_blob: product,
        };
      });
      this.$axiosAuth
        .post("/host/multi-gift/", formattedProducts)
        .then((res) => {
          ElNotification({
            title: "Success",
            message: `${formattedProducts.length} gifts added successfully`,
            type: "success",
          });
          this.$emit("multiGiftAdd");
          this.products = [];
          this.search = "";
        })
        .catch((err) => {
          ElNotification({
            title: "Error",
            message: "Gifts could not be added",
            type: "error",
          });
        });
    },
  },
  computed: {
    selectedProducts() {
      return this.products.filter((product) => product.selected);
    },
  },

  props: ["countryCode", "endpoint"],
  emits: ["addGift", "multiGiftAdd"],
  components: {
    Present,
  },
};
</script>

<style>
.gift-template-component {
  .el-scrollbar {
    @apply w-[calc(100%+2rem)];
  }
}
</style>
