export default {
  data() {
    return {
      showImageUploader: false,
      imageOptions: [
        { name: "Uploaded Image", key: "img" },
        { name: "Stock Image", key: "img_url_stock" },
        { name: "Image URL", key: "img_url" },
      ],
    };
  },

  methods: {
    handleImage(file, param) {
      this.showImageUploader = false
      this[param].imgPreview = URL.createObjectURL(file);
      this[param].img = file;
      this[param].imageUploaded = true;
    },

    handleStockImage(url, param) {
      if (param == "event" || param == "eventForm") {
        this.loading = true
        this[param].img_url_stock = url.full;
        this[param].properties.img_download_location = url.download;
        this.loading = false
      } else {
        this[param].img_url_stock = url;
      }
    },
  },
};
