<template>
  <div class="flex flex-col lg:flex-row">
    <div
      v-if="event[event.properties.img_source]"
      :style="{
        backgroundImage:
          'url(' +
          `${
            event.imgPreview && event.properties.img_source == 'img'
              ? event.imgPreview
              : event[event.properties.img_source]
          }` +
          ')',
      }"
      :class="[
        dashboardPreview ? ' max-h-fit' : 'lg:h-screen',
        drawerState ? 'lg:w-[45%]' : 'lg:w-[60%]',
      ]"
      class="top-0 block h-72 w-screen bg-cover bg-center bg-no-repeat transition-all duration-300 ease-in-out lg:sticky"
    ></div>
    <div
      class="w-screen break-all transition-all duration-300 ease-in-out"
      :class="[
        dashboardPreview
          ? 'h-72 overflow-y-scroll px-6'
          : 'px-10 py-12 lg:min-h-screen',
        drawerState ? 'lg:w-[55%]' : 'lg:w-[40%]',
      ]"
      :style="{ background: event.properties.colors.background }"
    >
      <div
        :class="dashboardPreview ? 'h-72' : 'lg:min-h-screen '"
        class="grid min-h-0 place-items-center"
      >
        <div
          :data-aos="
            !dashboardPreview && event.properties.animate ? 'fade-in' : null
          "
          data-aos-once="true"
          data-aos-duration="1000"
          data-aos-delay="200"
          class="text-center"
        >
          <h2
            :class="dashboardPreview ? 'text-3xl' : 'text-5xl lg:text-8xl'"
            :style="{
              color: event.properties.colors.heading,
              fontFamily: event.properties.font,
            }"
          >
            {{ event.name_host1 }}
          </h2>
          <h2
            v-if="event.name_host2"
            :class="dashboardPreview ? 'text-3xl' : 'text-5xl lg:text-8xl'"
            :style="{
              color: event.properties.colors.accent,
              fontFamily: event.properties.font,
            }"
          >
            &
          </h2>

          <h2
            v-if="event.name_host2"
            :class="dashboardPreview ? 'text-3xl' : 'text-5xl lg:text-8xl'"
            :style="{
              color: event.properties.colors.heading,
              fontFamily: event.properties.font,
            }"
          >
            {{ event.name_host2 }}
          </h2>
        </div>
      </div>
      <div
        class="h-fit overflow-hidden text-center"
        :class="[
          dashboardPreview
            ? 'space-y-4 pb-6'
            : 'space-y-6 lg:space-y-12 lg:pb-12',
          extraPadding ? 'pb-10 lg:pb-0' : null,
        ]"
      >
        <div
          v-if="event.hashtag"
          :data-aos="
            !dashboardPreview && event.properties.animate ? 'fade-in' : null
          "
          data-aos-once="true"
          data-aos-duration="1000"
        >
          <a
            v-if="event.hashtag"
            :href="`https://hashtag.com/${event.hashtag}/`"
            target="_blank"
            class="block"
            :class="[
              dashboardPreview
                ? 'mt-2 text-xs'
                : 'mt-6 block text-2xl lg:text-2xl',
            ]"
            :style="{ color: event.properties.colors.paragraph }"
          >
            {{ `#${event.hashtag}` }}
          </a>
        </div>
        <h4
          v-if="event.start_datetime && event.properties.show_date"
          :data-aos="
            !dashboardPreview && event.properties.animate ? 'fade-in' : null
          "
          data-aos-once="true"
          data-aos-duration="1000"
          :class="[
            dashboardPreview
              ? 'mx-auto mt-2 text-xs'
              : 'mt-6 text-2xl lg:text-3xl',
          ]"
          :style="{
            color: event.properties.colors.paragraph,
            fontFamily: event.properties.font,
          }"
        >
          {{ formatDate(event.start_datetime) }}
        </h4>
        <h3
          :data-aos="
            !dashboardPreview && event.properties.animate ? 'fade-in' : null
          "
          data-aos-once="true"
          data-aos-duration="1000"
          v-if="event.description"
          class="break-keep"
          :class="[
            dashboardPreview
              ? 'mx-auto mt-6 max-w-[250px] text-[10px]'
              : 'pb-12 pt-8 text-xl lg:text-2xl',
          ]"
          :style="{ color: event.properties.colors.paragraph }"
        >
          {{ event.description }}
        </h3>
        <div
          v-if="event.properties.showCustomFields"
          class="pt-6 lg:col-span-8 lg:pt-0"
          id="custom-fields"
        >
          <dl
            class="grid grid-cols-1"
            :class="dashboardPreview ? 'gap-y-3 pb-8' : ' gap-y-4 lg:gap-y-12'"
          >
            <div
              v-for="(field, index) in event.custom_user_fields"
              :key="index"
              class="cols-span-2 break-keep"
            >
              <div class="flex items-center justify-center gap-x-2 lg:gap-x-3">
                <dt
                  :data-aos="
                    !dashboardPreview && event.properties.animate
                      ? 'fade-in'
                      : null
                  "
                  data-aos-once="true"
                  data-aos-duration="1000"
                  :style="{
                    color: event.properties.colors.paragraph,
                    fontFamily: event.properties.font,
                  }"
                  :class="
                    dashboardPreview
                      ? 'text-base'
                      : 'text-xl leading-7 lg:text-3xl'
                  "
                  class="font-semibold"
                >
                  {{ field.title }}
                </dt>
              </div>
              <dd
                :data-aos="
                  !dashboardPreview && event.properties.animate
                    ? 'fade-in'
                    : null
                "
                data-aos-once="true"
                data-aos-duration="1000"
                :style="{
                  color: event.properties.colors.paragraph,
                }"
                :class="
                  dashboardPreview
                    ? 'mt-1 text-[10px]'
                    : 'mt-1 text-base leading-7 lg:mt-3 lg:text-lg'
                "
              >
                {{ field.description }}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Details from "@/layouts/event/snippets/Details.vue";

export default {
  components: { Details },
  props: ["event", "dashboardPreview", "drawerState"],
};
</script>