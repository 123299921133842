<template>
  <div
    v-if="!loading"
    :class="
      event && event.properties.layout != 'split' ? 'overflow-x-hidden' : null
    "
  >
    <div
      v-if="preview"
      :class="
        event.properties && event.properties.layout == 'center' ? 'mb-8' : null
      "
    >
      <PreviewBanner />
    </div>
    <div v-if="event && (event.is_live || this.preview)">
      <div class="hero">
        <SplitLayout
          v-if="event.properties && event.properties.layout == 'split'"
          :event="event"
        />
        <SplitLayout2
          v-if="event.properties && event.properties.layout == 'split 2'"
          :event="event"
        />
        <CenterLayout
          v-if="event.properties && event.properties.layout == 'center'"
          :event="event"
        />
        <FullLayout
          v-if="event.properties && event.properties.layout == 'full'"
          :event="event"
        />
      </div>
      <div
        v-if="
          event.properties &&
          event.properties.showCustomFields &&
          event.properties.layout != 'split'
        "
        class="mx-auto border-b border-gray-200 bg-white p-8 lg:p-0 lg:px-16 lg:py-20"
      >
        <CustomFields :event="event" />
      </div>
      <div id="gifts" class="mx-auto p-8 lg:px-16 lg:py-12">
        <PublicGifts
          v-if="gifts"
          :gifts="gifts"
          :event="event"
          :preview="preview"
          :kiosk="kiosk"
        />
      </div>
    </div>
    <div v-else>
      <Error />
    </div>
  </div>
</template>

<script>
import SplitLayout from "@/layouts/event/Split.vue";
import SplitLayout2 from "@/layouts/event/Split_2.vue";
import CenterLayout from "@/layouts/event/Center.vue";
import FullLayout from "@/layouts/event/Full.vue";
import CustomFields from "@/layouts/event/sections/CustomFields.vue";
import Error from "@/components/public/Error.vue";
import PreviewBanner from "@/components/admin/PreviewBanner.vue";

import eventStyles from "@/mixins/eventStyles.js";

export default {
  components: {
    SplitLayout,
    SplitLayout2,
    CenterLayout,
    FullLayout,
    CustomFields,
    Error,
    PreviewBanner,
  },
  props: ["preview", "kiosk", "eventProp"],
  mixins: [eventStyles],
  data() {
    return {
      loading: false,
      slug: "",
      event: "",
      gifts: [],
    };
  },

  computed: {
    divider() {
      if (this.event) {
        let divider = this.dividers.find(
          (d) => d.name == this.event.properties.divider
        );
        return divider.divider.main;
      }
    },
  },

  mounted() {
    this.slug = this.$route.params.slug;
    if (!this.kiosk) {
      this.getData();
    } else {
      this.event = JSON.parse(JSON.stringify(this.eventProp));
      //force live if kiosk preview
      this.event.is_live = true;
      this.setGiftOrder();
    }
  },

  methods: {
    getData() {
      this.loading = true;
      //using same component for gift preview and showing actual event
      if (!this.preview) {
        this.$axios
          .get(`event/${this.slug}/`)
          .then((res) => {
            this.event = res.data;
            this.setGiftOrder();
          })
          .catch((err) => {
            // if (err.response.data.detail == "Not found.") {
            //   this.$router.push({ name: "404" });
            // }
          });
      } else {
        this.$axiosAuth
          .get(`host/event/`)
          .then((res) => {
            this.event = res.data[0];
            this.setGiftOrder();
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
          });
      }
    },
    setGiftOrder() {
      let originalGiftOrder = this.event.gift_order;
      if (this.event.gifts) {
        this.event.gifts.forEach((gift) => {
          let index = originalGiftOrder.indexOf(gift.gift_id);
          if (index !== -1)
            this.gifts[originalGiftOrder.indexOf(gift.gift_id)] = gift;
          else this.gifts.push(gift);
        }); //if there is a gift in the database that somehow is not in the gift_order array, then indexOf() will return -1 and the gift will be placed at the end of the array (i.e. this.gifts[-1]) this is good forces the gift_order and actual gifts to be synchronized
      } else {
        this.gifts = [];
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.hero {
  mask: v-bind(divider);
  clip-path: v-bind(divider);
}
</style>
