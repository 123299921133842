<template>
  <main
    :class="[dashboardPreview ? 'h-80' : 'h-[100vh]']"
    class="bg-cover bg-center bg-no-repeat"
    :style="
      event.imgPreview
        ? { backgroundImage: 'url(' + event.imgPreview + ')' }
        : { backgroundImage: 'url(' + event[event.properties.img_source] + ')' }
    "
  >
    <div class="h-full w-full bg-black/50">
      <div
        :class="[
          dashboardPreview
            ? 'max-w-sm px-6 2xl:px-24'
            : 'max-w-[64rem] px-8 lg:px-16 2xl:px-48',
        ]"
        class="items-left flex h-full flex-col justify-center"
      >
        <Details
          :event="event"
          :white="true"
          :dashboardPreview="dashboardPreview"
        />
      </div>
    </div>
  </main>
</template>

<script>
import Details from "@/layouts/event/snippets/Details.vue";

export default {
  props: ["event", "dashboardPreview"],
  components: { Details },
};
</script>
