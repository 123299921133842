<template>
  <div v-if="event">
    <div class="flex items-center justify-between"></div>
    <p class="text-sm text-gray-600">
      Add any extra important details for your big day like dress code,
      transport or anything else.
    </p>
    <div>
      <el-checkbox
        v-model="event.properties.showCustomFields"
        label="Display this section on my registry"
        size="large"
        @change="scrollAnchor"
      />
    </div>
  </div>
  <div class="mt-2 flex items-center" v-if="event.properties.showCustomFields">
    <div>
      <p class="el-form-item__label" style="justify-content: flex-start">
        Section Title
      </p>
      <div class="flex gap-x-4">
        <el-form-item prop="customFieldsTitle">
          <el-input v-model="event.properties.customFieldsTitle" size="large" />
        </el-form-item>
        <el-button
          v-if="event.properties.showCustomFields"
          @click="addField()"
          type="primary"
          size="large"
          plain
          >Add New Card</el-button
        >
      </div>
    </div>
    <div></div>
  </div>
  <div
    v-if="event.properties.showCustomFields"
    class="my-4 space-y-6 overflow-auto pr-2"
  >
    <div
      v-for="(field, index) in event.custom_user_fields"
      :key="index"
      class="rounded-xl border bg-gray-50 p-4"
    >
      <CustomFieldsInput
        :index="index + 1"
        :field="field"
        :event="event"
        @click="(event) => icon"
      />
      <el-button @click="deleteField(index)" class="mt-2" type="danger" plain
        >Delete</el-button
      >
    </div>
  </div>
</template>

<script>
import CustomFieldsInput from "@/components/admin/CustomFieldsInputs.vue";
import { EyeIcon, EyeSlashIcon } from "@heroicons/vue/20/solid";

export default {
  components: { CustomFieldsInput, EyeIcon, EyeSlashIcon },
  props: ["event"],
  data() {
    return {
      icon: "",
    };
  },
  methods: {
    addField() {
      this.event.custom_user_fields.push({ title: "", description: "" });
    },
    deleteField(index) {
      this.event.custom_user_fields.splice(index, 1);
    },
    scrollAnchor(e) {
      let el;
      e == 0
        ? (el = document.querySelector("#main-section"))
        : (el = document.querySelector("#custom-fields"));
      if (el) {
        el.scrollIntoView({
          behavior: "smooth", // smooth scroll
          block: "start", // the upper border of the element will be aligned at the top of the visible part of the window of the scrollable area.
        });
      }
    },
  },
};
</script>

<style></style>
