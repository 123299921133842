<template>
  <PartnersLoginLayout>
    <div>
      <h2 class="mt-4 font-subheading text-3xl text-gray-900">
        Sign into your account
      </h2>
    </div>

    <div class="mt-6">
      <el-form label-position="top">
        <el-form-item label="Email" prop="email" :error="validation.email">
          <el-input
            @keyup.enter="login()"
            v-model="form.email"
            type="email"
            size="large"
          />
        </el-form-item>
        <el-form-item
          @keyup.enter="login()"
          label="Password"
          prop="password"
          :error="validation.password"
        >
          <el-input v-model="form.password" type="password" size="large" />
        </el-form-item>
        <div class="mt-4 space-y-2 text-center">
          <el-button
            @click="login()"
            class="w-full"
            type="primary"
            size="large"
            plain
            >Sign in</el-button
          >
        </div>
      </el-form>
    </div>
  </PartnersLoginLayout>
</template>

<script>
import PartnersLoginLayout from "@/layouts/partners/Login.vue";

import { useUserStore } from "@/stores/UserStore";
import { mapActions } from "pinia";

export default {
  components: { PartnersLoginLayout },
  data() {
    return {
      form: {
        email: null,
        password: null,
      },
      validation: {
        email: null,
        password: null,
      },
    };
  },
  methods: {
    ...mapActions(useUserStore, ["authenticateAffiliate"]),

    login() {
      this.$axiosAuth
        .post("affiliates/login/", this.form)
        .then((response) => {
          let user = {};
          user.email = response.data.user.email;
          user.name = response.data.name;
          user.affiliateCode = response.data.affiliate_code;
          this.authenticateAffiliate(true, user);
          this.$router.push({ name: "partners-dashboard" });
        })
        .catch((error) => {
          this.validation = {
            email: null,
            password: null,
          };

          const errorMessages = error.response.data;
          errorMessages.email
            ? (this.validation.email = errorMessages.email[0])
            : (this.email = null);
          errorMessages.password
            ? (this.validation.password = errorMessages.password[0])
            : (this.password = null);
          errorMessages.non_field_errors
            ? (this.validation.password = errorMessages.non_field_errors[0])
            : (this.password = null);
        });
    },
  },
};
</script>
