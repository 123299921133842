export default {
  data() {
    return {
      countryDropdownOptions: [
        { value: "AU", label: "Australia" },
        { value: "AT", label: "Austria" },
        { value: "BE", label: "Belgium" },
        { value: "BR", label: "Brazil" },
        { value: "BG", label: "Bulgaria" },
        { value: "CA", label: "Canada" },
        { value: "HR", label: "Croatia" },
        { value: "CY", label: "Cyprus" },
        { value: "CZ", label: "Czech Republic" },
        { value: "DK", label: "Denmark" },
        { value: "EE", label: "Estonia" },
        { value: "FI", label: "Finland" },
        { value: "FR", label: "France" },
        { value: "DE", label: "Germany" },
        { value: "GI", label: "Gibraltar" },
        { value: "GR", label: "Greece" },
        { value: "HK", label: "Hong Kong" },
        { value: "HU", label: "Hungary" },
        { value: "ID", label: "Indonesia" },
        { value: "IE", label: "Ireland" },
        { value: "IT", label: "Italy" },
        { value: "JP", label: "Japan" },
        { value: "LV", label: "Latvia" },
        { value: "LI", label: "Liechtenstein" },
        { value: "LT", label: "Lithuania" },
        { value: "LU", label: "Luxembourg" },
        { value: "MY", label: "Malaysia" },
        { value: "MT", label: "Malta" },
        { value: "MX", label: "Mexico" },
        { value: "NL", label: "Netherlands" },
        { value: "NZ", label: "New Zealand" },
        { value: "NO", label: "Norway" },
        { value: "PL", label: "Poland" },
        { value: "PT", label: "Portugal" },
        { value: "RO", label: "Romania" },
        { value: "SG", label: "Singapore" },
        { value: "SK", label: "Slovakia" },
        { value: "SI", label: "Slovenia" },
        { value: "ES", label: "Spain" },
        { value: "SE", label: "Sweden" },
        { value: "CH", label: "Switzerland" },
        { value: "TH", label: "Thailand" },
        { value: "AE", label: "United Arab Emirates" },
        { value: "GB", label: "United Kingdom" },
        { value: "US", label: "United States" },
      ],
      countryList: {
        AU: { name: "Australia", currency: "aud", locale: "en-AU" },
        AT: { name: "Austria", currency: "eur", locale: "de-AT" },
        BE: { name: "Belgium", currency: "eur", locale: "nl-BE" },
        BR: { name: "Brazil", currency: "brl", locale: "pt-BR" },
        BG: { name: "Bulgaria", currency: "bgn", locale: "bg-BG" },
        CA: { name: "Canada", currency: "cad", locale: "en-CA" },
        HR: { name: "Croatia", currency: "hrk", locale: "hr-HR" },
        CY: { name: "Cyprus", currency: "eur", locale: "el-CY" },
        CZ: { name: "Czech Republic", currency: "czk", locale: "cs-CZ" },
        DK: { name: "Denmark", currency: "dkk", locale: "da-DK" },
        EE: { name: "Estonia", currency: "eur", locale: "et-EE" },
        FI: { name: "Finland", currency: "eur", locale: "fi-FI" },
        FR: { name: "France", currency: "eur", locale: "fr-FR" },
        DE: { name: "Germany", currency: "eur", locale: "de-DE" },
        GI: { name: "Gibraltar", currency: "gip", locale: "en-GI" },
        GR: { name: "Greece", currency: "eur", locale: "el-GR" },
        HK: { name: "Hong Kong", currency: "hkd", locale: "en-HK" },
        HU: { name: "Hungary", currency: "huf", locale: "hu-HU" },
        ID: { name: "Indonesia", currency: "idr", locale: "id-ID" },
        IE: { name: "Ireland", currency: "eur", locale: "en-IE" },
        IT: { name: "Italy", currency: "eur", locale: "it-IT" },
        JP: { name: "Japan", currency: "jpy", locale: "ja-JP" },
        LV: { name: "Latvia", currency: "eur", locale: "lv-LV" },
        LI: { name: "Liechtenstein", currency: "chf", locale: "de-LI" },
        LT: { name: "Lithuania", currency: "eur", locale: "lt-LT" },
        LU: { name: "Luxembourg", currency: "eur", locale: "fr-LU" },
        MY: { name: "Malaysia", currency: "myr", locale: "ms-MY" },
        MT: { name: "Malta", currency: "eur", locale: "mt-MT" },
        MX: { name: "Mexico", currency: "mxn", locale: "es-MX" },
        NL: { name: "Netherlands", currency: "eur", locale: "nl-NL" },
        NZ: { name: "New Zealand", currency: "nzd", locale: "en-NZ" },
        NO: { name: "Norway", currency: "nok", locale: "nb-NO" },
        PL: { name: "Poland", currency: "pln", locale: "pl-PL" },
        PT: { name: "Portugal", currency: "eur", locale: "pt-PT" },
        RO: { name: "Romania", currency: "ron", locale: "ro-RO" },
        SG: { name: "Singapore", currency: "sgd", locale: "en-SG" },
        SK: { name: "Slovakia", currency: "eur", locale: "sk-SK" },
        SI: { name: "Slovenia", currency: "eur", locale: "sl-SI" },
        ES: { name: "Spain", currency: "eur", locale: "es-ES" },
        SE: { name: "Sweden", currency: "sek", locale: "sv-SE" },
        CH: { name: "Switzerland", currency: "chf", locale: "de-CH" },
        TH: { name: "Thailand", currency: "thb", locale: "th-TH" },
        AE: { name: "United Arab Emirates", currency: "aed", locale: "ar-AE" },
        GB: { name: "United Kingdom", currency: "gbp", locale: "en-GB" },
        US: { name: "United States", currency: "usd", locale: "en-US" },
      },
    };
  },
  methods: {
    formatCurrency(
      amount,
      countryCode,
      currency,
      symbolOnly = false,
      maxFractionalDigits = 0
    ) {
      if (currency === "") currency = this.countryList[countryCode].currency;
      const locale = this.countryList[countryCode].locale;
      if (symbolOnly) {
        return new Intl.NumberFormat(locale, {
          style: "currency",
          currency: currency,
        })
          .format(0)
          .replace(/\d|,|\./g, "")
          .trim();
      } else {
        return new Intl.NumberFormat(locale, {
          style: "currency",
          maximumFractionDigits: maxFractionalDigits,
          currency: currency,
        }).format(amount);
      }
    },
    currencyIsPreffix(countryCode, currency) {
      const locale = this.countryList[countryCode].locale;
      const format = new Intl.NumberFormat(locale, {
        style: "currency",
        currency: currency,
      }).format(0);
      if (format.indexOf("0") === 0) return false;
      else return true;
    },
  },
};
