<template>
  <div
    :class="[
      !dashboardPreview ? 'space-y-6 lg:space-y-10' : '',
      dashboardPreview ? 'space-y-2' : '',
    ]"
    class=""
  >
    <h1
      :data-aos="
        !dashboardPreview && event.properties.animate ? 'fade-in' : null
      "
      data-aos-once="true"
      data-aos-duration="1000"
      data-aos-delay="0"
      :class="[
        layout == 'split' && !dashboardPreview ? 'text-4xl lg:text-8xl' : '',
        layout != 'split' && !dashboardPreview ? 'text-6xl lg:text-8xl' : '',
        dashboardPreview ? 'text-3xl' : '',
      ]"
      :style="{
        color: event.properties.colors.heading,
        fontFamily: event.properties.font,
      }"
    >
      <span :class="layout == 'split' ? 'inline lg:block' : 'block'"
        >{{ event.name_host1 }}
      </span>
      <div :class="layout == 'split' ? 'inline lg:block' : 'block space-y-1'">
        <span
          v-if="event.name_host2"
          :style="{ color: event.properties.colors.accent }"
        >
          &
        </span>
        <span v-if="event.name_host2" class="inline">{{
          event.name_host2
        }}</span>
      </div>
    </h1>
    <div
      :class="[
        layout == 'split' && !dashboardPreview
          ? 'gap-2 lg:gap-6 lg:text-3xl'
          : '',
        layout != 'split' && !dashboardPreview
          ? 'gap-2 text-xl lg:gap-6 lg:text-3xl'
          : '',
        dashboardPreview ? 'space-x-1 text-[10px]' : '',
      ]"
      class="text flex flex-col lg:flex-row"
      :style="{ color: event.properties.colors.paragraph }"
    >
      <span
        :data-aos="
          !dashboardPreview && event.properties.animate ? 'fade-in' : null
        "
        data-aos-once="true"
        data-aos-duration="1000"
        data-aos-delay="200"
        v-if="event.start_datetime && event.properties.show_date"
        class="lg:items-top inline-flex items-center"
      >
        <span>{{ formatDate(this.event.start_datetime) }}</span>
      </span>
    </div>
    <p
      :data-aos="
        !dashboardPreview && event.properties.animate ? 'fade-in' : null
      "
      data-aos-once="true"
      data-aos-duration="1000"
      data-aos-delay="400"
      v-if="event.description"
      :class="[
        layout == 'split' && !dashboardPreview ? 'lg:text-2xl' : '',
        layout != 'split' && !dashboardPreview ? 'text-lg lg:text-2xl' : '',
        dashboardPreview ? 'text-[11px]' : '',
      ]"
      class="text"
      :style="{ color: event.properties.colors.paragraph }"
    >
      {{ event.description }}
    </p>
    <div
      :data-aos="
        !dashboardPreview && event.properties.animate ? 'fade-in' : null
      "
      data-aos-once="true"
      data-aos-duration="1000"
      data-aos-delay="600"
    >
      <a
        v-if="event.hashtag"
        :href="`https://instagram.com/${event.hashtag}/`"
        target="_blank"
        class="inline-block font-semibold"
        :class="[dashboardPreview ? 'text-xs' : 'block lg:text-3xl']"
        :style="{ color: event.properties.colors.paragraph }"
      >
        {{ `#${event.hashtag}` }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: ["event", "white", "dashboardPreview", "layout"],
};
</script>
