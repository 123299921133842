<template>
  <div>
    <h2 class="mb-3">
      {{ $dayjs().add(3, "year").startOf("year").format("YYYY") }} + Weddings
      <span class="text-sm text-gray-500">({{ events.length }})</span>
    </h2>
    <ListCard :events="events" date="start_datetime" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      events: [],
    };
  },
  mounted: function () {
    this.getData();
  },
  methods: {
    getData() {
      const params = {
        is_paid: false,
        event_from: this.$dayjs()
          .add(3, "year")
          .startOf("year")
          .format("YYYY-MM-DD"),
      };
      this.$axiosAuth
        .get("kiosk/events/", {
          params: params,
        })
        .then((res) => {
          this.events = res.data;
        })
        .catch((err) => {
          ElNotification({
            title: "Error",
            message: "Error getting data",
            type: "error",
          });
        });
    },
  },
};
</script>

<style>
</style>