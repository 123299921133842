<template>
  <div class="settings">
    <div
      v-loading.fullscreen.lock="loading"
      element-loading-background="#ffffff"
    ></div>
    <AdminLayout v-if="!loading && event">
      <div class="mx-auto max-w-7xl px-4 pt-8 sm:px-6 lg:px-8">
        <h1 class="text-2xl font-bold leading-7 text-black">Settings</h1>
        <el-tabs v-model="activeTab" class="mt-4">
          <el-tab-pane
            v-for="tab in tabs"
            :key="tab.label"
            :label="tab.label"
            :name="tab.name"
          >
            <div v-if="activeTab == 'first'" class="divide-y divide-black/5">
              <div
                class="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-4 pb-4 pt-2 md:grid-cols-3 lg:gap-y-10 lg:py-8"
              >
                <div>
                  <h2 class="text-base font-semibold leading-7 text-black">
                    Domain
                  </h2>
                  <p class="mt-1 text-sm leading-6 text-gray-400">
                    Update your event domain.
                  </p>
                </div>

                <form class="md:col-span-2" @submit.prevent>
                  <div
                    class="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6"
                  >
                    <div class="col-span-full space-y-4">
                      <el-tooltip
                        :disabled="event.total_contributions_fmt == 0"
                        effect="dark"
                        content="You can't change your domain once you have received contributions."
                        placement="top-start"
                      >
                        <el-input
                          class="url-input"
                          @input="checkDomainAvailable(eventForm.domain)"
                          v-model="eventForm.domain"
                          size="large"
                          :disabled="event.total_contributions_fmt > 0"
                        >
                          <template #prefix>
                            <span>lovereggie.com/</span>
                          </template>
                        </el-input>
                      </el-tooltip>
                      <el-alert
                        v-if="
                          !this.domainAvailable &&
                          eventForm.domain !== event.domain_name
                        "
                        title="Domain is already taken."
                        type="error"
                        :closable="false"
                        effect="dark"
                      />
                      <el-button
                        :disabled="
                          !this.domainAvailable ||
                          (event !== null &&
                            eventForm.domain === event.domain_name)
                        "
                        type="primary"
                        plain
                        @click="updateDomain"
                        >Update</el-button
                      >
                    </div>
                  </div>
                </form>
              </div>
              <div
                class="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-4 py-4 md:grid-cols-3 lg:gap-y-10 lg:py-8"
              >
                <div>
                  <h2 class="text-base font-semibold leading-7 text-black">
                    Country
                  </h2>
                  <p class="mt-1 text-sm leading-6 text-gray-400">
                    Change your location and currency.
                  </p>
                </div>

                <form class="md:col-span-2" @submit.prevent>
                  <div
                    class="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6"
                  >
                    <div class="col-span-full space-y-4">
                      <div>
                        <el-tooltip
                          :disabled="
                            !(
                              event !== null &&
                              event.stripe_registration_status !== 'incomplete'
                            )
                          "
                          effect="dark"
                          content="You can't change your country once you have verified your Stripe account."
                          placement="top-start"
                        >
                          <el-select
                            v-model="eventForm.countryCode"
                            filterable
                            class="mt-1 w-full"
                            size="large"
                            :disabled="
                              event !== null &&
                              event.stripe_registration_status !== 'incomplete'
                            "
                          >
                            <el-option
                              v-for="item in countryDropdownOptions"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            >
                              <span style="float: left">{{ item.label }}</span>
                              <span style="float: right"
                                ><country-flag
                                  :country="item.value.toLowerCase()"
                                  size="small"
                              /></span>
                            </el-option>
                          </el-select>
                        </el-tooltip>
                      </div>
                      <el-button
                        :disabled="
                          event !== null &&
                          event.stripe_registration_status !== 'incomplete'
                        "
                        type="primary"
                        plain
                        @click="updateCountry"
                        >Update</el-button
                      >
                    </div>
                  </div>
                </form>
              </div>
              <div
                class="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-4 pb-4 pt-4 md:grid-cols-3 lg:gap-y-10 lg:py-8"
              >
                <div>
                  <h2 class="text-base font-semibold leading-7 text-black">
                    Registry Password
                  </h2>
                  <p class="mt-1 text-sm leading-6 text-gray-400">
                    Keep your registry private with a password, but don't forget
                    to share it with your guests!
                  </p>
                </div>

                <form class="md:col-span-2" @submit.prevent>
                  <div
                    class="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6"
                  >
                    <div class="col-span-full space-y-4">
                      <el-switch
                        size="large"
                        active-text="Enabled"
                        inactive-text="Disabled"
                        v-model="eventForm.passwordProtected"
                        @change="updateRegistryPasswordProtection"
                      />
                      <el-input
                        v-model="eventForm.password"
                        :disabled="!eventForm.passwordProtected"
                        size="large"
                      >
                      </el-input>
                      <el-button
                        :disabled="
                          !eventForm.passwordProtected ||
                          (eventForm.password && eventForm.password.length <= 0)
                        "
                        type="primary"
                        plain
                        @click="updateRegistryPassword"
                        >Update</el-button
                      >
                    </div>
                  </div>
                </form>
              </div>
              <div
                class="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-4 pb-4 pt-4 md:grid-cols-3 lg:gap-y-10 lg:py-8"
              >
                <div>
                  <h2 class="text-base font-semibold leading-7 text-black">
                    Other
                  </h2>
                  <p class="mt-1 text-sm leading-6 text-gray-400">
                    Some other registry settings
                  </p>
                </div>

                <form class="md:col-span-2" @submit.prevent>
                  <div
                    class="grid grid-cols-1 gap-x-6 sm:max-w-xl sm:grid-cols-6"
                  >
                    <div class="col-span-full">
                      <el-checkbox
                        v-model="eventForm.showGiftPrices"
                        label="Show gift prices on my registry"
                        size="large"
                        @change="updateGiftPrices"
                      />
                    </div>
                    <div class="col-span-full">
                      <el-checkbox
                        v-model="eventForm.moveFundedGiftEnd"
                        label="Move funded gifts to the bottom of the page"
                        size="large"
                        @change="updateFundedGiftEnd"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div v-if="activeTab == 'second'" class="divide-y divide-black/5">
              <div
                class="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-4 pb-4 pt-2 md:grid-cols-3 lg:gap-y-10 lg:py-8"
              >
                <div>
                  <h2 class="text-base font-semibold leading-7 text-black">
                    Email
                  </h2>
                  <p class="mt-1 text-sm leading-6 text-gray-400">
                    Your account email address.
                  </p>
                </div>

                <form class="md:col-span-2" @submit.prevent>
                  <div
                    class="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6"
                  >
                    <div class="col-span-full space-y-4">
                      <el-tooltip
                        effect="dark"
                        content="Email help@lovereggie.com to change your email address"
                        placement="top-start"
                      >
                        <el-input
                          class="url-input"
                          v-model="email"
                          size="large"
                          :disabled="!event.email_bounced"
                        >
                        </el-input>
                      </el-tooltip>
                      <el-button
                        v-if="event.email_bounced"
                        type="primary"
                        plain
                        @click="updateEmail"
                        >Update</el-button
                      >
                    </div>
                  </div>
                </form>
              </div>
              <div
                class="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-4 py-6 md:grid-cols-3 lg:gap-y-10 lg:py-16"
              >
                <div>
                  <h2 class="text-base font-semibold leading-7 text-black">
                    Password
                  </h2>
                  <p class="mt-1 text-sm leading-6 text-gray-400">
                    Change your password.
                  </p>
                </div>

                <form class="md:col-span-2" @submit.prevent>
                  <div
                    class="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6"
                  >
                    <div v-if="!isSocial" class="col-span-full space-y-4">
                      <div v-for="(input, key) in userForm" :key="key">
                        <p
                          class="el-form-item__label"
                          style="justify-content: flex-start"
                        >
                          {{ input.label }}
                        </p>
                        <el-form-item>
                          <el-input
                            v-model="input.value"
                            size="large"
                            type="password"
                          />
                        </el-form-item>
                        <el-alert
                          v-show="input.validation"
                          v-for="(error, index) in input.validation"
                          :key="index"
                          :title="error"
                          type="error"
                          :closable="false"
                          class="mb-2"
                        />
                      </div>
                      <el-button
                        :disabled="
                          !userForm.old_password.value ||
                          !userForm.new_password1.value ||
                          !userForm.new_password2.value
                        "
                        type="primary"
                        plain
                        @click="changePassword"
                        >Update</el-button
                      >
                    </div>
                    <div v-else class="col-span-full">
                      Your account is connected via Google. You cannot update
                      these details.
                    </div>
                  </div>
                </form>
              </div>
              <div
                class="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-4 py-6 md:grid-cols-3 lg:gap-y-10 lg:py-16"
              >
                <div>
                  <h2 class="text-base font-semibold leading-7 text-black">
                    Delete account
                  </h2>
                  <p class="mt-1 text-sm leading-6 text-gray-400">
                    No longer want to use Reggie? Once you click delete your
                    registry and all data with be deleted permanently.
                  </p>
                </div>

                <form class="flex items-start md:col-span-2">
                  <el-button
                    @click="eventDeleteDialogVisible = true"
                    type="danger"
                    >Delete My Registry</el-button
                  >
                </form>
              </div>
            </div>
            <div v-if="activeTab == 'third'" class="divide-y divide-black/5">
              <div
                class="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-4 pb-4 pt-2 md:grid-cols-3 lg:gap-y-10 lg:py-8"
              >
                <div>
                  <h2 class="text-base font-semibold leading-7 text-black">
                    Payment Settings
                  </h2>
                  <p class="mt-1 text-sm leading-6 text-gray-400">
                    Settings related to your contribution payments.
                  </p>
                </div>

                <form class="md:col-span-2" @submit.prevent>
                  <div
                    class="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6"
                  >
                    <div class="col-span-full">
                      <div
                        v-for="checkbox in paymentForm"
                        :key="checkbox.label"
                      >
                        <div class="flex items-center gap-x-1">
                          <el-checkbox
                            v-model="checkbox.checked"
                            :label="checkbox.label"
                            size="large"
                            @change="updatePaymentSettings"
                          />
                          <Tooltip
                            v-if="checkbox.tooltip"
                            :value="checkbox.tooltip"
                          />
                        </div>
                        <el-alert
                          v-if="checkbox.description"
                          type="info"
                          :closable="false"
                          v-html="checkbox.description"
                          class="text-xs leading-5"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div
                class="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-4 pb-4 pt-4 md:grid-cols-3 lg:gap-y-10 lg:py-8"
              >
                <div>
                  <h2 class="text-base font-semibold leading-7 text-black">
                    Payment Details
                  </h2>
                  <p class="mt-1 text-sm leading-6 text-gray-400">
                    Update your payment details.
                  </p>
                </div>

                <form class="md:col-span-2" @submit.prevent>
                  <div
                    class="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6"
                  >
                    <div class="col-span-full">
                      <VerifyStripe
                        :event="event"
                        page="settings"
                        class="ml-1 mt-5"
                      />
                      <div class="mt-2">
                        <el-alert
                          type="info"
                          title="This will redirect you to Stripe's webpage where you can update your details."
                          :closable="false"
                          class="text-xs leading-5"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div v-if="activeTab == 'fourth'" class="divide-y divide-black/5">
              <div
                class="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-4 pb-4 pt-2 md:grid-cols-3 lg:gap-y-10 lg:py-8"
              >
                <div>
                  <h2 class="text-base font-semibold leading-7 text-black">
                    Notification settings
                  </h2>
                  <p class="mt-1 text-sm leading-6 text-gray-400">
                    Control your email notifications.
                  </p>
                </div>

                <form class="md:col-span-2" @submit.prevent>
                  <div
                    class="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6"
                  >
                    <div class="col-span-full">
                      <el-checkbox
                        v-for="checkbox in notificationForm"
                        :key="checkbox.label"
                        v-model="checkbox.checked"
                        :label="checkbox.label"
                        size="large"
                        @change="updateNotifications"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <el-dialog v-model="eventDeleteDialogVisible" width="30%">
        <template>
          <div class="flex items-center gap-x-1">
            <ExclamationTriangleIcon
              class="h-7 w-7 text-red-400"
              aria-hidden="true"
            />
            <h4>Delete Event</h4>
          </div>
        </template>
        <p>
          Type <span class="font-bold">DELETE</span> below to delete your
          registry.
        </p>
        <p class="mt-2">
          Once you delete, there is no way to retrieve your data!
        </p>

        <el-input
          v-model="deleteInput"
          size="large"
          class="mt-6"
          placeholder="DELETE"
        />

        <template #footer>
          <span class="dialog-footer">
            <el-button
              @click="eventDeleteDialogVisible = false"
              type="primary"
              plain
              >Cancel</el-button
            >
            <el-button
              @click="deleteEvent"
              :disabled="deleteInput != 'DELETE'"
              type="danger"
              >Delete</el-button
            >
          </span>
        </template>
      </el-dialog>
    </AdminLayout>
  </div>
</template>

<script>
import AdminLayout from "@/layouts/Admin.vue";
import URLInput from "@/components/public/URLInput.vue";
import VerifyStripe from "@/components/admin/VerifyStripe.vue";
import { ElMessageBox } from "element-plus";
import { ExclamationTriangleIcon } from "@heroicons/vue/20/solid";
import CountryFlag from "vue-country-flag-next";
import { mapActions } from "pinia";
import { useUserStore } from "@/stores/UserStore";

export default {
  components: {
    AdminLayout,
    URLInput,
    ElMessageBox,
    ExclamationTriangleIcon,
    VerifyStripe,
    CountryFlag,
  },

  data() {
    return {
      loading: true,
      event: null,
      email: null,
      activeTab: "first",
      deleteInput: "",
      domainAvailable: true,
      eventDeleteDialogVisible: false,
      passwordError: null,
      tabs: [
        {
          label: "Registry",
          name: "first",
        },
        {
          label: "Account",
          name: "second",
        },
        {
          label: "Payments",
          name: "third",
        },
        {
          label: "Notifications",
          name: "fourth",
        },
      ],
      eventForm: {
        domain: "",
        countryCode: "",
        passwordProtected: false,
        password: "",
        showGiftPrices: true,
      },
      isSocial: false,
      userForm: {
        old_password: {
          label: "Old Password",
          value: "",
          validation: "",
        },
        new_password1: {
          label: "New Password",
          value: "",
          validation: "",
        },
        new_password2: {
          label: "Confirm Password",
          value: "",
          validation: "",
        },
      },
      notificationForm: {
        contribution: {
          label: "Email me when someone contributes to my registry",
          checked: false,
        },
        giftFunded: {
          label: "Email me when a gift is fully funded",
          checked: false,
        },
      },
      paymentForm: {
        stripe_fee: {
          label: "My guests pay the processing fee",
          description: `Our payment provider Stripe charges a fee per domestic transaction. By checking this box, you can choose to have your guests pay this fee instead of you. </br> If they use an international credit card, there will be an additional fee that you will pay, regardless of whether you have checked this box.`,
          tooltip: "",
          checked: false,
        },
        // autoPayout: {
        //   label: "Automatically pay funds to my bank account when available",
        //   tooltip:
        //     "If you leave this unchecked, you can manually request a payout at any time from your dashboard or payouts page.",
        //   checked: false,
        // },
      },
    };
  },

  mounted() {
    this.loading = true;
    if (this.$route.query.tab) {
      this.activeTab = this.$route.query.tab;
    }
    this.getData();
  },

  watch: {
    "$route.query.tab"() {
      if (this.$route.query.tab) {
        this.activeTab = this.$route.query.tab;
      }
    },
  },

  methods: {
    ...mapActions(useUserStore, [
      "authenticateUser",
      "updateUserEmail",
      "updateEmailBounced",
      "updateEmailVerified",
      "updateUserCountryCode",
    ]),
    getData() {
      Promise.all([
        this.$axiosAuth.get(`host/event/`),
        this.$axiosAuth.get(`users/profile/`),
      ])
        .then((res) => {
          this.event = res[0].data[0];
          this.isSocial = res[1].data.is_social;
          this.email = res[1].data.email;
          this.eventForm.domain = this.event.domain_name;
          this.eventForm.countryCode = this.event.country_code;
          this.eventForm.passwordProtected = this.event.password_protected;
          this.eventForm.password = this.event.password;
          this.eventForm.showGiftPrices = this.event.properties.showGiftPrices;
          this.eventForm.moveFundedGiftEnd =
            this.event.properties.moveFundedGiftEnd;
          this.paymentForm.stripe_fee.checked = this.event.stripe_fee;

          this.paymentForm.stripe_fee.description = `Our payment provider Stripe charges a ${
            this.event.fee.percent_fmt
          }% + ${this.formatCurrency(
            this.event.fee.flat_fmt,
            this.event.country_code,
            this.event.currency,
            false,
            2
          )} fee per domestic transaction. By checking this box, you can choose to have your guests pay this fee instead of you. </br> If they use an international credit card, there will be an additional fee that you will pay, regardless of whether you have checked this box.`;

          for (const notification in this.event.properties.notifications) {
            this.notificationForm[notification].checked =
              this.event.properties.notifications[notification];
          }
          this.updateEmailBounced(this.event.email_bounced);
          this.updateEmailVerified(this.event.email_verified);
        })
        .then((res) => {
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    updateDomain() {
      this.$axiosAuth
        .patch(`host/event/${this.event.event_id}/`, {
          domain_name: this.eventForm.domain,
        })
        .then((res) => {
          ElNotification({
            title: "Success",
            message: "Domain updated successfully",
            type: "success",
          });
          this.getData();
        })
        .catch((err) => {
          ElNotification({
            title: "Error",
            message: err.response.data.domain_name[0],
            type: "error",
          });
        });
    },

    updateEmail() {
      this.$axiosAuth
        .post(`users/change-email/`, {
          new_email: this.email,
        })
        .then((res) => {
          ElNotification({
            title: "Success",
            message: "Email updated successfully",
            type: "success",
          });
          // update store
          this.updateUserEmail(this.email);
          this.getData();
        })
        .catch((err) => {
          ElNotification({
            title: "Error",
            message: err.response.data,
            type: "error",
          });
        });
    },
    updateRegistryPasswordProtection() {
      this.$axiosAuth
        .patch(`host/event/${this.event.event_id}/`, {
          password_protected: this.eventForm.passwordProtected,
        })
        .then((res) => {
          const messageSuffix = this.eventForm.passwordProtected
            ? "enabled"
            : "disabled";
          ElNotification({
            title: "Success",
            message: `Password protection ${messageSuffix}`,
            type: "success",
          });
        })
        .catch((err) => {
          ElNotification({
            title: "Error",
            message: err.response.data.non_field_errors[0],
            type: "error",
          });
        });
    },
    updateRegistryPassword() {
      this.$axiosAuth
        .patch(`host/event/${this.event.event_id}/`, {
          password: this.eventForm.password,
        })
        .then((res) => {
          ElNotification({
            title: "Success",
            message: `Password updated succesfully`,
            type: "success",
          });
          this.getData();
        })
        .catch((err) => {
          ElNotification({
            title: "Error",
            message: err.response.data.non_field_errors[0],
            type: "error",
          });
        });
    },
    updateCountry() {
      this.$axiosAuth
        .patch(`host/event/${this.event.event_id}/`, {
          country_code: this.eventForm.countryCode,
        })
        .then((res) => {
          ElNotification({
            title: "Success",
            message: "Country updated successfully",
            type: "success",
          });
          this.updateUserCountryCode(this.eventForm.countryCode);
          this.getData();
        })
        .catch((err) => {
          ElNotification({
            title: "Error",
            message: err.response.data.non_field_errors[0],
            type: "error",
          });
        });
    },
    changePassword() {
      this.$axiosAuth
        .post(`users/password/change/`, {
          old_password: this.userForm.old_password.value,
          new_password1: this.userForm.new_password1.value,
          new_password2: this.userForm.new_password2.value,
        })
        .then((res) => {
          ElNotification({
            title: "Success",
            message: "Password updated successfully",
            type: "success",
          });
          for (const key in this.userForm) {
            //clear the form
            this.userForm[key].value = "";
            this.userForm[key].validation = null;
          }
          this.getData();
        })
        .catch((err) => {
          let errors = err.response.data;
          Object.keys(this.userForm).forEach((item) => {
            this.userForm[item].validation = null;
            Object.keys(errors).forEach((key) => {
              if (item == key) {
                this.userForm[item].validation = errors[key];
              }
              //Django returns the password don't match error as a different key, handle here
              if (key == "non_field_errors" && item == "password2") {
                item.validation = errors[key];
              }
            });
          });
        });
    },
    updateNotifications() {
      for (const notification in this.notificationForm) {
        this.event.properties.notifications[notification] =
          this.notificationForm[notification].checked;
      }
      this.$axiosAuth
        .patch(`host/event/${this.event.event_id}/`, {
          properties: this.event.properties,
        })
        .then((res) => {
          ElNotification({
            title: "Success",
            message: "Notifications updated successfully",
            type: "success",
          });
          this.getData();
        })
        .catch((err) => {
          ElNotification({
            title: "Error",
            message: "Notifications could not be updated",
            type: "error",
          });
        });
    },
    updatePaymentSettings() {
      this.$axiosAuth
        .patch(`host/event/${this.event.event_id}/`, {
          stripe_fee: this.paymentForm.stripe_fee.checked,
        })
        .then((res) => {
          ElNotification({
            title: "Success",
            message: "Payment settings updated successfully",
            type: "success",
          });
          this.getData();
        })
        .catch((err) => {
          ElNotification({
            title: "Error",
            message: "Payment settings could not be updated",
            type: "error",
          });
        });
    },
    updateGiftPrices() {
      this.event.properties.showGiftPrices = this.eventForm.showGiftPrices;
      this.$axiosAuth
        .patch(`host/event/${this.event.event_id}/`, {
          properties: this.event.properties,
        })
        .then((res) => {
          ElNotification({
            title: "Success",
            message: "Settings updated successfully",
            type: "success",
          });
          this.getData();
        })
        .catch((err) => {
          ElNotification({
            title: "Error",
            message: "Settings could not be updated",
            type: "error",
          });
        });
    },
    updateFundedGiftEnd() {
      this.event.properties.moveFundedGiftEnd =
        this.eventForm.moveFundedGiftEnd;
      this.$axiosAuth
        .patch(`host/event/${this.event.event_id}/`, {
          properties: this.event.properties,
        })
        .then((res) => {
          ElNotification({
            title: "Success",
            message: "Settings updated successfully",
            type: "success",
          });
          this.getData();
        })
        .catch((err) => {
          ElNotification({
            title: "Error",
            message: "Settings could not be updated",
            type: "error",
          });
        });
    },
    deleteEvent() {
      this.$axiosAuth
        .delete(`users/delete/`)
        .then((res) => {
          ElNotification({
            title: "Success",
            message: "Event deleted successfully",
            type: "success",
          });
          setTimeout(() => {
            location.reload();
          }, 3000);
        })
        .catch((err) => {
          ElNotification({
            title: "Error",
            message: "You can't delete an event that already has contributions",
            type: "error",
          });
        });
    },
  },
};
</script>

<style>
.settings {
  .el-form-item__label {
    @apply text-sm text-gray-700 !important;
  }
}
</style>
