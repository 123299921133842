<template>
  <div
    v-loading.fullscreen.lock="loading"
    element-loading-background="#ffffff"
  ></div>
  <AdminLayout v-if="!loading">
    <div class="mx-auto max-w-7xl px-4 pt-8 sm:px-6 lg:px-8">
      <OverviewHeader
        :event="event"
        @open-dialog="previewDialogVisible = true"
      />
      <section class="mt-6 grid grid-cols-4 gap-x-6">
        <div class="col-span-3 space-y-6">
          <card>
            <template #heading> Details </template>
            <ul role="list" class="divide-y divide-gray-100">
              <li
                v-for="(item, index) in eventItemsToShow"
                :key="item"
                class="flex justify-between gap-x-6 py-5"
              >
                <div class="flex min-w-0 gap-x-4">
                  <p class="text-sm/6 font-semibold text-gray-900">
                    {{ toTitleCase(unslugify(index)) }}
                  </p>
                </div>
                <div class="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                  <p class="text-sm/6 text-gray-900">{{ item }}</p>
                </div>
              </li>
            </ul>
          </card>

          <PayoutsTable
            v-if="event"
            :payouts="event.payouts.data"
            :countryCode="event.country_code"
            :currency="event.currency"
          />
        </div>

        <div class="col-span-1">
          <card>
            <el-timeline style="max-width: 600px">
              <el-timeline-item
                v-if="event.start_datetime"
                color="#E16345"
                :timestamp="$dayjs(event.start_datetime).format('YYYY-MM-DD')"
              >
                Wedding Date
              </el-timeline-item>
              <el-timeline-item
                v-if="event.paid_datetime"
                color="#0bbd87"
                :timestamp="$dayjs(event.paid_datetime).format('YYYY-MM-DD')"
              >
                Event Paid
              </el-timeline-item>
              <el-timeline-item
                v-if="event.created"
                :timestamp="$dayjs(event.created).format('YYYY-MM-DD')"
              >
                Event Created
              </el-timeline-item>
            </el-timeline>
          </card>
        </div>
      </section>
    </div>
  </AdminLayout>
  <el-dialog
    width="100%"
    v-model="previewDialogVisible"
    modalClass="preview-dialog"
  >
    <EventPage :kiosk="true" :eventProp="event" />
  </el-dialog>
</template>

<script>
import AdminLayout from "@/layouts/Admin.vue";
import EventPage from "@/views/EventPage.vue";

export default {
  components: {
    AdminLayout,
    EventPage,
  },
  mounted() {
    this.getData();
  },
  computed: {
    eventItemsToShow() {
      const list = [
        "currency",
        "referred_by",
        "is_paid",
        "is_live",
        "password_protected",
        "emails_sent",
      ];
      return Object.keys(this.event).reduce((acc, key) => {
        if (list.includes(key)) {
          acc[key] = this.event[key];
        }
        return acc;
      }, {});
    },
  },
  data() {
    return {
      loading: true,
      event: null,
      previewDialogVisible: false,
    };
  },

  methods: {
    getData() {
      this.$axiosAuth
        .get(`/kiosk/events/${this.$route.params.eventId}`)
        .then((res) => {
          this.event = res.data;
          this.loading = false;
        })
        .catch((err) => {
          ElNotification({
            title: "Error",
            message: "Error getting data",
            type: "error",
          });
        });
    },
  },
};
</script>

<style>
.el-timeline {
  li:last-of-type {
    padding-bottom: 0px !important;
  }
}

.preview-dialog {
  .el-dialog {
    padding: 0;
    .show-close {
      display: none;
    }
    .el-dialog__body {
      padding: 0 !important;
    }
  }
}
</style>