<template>
  <div>Verifying email...</div>
</template>

<script>
import { useUserStore } from "@/stores/UserStore";
import { mapActions } from "pinia";
export default {
  mounted() {
    if (this.$route.params.token) {
      this.$axios
        .post("users/account-confirm-email/", { key: this.$route.params.token })
        .then((res) => {
          ElNotification({
            title: "Success",
            message: "Email verified.",
            type: "success",
          });
          this.updateEmailVerified(true);
          this.$router.push({ name: "dashboard" });
        })
        .catch((err) => {
          ElNotification({
            title: "Error",
            message: "Something went wrong.",
            type: "error",
          });
          this.$router.push({ name: "dashboard" });
        });
    } else {
      ElNotification({
        title: "Error",
        message: "Something went wrong.",
        type: "error",
      });
      this.$router.push({ name: "dashboard" });
    }
  },
  methods: {
    ...mapActions(useUserStore, ["updateEmailVerified"]),
  }
};
</script>
