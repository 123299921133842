<template>
  <main
    class="min-h-screen bg-cover bg-center"
    style="
      background-image: url('https://media.tenor.com/g8qxKFs2V1wAAAAC/really-go-on.gif');
    "
  >
    <div
      class="flex min-h-screen flex-col items-center justify-center bg-black/50"
    >
      <div
        class="max-w-7xl px-4 py-16 text-center sm:px-6 sm:py-24 lg:px-8 lg:py-48"
      >
        <!-- <p class="text-base font-semibold text-black text-opacity-50">404</p> -->
        <h1
          class="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl"
        >
          Someone's an eager beaver
        </h1>
        <p class="mt-2 text-lg font-medium text-white">
          This registry isn't live yet...come back soon.
        </p>
        <div class="mt-6">
          <el-button type="primary" size="large" plain>Home</el-button>
        </div>
      </div>
    </div>
  </main>
</template>
