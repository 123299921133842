<template>
  <LoginLayout>
    <div>
      <h3 class="font-heading text-6xl text-primary">reggie</h3>
      <h2 class="mt-2 font-subheading text-3xl tracking-tight text-gray-900">
        Password Reset
      </h2>
      <p class="mt-4 text-sm text-gray-600">Enter your new password below.</p>
    </div>

    <div class="mt-4">
      <el-form class="">
        <el-form-item :error="validation.newPassword1">
          <label class="block text-sm font-medium leading-6 text-gray-900"
            >New password</label
          >
          <el-input
            v-model="resetPasswordForm.newPassword1"
            type="password"
            size="large"
          />
        </el-form-item>

        <el-form-item :error="validation.newPassword2">
          <label class="block text-sm font-medium leading-6 text-gray-900"
            >Confirm new password</label
          >
          <el-input
            v-model="resetPasswordForm.newPassword2"
            type="password"
            size="large"
          />
        </el-form-item>

        <el-button
          @click="resetPassword"
          class="w-full"
          type="primary"
          size="large"
          plain
          >Reset Password</el-button
        >
      </el-form>

      <p class="mt-6 text-sm text-gray-500">
        Ended up here by mistake?
        <router-link
          class="font-semibold text-primary hover:opacity-70"
          :to="{ name: 'login' }"
        >
          Back to login
        </router-link>
      </p>
    </div>
  </LoginLayout>
</template>

<script>
import LoginLayout from "@/layouts/Login.vue";

export default {
  components: { LoginLayout },

  beforeMount() {
    if (!(this.$route.params.uid && this.$route.params.token)) {
      this.$router.push({ name: "login" });
    }
  },
  data() {
    return {
      resetPasswordForm: {
        newPassword1: "",
        newPassword2: "",
      },
      validation: {
        newPassword1: "",
        newPassword2: "",
      },
    };
  },
  methods: {
    resetPassword() {
      this.$axios
        .post("/users/password/reset/confirm/", {
          uid: this.$route.params.uid,
          token: this.$route.params.token,
          new_password1: this.resetPasswordForm.newPassword1,
          new_password2: this.resetPasswordForm.newPassword2,
        })
        .then((res) => {
          ElNotification.success("Password reset successfully");
          this.$router.push({ name: "login" });
        })
        .catch((error) => {
          this.validation = {
            newPassword1: null,
            newPassword2: null,
          };

          const errorMessages = error.response.data;
          errorMessages.new_password1
            ? (this.validation.newPassword1 = errorMessages.new_password1[0])
            : (this.newPassword1 = null);
          errorMessages.new_password2
            ? (this.validation.newPassword2 = errorMessages.new_password2[0])
            : (this.newPassword2 = null);
          errorMessages.non_field_errors
            ? (this.validation.newPassword2 = errorMessages.non_field_errors[0])
            : (this.newPassword2 = null);
        });
    },
  },
};
</script>
