<template>
  <div class="min-h-full">
    <div class="flex flex-1 flex-col">
      <main class="flex-1 pb-8">
        <div>
          <!-- Activity list (smallest breakpoint only) -->
          <div class="shadow md:hidden">
            <ContributionsFeed
              v-if="event && contributions"
              :event="event"
              :contributions="contributions"
            />
          </div>

          <!-- Activity table (small breakpoint and up) -->
          <div class="hidden md:block">
            <div>
              <div class="flex flex-col">
                <div
                  class="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg"
                >
                  <table class="min-w-full divide-y divide-gray-200 bg-white">
                    <thead class="bg-gray-50">
                      <tr>
                        <th
                          class="w-44 bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                          scope="col"
                        >
                          Date
                        </th>
                        <th
                          class="w-56 bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                          scope="col"
                        >
                          Guest
                        </th>
                        <th
                          class="w-16 bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                          scope="col"
                        >
                          Gift
                        </th>
                        <th
                          class="w-36 bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                          scope="col"
                        >
                          Contribution
                        </th>
                        <th
                          class="w-36 bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                          scope="col"
                        >
                          Total
                        </th>
                        <th
                          class="w-fit bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                          scope="col"
                        >
                          Message
                        </th>
                      </tr>
                    </thead>
                    <tbody
                      v-show="contributions.length > 0"
                      class="divide-y divide-gray-200 bg-white"
                    >
                      <tr
                        v-for="contribution in contributions"
                        :key="contribution.id"
                        class="bg-white"
                      >
                        <td class="px-6 py-4 text-sm text-gray-900">
                          <p>
                            {{ formatDate(contribution.created) }}
                          </p>
                        </td>
                        <td class="px-6 py-4 text-sm text-gray-900">
                          <div class="flex items-center gap-2">
                            <Avatar :contribution="contribution" />
                            <div class="flex flex-col">
                              <p
                                class="text-gray-900 group-hover:text-gray-900"
                              >
                                {{ contribution.name }}
                              </p>
                              <p
                                class="text-gray-900 group-hover:text-gray-900"
                              >
                                {{ contribution.email }}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td class="px-6 py-4 text-sm text-gray-900">
                          <span
                            class="text-gray-900 group-hover:text-gray-900"
                            >{{ contribution.gift_name }}</span
                          >
                        </td>
                        <td class="px-6 py-4 text-sm text-gray-500">
                          <span class="text-gray-900">{{
                            formatCurrency(
                              contribution.amount_fmt,
                              event.country_code,
                              event.currency
                            )
                          }}</span>
                        </td>
                        <td class="px-6 py-4 text-sm text-gray-500">
                          <span class="mr-1 text-gray-900">{{
                            formatCurrency(
                              contribution.amount_total_fmt,
                              event.country_code,
                              event.currency,
                              false,
                              2
                            )
                          }}</span
                          ><Tooltip
                            v-if="
                              !contribution.stripe_fee || contribution.is_intl
                            "
                            :html="true"
                            :size="4"
                            :value="getFeeTooltip(contribution)"
                          />
                        </td>
                        <td class="max-w-md px-6 py-4 text-sm text-gray-500">
                          <p class="text-gray-900">
                            {{ contribution.message }}
                          </p>
                        </td>
                      </tr>
                    </tbody>
                    <tr v-show="contributions.length == 0">
                      <p
                        class="w-full overflow-visible whitespace-nowrap bg-transparent p-6"
                      >
                        No contributions yet
                      </p>
                    </tr>
                  </table>
                  <!-- Pagination -->
                  <!-- <nav
                    class="
                      flex
                      items-center
                      justify-between
                      border-t border-gray-200
                      bg-white
                      px-4
                      py-3
                      sm:px-6
                    "
                    aria-label="Pagination"
                  >
                    <div class="hidden sm:block">
                      <p class="text-sm text-gray-700">
                        Showing
                        {{ " " }}
                        <span class="font-medium">1</span>
                        {{ " " }}
                        to
                        {{ " " }}
                        <span class="font-medium">10</span>
                        {{ " " }}
                        of
                        {{ " " }}
                        <span class="font-medium">20</span>
                        {{ " " }}
                        results
                      </p>
                    </div>
                    <div class="flex flex-1 justify-between sm:justify-end">
                      <a
                        href="#"
                        class="
                          relative
                          inline-flex
                          items-center
                          rounded-md
                          border border-gray-300
                          bg-white
                          px-4
                          py-2
                          text-sm
                          font-medium
                          text-gray-700
                          hover:bg-gray-50
                        "
                        >Previous</a
                      >
                      <a
                        href="#"
                        class="
                          relative
                          ml-3
                          inline-flex
                          items-center
                          rounded-md
                          border border-gray-300
                          bg-white
                          px-4
                          py-2
                          text-sm
                          font-medium
                          text-gray-700
                          hover:bg-gray-50
                        "
                        >Next</a
                      >
                    </div>
                  </nav> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import Avatar from "@/components/admin/Avatar.vue";
import ContributionsFeed from "@/components/admin/ContributionsFeed.vue";

export default {
  components: {
    Avatar,
    ContributionsFeed,
  },
  props: ["event"],
  data() {
    return {
      loading: false,
      contributions: [],
      statusStyles: {
        success: "bg-green-100 text-green-800",
        processing: "bg-yellow-100 text-yellow-800",
        failed: "bg-gray-100 text-gray-800",
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.$axiosAuth
        .get(`host/contribution/`)
        .then((res) => {
          this.contributions = res.data;
        })
        .catch((err) => {});
    },
    getFeeTooltip(contribution) {
      let feeText = "";

      feeText = `<b>Card Fee:</b> ${this.formatCurrency(
        contribution.fee_fmt,
        this.event.country_code,
        this.event.currency,
        false,
        2
      )}`;

      if (contribution.is_intl) feeText += ` (intl.)`;

      return feeText;
    },
  },
};
</script>
