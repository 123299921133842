export default {
  data() {
    return {
      fonts: [
        "Bebas Neue",
        "Bowlby One",
        "Caveat",
        "Chewy",
        "Comfortaa",
        "Courier Prime",
        "Courgette",
        "DM Mono",
        "Dancing Script",
        "Indie Flower",
        "Josefin Slab",
        "Karma",
        "Lilita",
        "Lobster",
        "Lora",
        "Merienda",
        "Montserrat",
        "Pacifico",
        "Permanent Marker",
        "Playpen Sans",
        "Racing Sans One",
        "Rubik Mono One",
        "Sacramento",
        "Seymour One",
        "Shrikhand",
        "Taviraj",
        "Tilt Neon",
        "Tilt Prism",
        "Young Serif"
      ],
      clipPaths: [
        {
          name: "square",
          path: "inset(5%)",
        },
        {
          name: "circle",
          path: "circle(50% at 50% 50%)",
        },
        {
          name: "triangle",
          path: "polygon(50% 0%, 0% 100%, 100% 100%)",
        },
        {
          name: "hexagon",
          path: "polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%)",
        },
        {
          name: "octagon",
          path: "polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%)",
        },
        {
          name: "trapezoid",
          path: "polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%)",
        },
        {
          name: "bevel",
          path: "polygon(20% 0%, 80% 0%, 100% 20%, 100% 80%, 80% 100%, 20% 100%, 0% 80%, 0% 20%)",
        },
      ],
      dividers: [
        {
          name: "straight",
          divider: {
            main: "",
            preview: "",
          },
        },
        {
          name: "tilt",
          divider: {
            main: "polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 90px))",
            preview: "polygon(0 0,100% 0,100% 100%,0 calc(100% - 30px))",
          },
        },
        {
          name: "wavy",
          divider: {
            main: "radial-gradient(33.10px at 50% calc(100% - 46.80px),#000 99%,#0000 101%) calc(50% - 48px) 0/96px 100%, radial-gradient(33.10px at 50% calc(100% + 22.80px),#0000 99%,#000 101%) 50% calc(100% - 24px)/96px 100% repeat-x",
            preview:
              "radial-gradient(17.49px at 50% calc(100% - 24.00px),#000 99%,#0000 101%) calc(50% - 30px) 0/60px 100%, radial-gradient(17.49px at 50% calc(100% + 9.00px),#0000 99%,#000 101%) 50% calc(100% - 15px)/60px 100% repeat-x",
          },
        },
        {
          name: "zig-zag",
          divider: {
            main: "conic-gradient(from -45deg at bottom,#0000,#000 1deg 89deg,#0000 90deg) 50%/60.00px 100%",
            preview:
              "conic-gradient(from -45deg at bottom,#0000,#000 1deg 89deg,#0000 90deg) 50%/30.00px 100%",
          },
        },
        {
          name: "arrow",
          divider: {
            main: "polygon(0 0,100% 0,100% calc(100% - 90px),calc(50% + 90.00px) calc(100% - 90px),50% 100%,calc(50% - 90.00px) calc(100% - 90px),0 calc(100% - 90px))",
            preview:
              "polygon(0 0,100% 0,100% calc(100% - 20px),calc(50% + 20.00px) calc(100% - 20px),50% 100%,calc(50% - 20.00px) calc(100% - 20px),0 calc(100% - 20px))",
          },
        },
        {
          name: "triangle",
          divider: {
            main: "polygon(0 0,100% 0,100% calc(100% - 90px),50% 100%,0 calc(100% - 90px))",
            preview:
              "polygon(0 0,100% 0,100% calc(100% - 15px),50% 100%,0 calc(100% - 15px))",
          },
        },
      ],
    };
  },
};
