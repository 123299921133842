<template>
  <div class="amazon-table" v-loading.fullscreen.lock="loading">
    <div v-if="!loading">
      <div
        v-if="gifts.length > 0"
        class="mb-6 flex flex-col items-center justify-between gap-x-12 gap-y-4 overflow-hidden rounded-lg bg-white px-6 py-5 text-sm font-medium text-gray-900 shadow sm:text-gray-700 lg:flex-row"
      >
        <div>
          <h3 class="mb-2 text-base font-bold">
            Ready to purchase your gifts from Amazon?
          </h3>

          Click the Checkout button to be redirected to Amazon to complete your
          purchase. If you only want to buy some of your gifts now, use the
          checkboxes in the table to make your selection.
        </div>
        <el-button
          v-if="selectedGifts"
          @click="dialogVisible = true"
          type="primary"
          plain
          size="large"
          class="w-full lg:w-auto"
          >Checkout {{ giftsToCheckout.length }}
          {{ giftsToCheckout.length == 1 ? "gift" : "gifts" }}

          on Amazon</el-button
        >
      </div>
      <div
        v-else
        class="mb-6 flex flex-col items-center justify-between gap-x-12 gap-y-4 overflow-hidden rounded-lg bg-white px-6 py-5 text-sm font-medium text-gray-900 shadow sm:text-gray-700 lg:flex-row"
      >
        <div>
          With Reggie, you can add items directly from Amazon to your gift
          registry. Head to your
          <router-link
            class="cursor-pointer border-b border-red-300 font-bold"
            :to="{ name: 'gifts' }"
          >
            Gifts Page
          </router-link>
          to get started. Once you start adding gifts from Amazon, they will
          show in the table below.
        </div>
      </div>
      <el-tabs v-model="activeTab" @tab-click="handleClick">
        <!-- Gifts to buy -->
        <el-tab-pane :label="`To Buy (${toBuyGifts.length})`" name="to-buy">
          <el-table
            :data="toBuyGifts"
            stripe
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              type="selection"
              :selectable="selectable"
              width="55"
            />
            <el-table-column prop="name" label="Gift">
              <template #default="scope">
                <div class="flex items-center gap-x-4">
                  <img
                    :src="giftImageUrl(scope.row)"
                    class="h-5 w-5 object-contain object-center lg:h-16 lg:w-16"
                  />
                  <p class="truncate">{{ scope.row.name }}</p>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="amount_fmt" label="Price">
              <template #default="scope">
                <span>{{ `$${scope.row.amount_fmt}` }}</span>
              </template>
            </el-table-column>
          </el-table></el-tab-pane
        >
        <!-- Gifts purchased -->
        <el-tab-pane
          :label="`Purchased (${purchasedGifts.length})`"
          name="Purchased"
        >
          <el-table :data="purchasedGifts" stripe>
            <el-table-column prop="name" label="Gift">
              <template #default="scope">
                <div class="flex items-center gap-x-4">
                  <img
                    :src="giftImageUrl(scope.row)"
                    class="h-10 w-10 object-contain object-center lg:h-16 lg:w-16"
                  />
                  <p class="truncate">{{ scope.row.name }}</p>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="amount_fmt" label="Price">
              <template #default="scope">
                <span>{{ `$${scope.row.amount_fmt}` }}</span>
              </template>
            </el-table-column>
            <el-table-column label="" width="50">
              <template #default="scope">
                <el-dropdown trigger="click">
                  <EllipsisVerticalIcon
                    class="h-5 w-5 cursor-pointer text-black"
                    aria-hidden="true"
                  />
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item
                        @click="markPurchased(scope.row, false, true)"
                        >Unmark as purchased</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table></el-tab-pane
        >
      </el-tabs>
    </div>
    <el-dialog v-model="dialogVisible" title="Amazon Checkout" width="50%">
      <span>
        Nice gifts! We are about to redirect you to Amazon to complete your
        purchase. Once you click continue below, we will mark these gifts as
        purchased in Reggie. Here's a list of your selected gifts:
        <ul
          class="mt-4 max-h-[400px] divide-y divide-gray-200 overflow-y-scroll rounded-lg bg-white shadow"
        >
          <li v-for="gift in giftsToCheckout" :key="gift.gift_id" class="p-4">
            <div class="flex items-center gap-x-4">
              <img
                :src="giftImageUrl(gift)"
                class="h-10 w-10 object-contain object-center lg:h-16 lg:w-16"
              />
              <p class="w-64 truncate">{{ gift.name }}</p>
              <span>{{ `$${gift.amount_fmt}` }}</span>
            </div>
          </li>
        </ul>
      </span>
      <template #footer>
        <span class="space-x-2">
          <el-button
            @click="dialogVisible = false"
            type="primary"
            size="large"
            plain
            >Cancel</el-button
          >
          <el-button
            @click="createCartAmazon(), (dialogVisible = false)"
            type="primary"
            size="large"
            >Continue to Amazon</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import amazon from "@/mixins/amazon.js";
import { EllipsisVerticalIcon } from "@heroicons/vue/20/solid";

export default {
  mixins: [amazon],
  components: { EllipsisVerticalIcon },

  data() {
    return {
      gifts: [],
      selectedGifts: [],
      activeTab: "to-buy",
      dialogVisible: false,
      loading: true,
    };
  },
  mounted() {
    this.getData();
  },
  computed: {
    purchasedGifts() {
      return this.gifts.filter((gift) => gift.amazon_blob.purchased);
    },
    toBuyGifts() {
      return this.gifts.filter((gift) => !gift.amazon_blob.purchased);
    },
    giftsToCheckout() {
      return this.selectedGifts.length == 0
        ? this.toBuyGifts
        : this.selectedGifts;
    },
  },
  methods: {
    getData() {
      this.$axiosAuth
        .get(`host/gift/`)
        .then((res) => {
          this.gifts = res.data.filter((gift) => gift.amazon_blob);
        })
        .then((res) => {
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    handleSelectionChange(val) {
      this.selectedGifts = val;
    },
    markPurchased(gift, status, showNotification = true) {
      gift.amazon_blob.purchased = status;

      this.$axiosAuth
        .patch(`host/gift/${gift.gift_id}/`, {
          amazon_blob: gift.amazon_blob,
        })
        .then((res) => {
          if (showNotification) {
            ElNotification({
              title: "Success",
              message: gift.amazon_blob.purchased
                ? `Gift marked as purchased`
                : `Gift marked as not purchased`,
              type: "success",
            });
          }
          this.getData();
        })
        .catch((err) => {
          ElNotification({
            title: "Error",
            message: "Something went wrong, contact us for help",
            type: "error",
          });
        });
    },
    createCartAmazon() {
      let productASINs = [];
      let array = [];
      this.giftsToCheckout.forEach((gift, index) => {
        productASINs.push(`ASIN.${index}=${gift.amazon_blob.asin}`);
        this.markPurchased(gift, true, false);
      });
      let productsString = productASINs.join("&");
      let amazonLocale = this.amazonLocale[this.gifts[0].country_code];
      let baseUrl = `${amazonLocale.domain}/gp/aws/cart/add.html?${productsString}&tag=${amazonLocale.associateTag}`;
      window.open(baseUrl, "_blank");
    },
  },
};
</script>

<style>
/* hide select all checkbox */
.amazon-table {
  .el-table {
    @apply w-full overflow-y-scroll lg:h-[65vh] !important;
  }
  .el-table__header {
    .el-table-column--selection {
      .el-checkbox {
        display: none;
      }
    }
  }
  .el-tooltip__trigger:focus {
    outline: none !important;
  }
}
</style>