<template>
  <div
    v-loading.fullscreen.lock="loading"
    element-loading-background="#ffffff"
  ></div>
  <AdminLayout v-if="!loading">
    <div
      v-if="balance && countryCode"
      class="bg-white px-4 py-6 sm:px-6 lg:mx-auto lg:border lg:border-gray-200 lg:px-40"
    >
      <div class="w-full md:flex md:items-center md:justify-between">
        <div>
          <div>
            <div class="flex items-center">
              <div>
                <h1
                  class="text-2xl font-bold leading-7 text-black sm:truncate sm:leading-9"
                >
                  {{
                    `${
                      Object.keys(balance).length > 0
                        ? formatCurrency(
                            balance.available[0].amount_fmt,
                            countryCode,
                            currency
                          )
                        : formatCurrency(0, countryCode, currency)
                    } available for payout`
                  }}
                </h1>
                <p class="flex items-center text-red-700">
                  {{
                    `${
                      Object.keys(balance).length > 0
                        ? formatCurrency(
                            balance.pending[0].amount_fmt,
                            countryCode,
                            currency
                          )
                        : formatCurrency(0, countryCode, currency)
                    } will be available soon.`
                  }}
                  <Tooltip
                    :size="4"
                    value="Funds are available for payout 2 days after contribution."
                  />
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-6 flex gap-2 md:mt-0">
          <el-tooltip
            class="box-item"
            effect="dark"
            :content="
              eventFinished
                ? `You will be able to payout balances under ${formatCurrency(
                    100,
                    countryCode,
                    currency
                  )} three days after your event has finished.`
                : `You can only request a payout when the available balance is at least ${formatCurrency(
                    100,
                    countryCode,
                    currency
                  )}`
            "
            placement="top-start"
            :disabled="payoutCondition"
          >
            <el-button
              v-if="Object.keys(balance).length > 0"
              :disabled="!payoutCondition"
              @click="payoutFunds()"
              type="primary"
              size="large"
              round
              plain
            >
              <span class="text-lg">Payout available funds</span>
            </el-button>
          </el-tooltip>
        </div>
      </div>
    </div>
    <div class="mx-auto max-w-7xl px-4 pt-8 sm:px-6 lg:px-8">
      <div class="space-y-8">
        <div class="space-y-4">
          <h2 class="text-lg font-bold">All payouts</h2>
          <PayoutsTable
            v-if="payouts"
            :payouts="payouts"
            :countryCode="countryCode"
            :currency="currency"
          />
        </div>
      </div>
    </div>
  </AdminLayout>
</template>

<script>
import AdminLayout from "@/layouts/Admin.vue";
import ContributionsCards from "@/components/admin/ContributionsCards.vue";
import PayoutsTable from "@/components/admin/PayoutsTable.vue";

export default {
  components: {
    AdminLayout,
    ContributionsCards,
    PayoutsTable,
  },
  data() {
    return {
      loading: false,
      balance: null,
      payouts: null,
      countryCode: null,
      eventDatetime: null,
    };
  },
  mounted() {
    this.loading = true;
    this.getData();
  },
  computed: {
    payoutCondition() {
      const threeDaysAgo = new Date();
      threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);
      const eventDate = new Date(this.eventDatetime);
      return (
        this.balance.available[0].amount > 100 ||
        (this.balance.available[0].amount > 0 && eventDate < threeDaysAgo)
      );
    },
    eventFinished() {
      const now = new Date();
      const eventDate = new Date(this.eventDatetime);
      return eventDate < now;
    },
  },
  methods: {
    getData() {
      Promise.all([
        this.$axiosAuth.get(`host/stripe/balance`),
        this.$axiosAuth.get(`host/stripe/payout`),
      ])
        .then((res) => {
          this.balance = res[0].data;
          this.countryCode = res[1].data.country_code;
          this.currency = res[1].data.currency;
          this.payouts = res[1].data.data;
          this.eventDatetime = res[1].data.event_datetime;
        })
        .then((res) => {
          this.loading = false;
        })
        .catch((err) => {
          this.balance = {};
          this.loading = false;
        });
    },
    payoutFunds() {
      this.$axiosAuth
        .post("host/stripe/payout")
        .then((res) => {
          ElNotification({
            title: "Success",
            message: "Money is on it's way to your bank account",
            type: "success",
          });
          this.getData();
        })
        .catch((err) => {
          ElNotification({
            title: "Error",
            message: "Something went wrong",
            type: "error",
          });
        });
    },
  },
};
</script>
