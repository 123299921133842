<template>
  <div>
    <el-radio-group v-model="event.properties.layout" size="large">
      <el-radio-button
        class="capitalize"
        v-for="layout in layouts"
        :key="layout.title"
        :label="layout.title"
      />
    </el-radio-group>
  </div>
</template>

<script>
export default {
  props: ["event"],
  data() {
    return {
      layouts: [
        { title: "center" },
        { title: "split" },
        { title: "split 2" },
        { title: "full" },
      ],
    };
  },
};
</script>
