<template>
  <div class="overflow-hidden rounded-lg bg-white shadow">
    <div class="flex items-center">
      <div class="w-0 flex-1 p-4">
        <dl>
          <dt class="truncate text-sm font-medium text-gray-500">
            {{ label }}
            <el-tooltip
              v-if="tooltip"
              effect="dark"
              :content="tooltip"
              placement="top"
            >
              <el-icon style="margin-left: 4px" :size="12">
                <InfoFilled />
              </el-icon>
            </el-tooltip>
          </dt>

          <dd>
            <div class="text-lg font-medium text-gray-900">
              {{ value }}
            </div>
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import { InfoFilled } from "@element-plus/icons-vue";

export default {
  props: ["label", "value", "tooltip"],
  components: {
    InfoFilled,
  },
};
</script>

