<template>
  <div
    v-loading.fullscreen.lock="loading"
    element-loading-background="#ffffff"
  ></div>
  <AdminLayout v-if="!loading">
    <div
      class="mx-auto flex max-w-7xl flex-col gap-y-10 px-4 pt-8 sm:px-6 lg:px-8"
    >
      <card>
        <template #heading> All Time </template>
        <dl
          class="mx-auto grid grid-cols-1 gap-px bg-gray-900/5 lg:grid-cols-3"
        >
          <div
            v-for="stat in stats"
            :key="stat.name"
            class="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 sm:px-6 xl:px-8"
          >
            <dt class="text-sm/6 font-medium text-gray-500">
              {{ stat.name }}
            </dt>
            <dd
              class="w-full flex-none text-3xl/10 font-medium tracking-tight text-gray-900"
            >
              {{ stat.value }}
            </dd>
          </div>
        </dl>
      </card>
      <card>
        <template #heading> Upcoming Weddings (Unpaid) </template>
        <div class="grid grid-cols-3 gap-x-10">
          <div>
            <FutureOneYear />
          </div>
          <div>
            <FutureTwoYear />
          </div>
          <div>
            <FutureMore />
          </div>
        </div>
      </card>
    </div>
  </AdminLayout>
</template>

<script>
import AdminLayout from "@/layouts/Admin.vue";

export default {
  components: {
    AdminLayout,
  },
  computed: {
    paidEvents() {
      return this.events.filter((event) => event.is_paid);
    },
    stats() {
      return [
        {
          name: "Events Created",
          value: this.events.length,
        },
        {
          name: "Events Paid",
          value: this.paidEvents.length,
        },
        {
          name: "Conversion Rate",
          value:
            Math.round((this.paidEvents.length / this.events.length) * 100) +
            "%",
        },
      ];
    },
  },
  data() {
    return {
      events: [],
      loading: true,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.$axiosAuth
        .get("kiosk/events/")
        .then((res) => {
          this.events = res.data;
          this.loading = false;
        })
        .catch((err) => {
          ElNotification({
            title: "Error",
            message: "Error getting data",
            type: "error",
          });
        });
    },
  },
};
</script>


