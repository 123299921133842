<template>
  <vue-gravatar
    ref="gravatar"
    @error="hideGravatar()"
    @load="hideAvatar()"
    class="h-12 w-12 rounded-full object-cover"
    :email="contribution.email"
    :size="150"
    default-image="404"
  />
  <vue-avatar
    class="min-w-[50px]"
    ref="avatar"
    :size="50"
    :username="contribution.name"
  />
</template>

<script>
import VueAvatar from "@webzlodimir/vue-avatar";
import "@webzlodimir/vue-avatar/dist/style.css";
export default {
  props: ["contribution"],
  components: { VueAvatar },
  methods: {
    hideGravatar() {
      this.$refs.gravatar.$el.classList.value = "hidden";
    },
    hideAvatar() {
      this.$refs.avatar.$el.classList.value = "hidden";
    },
  },
};
</script>

<style></style>
