<template>
  <div class="border-b border-gray-200 bg-white px-8 py-6 2xl:px-24">
    <div class="">
      <div class="lg:grid lg:grid-cols-12 lg:gap-4">
        <div class="lg:col-span-4">
          <h2
            class="heading text-xl font-bold tracking-tight text-gray-900"
            :style="
              event.properties.fontAll
                ? {
                    fontFamily: event.properties.font,
                  }
                : null
            "
          >
            {{ event.properties.customFieldsTitle }}
          </h2>
        </div>
        <div class="lg:col-span-8">
          <dl class="grid grid-cols-2 gap-x-3 gap-y-4">
            <div
              v-for="(field, index) in event.custom_user_fields"
              :key="index"
              class="cols-span-2"
            >
              <div class="flex items-center gap-x-2">
                <img
                  v-if="field.icon"
                  :src="`/icons/${field.icon}.svg`"
                  class="h-auto w-6 object-cover object-center"
                />

                <dt class="heading text-sm font-semibold text-gray-900">
                  {{ field.title }}
                </dt>
              </div>
              <dd class="text mt-1 text-[10px] text-gray-600">
                {{ field.description }}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["event"],
};
</script>
