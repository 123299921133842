<template>
  <div class="overflow-hidden rounded-lg bg-white shadow">
    <h2 class="sr-only" id="profile-overview-title">Profile Overview</h2>
    <div class="bg-white p-6">
      <div class="sm:flex sm:items-center sm:justify-between">
        <div class="sm:flex sm:space-x-5">
          <div class="shrink-0">
            <div class="flex items-center gap-x-2">
              {{ event.is_live ? "🟢" : "🔴" }}

              <img
                class="mx-auto h-20 w-20 rounded-full"
                :src="event[event.properties.img_source]"
                alt=""
              />
            </div>
          </div>
          <div class="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
            <p class="text-xl font-bold text-gray-900 sm:text-2xl">
              {{ `${event.name_host1} & ${event.name_host2}` }}
            </p>
            <a
              :href="`https://lovereggie.com/${event.domain_name}`"
              class="text-sm font-medium text-gray-600"
              >/{{ event.domain_name }}</a
            >
          </div>
        </div>
        <div>
          <el-button @click="$emit('open-dialog')" type="primary"
            >Preview Registry</el-button
          >
          <el-button
            tag="a"
            target="_blank"
            :href="`https://dashboard.stripe.com/connect/accounts/${event.stripe_account}/`"
            >Stripe Connect</el-button
          >
        </div>
      </div>
    </div>
    <!-- <div
      class="grid grid-cols-1 divide-y divide-gray-200 border-t border-gray-200 bg-gray-50 sm:grid-cols-3 sm:divide-x sm:divide-y-0"
    >
      <div
        v-for="stat in stats"
        :key="stat.label"
        class="px-6 py-5 text-center text-sm font-medium"
      >
        <span class="text-gray-900">{{ stat.value }}</span>
        {{ " " }}
        <span class="text-gray-600">{{ stat.label }}</span>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  props: ["event"],
};
</script>

<style>
</style>