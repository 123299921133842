<template>
  <AdminLayout>
    <div class="mx-auto max-w-7xl px-4 py-8 sm:px-6 lg:px-8">
      <AdminGifts />
    </div>
  </AdminLayout>
</template>

<script>
import AdminLayout from "@/layouts/Admin.vue";

export default {
  components: {
    AdminLayout,
  },
};
</script>
