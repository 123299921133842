<template>
  <div
    v-if="event"
    class="relative overflow-y-hidden"
    :style="{ background: event.properties.colors.background }"
  >
    <main class="grid place-items-center">
      <div
        :class="[
          dashboardPreview
            ? 'max-h-fit py-8 pl-6'
            : 'min-h-[90vh] px-6 pb-16 pt-12 lg:min-h-[100vh] lg:px-12 ',
        ]"
        class="w-full text-center lg:max-w-3xl"
      >
        <div
          :data-aos="event.properties.animate ? 'fade-in' : null"
          data-aos-once="true"
          data-aos-duration="1000"
          class="relative mx-auto"
          :class="dashboardPreview ? 'w-1/4' : 'w-3/4'"
        >
          <h2
            class="absolute left-1/2 z-10 -translate-x-1/2 -translate-y-1/2 transform"
            :class="dashboardPreview ? 'text-3xl' : 'text-5xl lg:text-7xl'"
            :style="{
              color: event.properties.colors.heading,
              fontFamily: event.properties.font,
            }"
          >
            {{ event.name_host1 }}
          </h2>
          <div
            :style="{
              backgroundImage:
                'url(' +
                `${
                  event.imgPreview && event.properties.img_source == 'img'
                    ? event.imgPreview
                    : event[event.properties.img_source]
                }` +
                ')',
              clipPath:
                event.properties.clipPath != 'inset(5%)'
                  ? event.properties.clipPath
                  : null,
            }"
            :class="[dashboardPreview ? 'rounded-tr-lg' : '']"
            class="aspect-square bg-gray-400 bg-cover bg-center bg-no-repeat"
          ></div>
          <h2
            class="absolute left-1/2 z-10 -translate-x-1/2 -translate-y-1/2 transform"
            :class="dashboardPreview ? 'text-3xl' : 'text-5xl lg:text-7xl'"
            :style="{
              color: event.properties.colors.heading,
              fontFamily: event.properties.font,
            }"
          >
            {{ event.name_host2 }}
          </h2>
        </div>
        <div
          :class="[dashboardPreview ? ' mt-6 space-y-2' : 'mt-16 space-y-6']"
        >
          <h3
            :data-aos="event.properties.animate ? 'fade-in' : null"
            data-aos-once="true"
            data-aos-duration="1000"
            data-aos-delay="300"
            v-if="event.description"
            :class="[
              dashboardPreview
                ? 'mx-auto max-w-[250px] text-[10px]'
                : 'text-xl lg:text-2xl',
            ]"
            :style="{ color: event.properties.colors.paragraph }"
          >
            {{ event.description }}
          </h3>
          <h4
            :data-aos="event.properties.animate ? 'fade-in' : null"
            data-aos-once="true"
            data-aos-duration="1000"
            data-aos-delay="600"
            v-if="event.start_datetime && event.properties.show_date"
            :class="[
              dashboardPreview ? 'mx-auto  text-xs' : ' text-xl lg:text-2xl',
            ]"
            :style="{ color: event.properties.colors.paragraph }"
          >
            {{ formatDate(event.start_datetime) }}
          </h4>
          <div
            :data-aos="event.properties.animate ? 'fade-in' : null"
            data-aos-once="true"
            data-aos-duration="1000"
            data-aos-delay="900"
          >
            <a
              v-if="event.hashtag"
              :href="`https://hashtag.com/${event.hashtag}/`"
              target="_blank"
              class="block font-semibold"
              :class="[
                dashboardPreview ? ' text-xs' : ' block text-2xl lg:text-3xl',
              ]"
              :style="{ color: event.properties.colors.paragraph }"
            >
              {{ `#${event.hashtag}` }}
            </a>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import Details from "@/layouts/event/snippets/Details.vue";

export default {
  components: { Details },
  props: ["event", "dashboardPreview"],
};
</script>
