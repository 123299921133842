<template>
  <div :class="dashboardPreview ? '2xl:px-16' : null">
    <div class="bg-white px-8 py-5">
      <div class="flex items-start gap-x-4">
        <h2
          class="heading mb-3 text-xl font-bold tracking-tight text-gray-900"
          :style="
            !registration && event.properties.fontAll
              ? {
                  fontFamily: event.properties.font,
                }
              : null
          "
        >
          Gifts
        </h2>
      </div>

      <ul
        role="list"
        class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-6"
      >
        <li
          v-show="gifts"
          v-for="gift in gifts"
          :key="gift.gift_id"
          class="relative"
        >
          <div
            class="group block aspect-square w-full overflow-hidden rounded-lg bg-gray-100 drop-shadow-sm transition-opacity focus-within:ring-2 focus-within:ring-primary focus-within:ring-offset-2 focus-within:ring-offset-gray-100 hover:opacity-75"
          >
            <div
              :style="{
                backgroundImage: 'url(' + giftImageUrl(gift) + ')',
              }"
              class="pointer-events-none flex h-full w-full items-center justify-center bg-cover bg-center object-scale-down"
            ></div>
          </div>
          <p
            class="pointer-events-none mt-2 block truncate text-[.8rem] font-medium text-gray-900"
          >
            {{ gift.name }}
          </p>
          <p
            v-if="gift.type != 'unlimited'"
            class="text-[.65rem] text-gray-900"
          >
            {{
              gift.total_contributions
                ? `${formatCurrency(
                    gift.total_contributions_fmt,
                    gift.country_code,
                    gift.currency
                  )}`
                : `${formatCurrency(0, gift.country_code, gift.currency)}`
            }}
            <span class="mt-6 text-[.5rem] text-gray-500">
              {{
                `of ${formatCurrency(
                  gift.amount_fmt,
                  gift.country_code,
                  gift.currency
                )} funded`
              }}
            </span>
          </p>
          <p v-else class="text-[.65rem] text-gray-900">Contribute</p>
        </li>
        <div
          v-show="gifts.length == 0"
          class="relative block w-full rounded-lg border-2 border-dashed border-gray-300 px-2 py-6 text-center focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="{1.5}"
            stroke="currentColor"
            class="mx-auto h-12 w-12 text-red-400"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21 11.25v8.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 109.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1114.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z"
            />
          </svg>

          <span class="mt-2 block text-xs font-medium text-gray-900"
            >Add your first gift</span
          >
        </div>
      </ul>
      <div v-if="extraMessage" class="mt-2">
        <el-alert
          :title="extraMessage"
          :type="info"
          show-icon
          :closable="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "event",
    "registration",
    "countryCode",
    "dashboardPreview",
    "giftTemplates",
  ],
  computed: {
    gifts() {
      var gifts = [];
      if (this.event) {
        let originalGiftOrder = this.event.gift_order;
        if (this.event.gifts) {
          this.event.gifts.forEach((gift) => {
            let index = originalGiftOrder.indexOf(gift.gift_id);
            if (index !== -1)
              gifts[originalGiftOrder.indexOf(gift.gift_id)] = gift;
            else gifts.push(gift);
          }); //if there is a gift in the database that somehow is not in the gift_order array, then indexOf() will return -1 and the gift will be placed at the end of the array (i.e. this.gifts[-1]) this is good forces the gift_order and actual gifts to be synchronized
        } else {
          gifts = [];
        }
        gifts = [...gifts].splice(0, 4);
      } else if (this.giftTemplates && this.giftTemplates.length > 0) {
        this.giftTemplates.forEach((gift) => {
          gifts.push({
            id: gift.template_id,
            name: gift.brand ? `${gift.brand} ${gift.name}` : gift.name,
            type: gift.type,
            amount_fmt: gift.amount_fmt,
            total_contributions: gift.amount * 0.75,
            total_contributions_fmt: Math.floor(gift.amount_fmt * 0.75),
            img_url_stock: gift.img_url_stock,
            img_url: gift.img,
            country_code: this.countryCode,
            currency: gift.converted_currency,
            properties: gift.properties,
          });
          gifts = [...gifts].splice(0, 4);
          if (this.giftTemplates.length > 4) {
            this.extraMessage = `+ ${
              this.giftTemplates.length - 4
            } more gifts (these will show on your actual registry).`;
          }
        });
      } else {
        gifts = [
          {
            id: 1,
            name: "Moccamaster",
            amount_fmt: "450",
            total_contributions: "100",
            total_contributions_fmt: "100",
            img_url:
              "https://cdn.lovereggie.com/media/static-site-media/imp_1_491539.jpg",
            country_code: this.countryCode,
            currency: "aud",
            properties: {
              img_source: "img_url",
            },
          },
          {
            id: 2,
            name: "Honeymoon Fund",
            amount_fmt: "3000",
            total_contributions: "1300",
            total_contributions_fmt: "1300",
            img_url:
              "https://cdn.lovereggie.com/media/static-site-media/italy_honeymoon.jpg",
            country_code: this.countryCode,
            currency: "aud",
            properties: {
              img_source: "img_url",
            },
          },
          {
            id: 3,
            name: "Le Creuset Dish",
            amount_fmt: "300",
            total_contributions: "500",
            total_contributions_fmt: "500",
            img_url:
              "https://cdn.lovereggie.com/media/static-site-media/le-creuset.jpg",
            country_code: this.countryCode,
            currency: "aud",
            properties: {
              img_source: "img_url",
            },
          },
          {
            id: 1,
            name: "Linen Sheets",
            amount_fmt: "450",
            total_contributions: "100",
            total_contributions_fmt: "100",
            img_url:
              "https://cdn.lovereggie.com/media/static-site-media/olive-mineral-sage.webp",
            country_code: this.countryCode,
            currency: "aud",
            properties: {
              img_source: "img_url",
            },
          },
        ];
        this.extraMessage =
          "These are demo gifts. You will be able to add your own later.";
      }
      return gifts;
    },
  },
  data() {
    return {
      extraMessage: "",
    };
  },
};
</script>
