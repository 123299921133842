<template>
  <el-dropdown ref="dropdown" trigger="click">
    <el-button type="primary">
      Add New Gift<el-icon class="el-icon--right"><arrow-down /></el-icon>
    </el-button>
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item
          @click="(modal.show = true), (modal.type = 'template')"
          >Search for a gift template</el-dropdown-item
        >
        <el-dropdown-item
          v-if="event && event.country_code in amazonLocale"
          divided
          @click="(modal.show = true), (modal.type = 'amazon')"
          >Add a gift from Amazon</el-dropdown-item
        >
        <el-dropdown-item divided @click="$emit('add-gift', null)"
          >Add a custom gift</el-dropdown-item
        >
      </el-dropdown-menu>
    </template>
  </el-dropdown>
  <el-dialog
    v-model="modal.show"
    :title="
      modal.type === 'template' ? 'Gift Template Search' : 'Amazon Search'
    "
    width="800"
    :fullscreen="userMobile"
    @close="handleClose"
  >
    <div
      v-if="modal.type === 'template' && !event.properties.closeGiftAlert"
      class="mb-4"
    >
      <el-alert
        title="💰 Cash Registry Reminder 💰"
        type="info"
        @close="patchEvent()"
        >We deliver the cash to your bank account, not the gift to your
        doorstep.
      </el-alert>
    </div>

    <GiftTemplateComponent
      v-if="modal.type === 'template'"
      @add-gift="(gift) => ($emit('add-gift', gift), (modal.show = false))"
      @close-modal="modal.show = false"
      :countryCode="countryCode"
      endpoint="host"
    />
    <AmazonComponent
      v-else
      @add-gift="(gift) => ($emit('add-gift', gift), (modal.show = false))"
      @close-modal="modal.show = false"
      @multi-gift-add="$emit('multi-gift-add'), (modal.show = false)"
      :countryCode="countryCode"
      endpoint="host"
    />
  </el-dialog>
</template>

<script>
import GiftTemplateComponent from "@/components/admin/GiftTemplateComponent.vue";
import AmazonComponent from "@/components/admin/AmazonComponent.vue";
import { ArrowDown } from "@element-plus/icons-vue";
import amazon from "@/mixins/amazon.js";

export default {
  mixins: [amazon],
  data() {
    return {
      modal: {
        show: false,
        type: null,
      },
    };
  },
  components: {
    GiftTemplateComponent,
    AmazonComponent,
    ArrowDown,
  },
  props: ["countryCode", "openDropdownProp", "event"],
  emits: ["addGift", "dropdown-closed", "multi-gift-add"],
  methods: {
    handleClose() {
      this.$emit("modal-closed");
    },
    patchEvent() {
      this.event.properties.closeGiftAlert = true;
      this.$axiosAuth.patch(`host/event/${this.event.event_id}/`, {
        properties: this.event.properties,
      });
    },
  },
  watch: {
    openDropdownProp: {
      handler(val) {
        //open the dropdown
        if (val) {
          this.$refs.dropdown.handleOpen();
          this.$emit("dropdown-closed");
        }
      },
    },
  },
};
</script>

<style></style>
