<template>
  <div>
    <ul
      v-if="contributions.length > 0"
      role="list"
      class="divide-y divide-gray-200 rounded-lg bg-white shadow"
    >
      <li
        v-for="contribution in dashboard
          ? contributions.slice(0, 5)
          : contributions"
        :key="contribution.id"
        class="py-4 px-4"
      >
        <div class="flex justify-between gap-3 lg:gap-0">
          <div class="flex items-center gap-3">
            <Avatar :contribution="contribution" />
            <div>
              <h3 class="text-sm font-medium">
                {{ contribution.name }} contributed
                {{
                  formatCurrency(
                    contribution.amount_fmt,
                    event.country_code,
                    event.currency
                  )
                }}
                to
                {{ contribution.gift_name }}
              </h3>
              <p class="max-w-sm text-sm text-gray-500">
                {{ trimMessage(contribution.message) }}
              </p>
            </div>
          </div>
          <p class="self-start text-sm text-gray-500">
            {{ daysTill(contribution.created) }}
          </p>
        </div>
      </li>
    </ul>
    <div
      v-else
      class="rounded-lg bg-white p-4 shadow"
    >
      No contributions yet
    </div>
  </div>
</template>

<script>
import Avatar from "@/components/admin/Avatar.vue";

export default {
  components: {
    Avatar,
  },
  props: ["event", "contributions", "dashboard"],
  methods: {
    trimMessage(str) {
      let trimLength = 110;
      var hasTouchScreen = false;

      if ("maxTouchPoints" in navigator) {
        hasTouchScreen = navigator.maxTouchPoints > 0;
      }

      if (hasTouchScreen) {
        trimLength = 70;
      }
      if (str.length <= trimLength || !this.dashboard) {
        return str;
      }
      // Return str truncated with '...' concatenated to the end of str.
      return str.slice(0, trimLength) + "...";
    },
  },
};
</script>
