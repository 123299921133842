<template>
  <main class="min-h-screen bg-cover bg-center">
    <div
      class="flex min-h-screen flex-col items-center justify-center bg-black/70"
    >
      <div
        class="mx-auto max-w-7xl px-4 py-16 text-center sm:px-6 sm:py-24 lg:px-8 lg:py-48"
      >
        <h1
          class="mt-2 text-4xl font-bold tracking-tight text-red-400 sm:text-5xl"
        >
          Login Succesful!
        </h1>
        <p class="text-md mt-2 font-medium text-white md:text-xl">
          You can now get back to using our handy extension to fill your reggie
          with gifts!
        </p>
        <div class="mt-6">
          <el-button type="primary" size="large" @click="this.$router.push({ name: 'dashboard' })" plain>Home</el-button>
        </div>
      </div>
    </div>
  </main>
</template>
