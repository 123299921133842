<template>
  <!-- Card -->
  <div
    v-for="card in cards"
    :key="card.name"
    :class="[
      dashboard && card.hideDashboard ? 'hidden' : '',
      report && card.hideReport ? 'hidden' : '',
    ]"
    class="overflow-hidden rounded-lg bg-white shadow"
  >
    <div class="flex items-center justify-between p-4">
      <div
        class="flex w-full items-center justify-between text-sm font-medium text-gray-900 sm:block sm:text-gray-500"
      >
        <div>
          {{ card.name }}
          <Tooltip v-if="card.tooltip" :value="card.tooltip" />
        </div>
        <div class="flex items-center gap-x-2">
          <div class="text-sm font-medium text-gray-900 sm:text-lg">
            {{ card.amount }}
          </div>
          <div v-if="card.showButton" class="sm:hidden">
            <el-button
              @click="submit(card.endpoint)"
              type="primary"
              plain
              size="small"
              >Payout</el-button
            >
          </div>
        </div>
      </div>
      <div v-if="card.showButton" class="hidden sm:block">
        <el-button @click="submit(card.endpoint)" type="primary" plain
          >Payout</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import {
  Bars3CenterLeftIcon,
  BellIcon,
  ClockIcon,
  CogIcon,
  CreditCardIcon,
  DocumentChartBarIcon,
  HomeIcon,
  QuestionMarkCircleIcon,
  ScaleIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  XMarkIcon,
} from "@heroicons/vue/24/outline";

export default {
  components: {
    Bars3CenterLeftIcon,
    BellIcon,
    ClockIcon,
    CogIcon,
    CreditCardIcon,
    DocumentChartBarIcon,
    HomeIcon,
    QuestionMarkCircleIcon,
    ScaleIcon,
    ShieldCheckIcon,
    UserGroupIcon,
    XMarkIcon,
  },
  props: ["contributions", "payouts", "event", "dashboard", "report"],
  emits: ["refresh-data"],

  computed: {
    cards() {
      let cards = [
        {
          name: "Total Contributions",
          href: "#",
          icon: ScaleIcon,
          amount: `${this.formatCurrency(
            this.event.total_contributions_fmt,
            this.event.country_code,
            this.event.currency
          )}`,
        },
        {
          name: "Number of Contributions",
          href: "#",
          icon: ScaleIcon,
          amount: this.numberContributions,
        },
        {
          name: "Average Contribution",
          href: "#",
          icon: ScaleIcon,
          amount: `${
            this.averageContribution
              ? this.formatCurrency(
                  this.averageContribution,
                  this.event.country_code,
                  this.event.currency
                )
              : this.formatCurrency(
                  0,
                  this.event.country_code,
                  this.event.currency
                )
          } `,
          hideDashboard: true,
        },
        {
          name: "Largest Contribution",
          href: "#",
          icon: ScaleIcon,
          amount: `${this.formatCurrency(
            this.largestContribution,
            this.event.country_code,
            this.event.currency
          )}`,
          hideDashboard: true,
        },
        {
          name: "Gifts Funded",
          href: "#",
          icon: ScaleIcon,
          amount: this.giftsFunded,
        },
      ];
      if (this.payouts) {
        cards.unshift({
          name: "Available for payout",
          tooltip: `${
            this.eventFinished
              ? `Balances under ${this.formatCurrency(
                  100,
                  this.event.country_code,
                  this.event.currency
                )} can be paid out 3 days after your event finishes.`
              : `You can only request a payout when the available balance is at least ${this.formatCurrency(
                  100,
                  this.event.country_code,
                  this.event.currency
                )}`
          } `,
          href: "#",
          icon: ScaleIcon,
          amount:
            Object.keys(this.payouts).length > 0
              ? `${this.formatCurrency(
                  this.payouts.available[0].amount_fmt,
                  this.event.country_code,
                  this.event.currency
                )}`
              : `${this.formatCurrency(0, this.event.country_code)}`,
          hideReport: true,
          endpoint: "host/stripe/payout",
          showButton: this.payoutCondition,
        });
      }
      return cards;
    },
    payoutCondition() {
      const threeDaysAgo = new Date();
      threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);
      const eventDate = new Date(this.event.start_datetime);
      return (
        this.payouts.available[0].amount_fmt > 100 ||
        (this.payouts.available[0].amount_fmt > 0 && eventDate < threeDaysAgo)
      );
    },
    eventFinished() {
      const now = new Date();
      const eventDate = new Date(this.event.start_datetime);
      return eventDate < now;
    },
    numberContributions() {
      return this.contributions.length;
    },
    contributionNumbers() {
      if (this.contributions) {
        return this.contributions.map((contribution) => {
          return contribution.amount_fmt;
        });
      }
    },
    largestContribution() {
      if (this.contributions.length > 0) {
        return Math.max(...this.contributionNumbers);
      } else {
        return 0;
      }
    },
    averageContribution() {
      return Math.round(
        this.event.total_contributions_fmt / this.numberContributions
      );
    },
    giftsFunded() {
      let fundedGifts = this.event.gifts.filter((gift) => gift.is_funded);
      return `${fundedGifts.length}/${this.event.gifts.length}`;
    },
  },
  methods: {
    submit(endpoint) {
      this.$axiosAuth
        .post(endpoint)
        .then((res) => {
          ElNotification({
            title: "Success",
            message: "Money is on it's way to your bank account",
            type: "success",
          });
          setTimeout(() => {
            this.$emit("refresh-data");
          }, 1000);
        })
        .catch((err) => {
          ElNotification({
            title: "Error",
            message: "Something went wrong",
            type: "error",
          });
        });
    },
  },
};
</script>

<style></style>

<!-- <div class="flex-shrink-0">
                      <component
                        :is="card.icon"
                        class="h-6 w-6 text-gray-400"
                        aria-hidden="true"
                      />
                    </div> -->
