import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

export default {
  install: (app, options) => {

    dayjs.extend(relativeTime);
    app.config.globalProperties.$dayjs = dayjs;

  },
};
