<template>
  <PartnersLoginLayout>
    <div v-loading.fullscreen.lock="loading">
      <p class="mt-4 text-sm text-gray-600">
        Welcome to the Reggie partner program {{ stateDecoded.email }}! Please
        enter a password to setup your account.
      </p>
    </div>

    <div class="mt-4">
      <el-form
        @submit.prevent
        :model="passwordForm"
        :rules="passwordFormRules"
        :status-icon="true"
        label-position="top"
        ref="passwordFormRef"
      >
        <el-form-item
          v-if="!passwordSuccess"
          label="Password"
          :label-width="100"
          prop="password_1"
        >
          <el-input
            v-model="passwordForm.password_1"
            type="password"
            size="large"
            @keyup.enter="password_1(passwordFormRef)"
          />
        </el-form-item>
        <el-form-item
          v-if="!passwordSuccess"
          label="Retype Password"
          :label-width="100"
          prop="password_2"
        >
          <el-input
            v-model="passwordForm.password_2"
            type="password"
            size="large"
            @keyup.enter="password_2(passwordFormRef)"
          />
        </el-form-item>
        <el-button
          v-if="!passwordSuccess"
          @click="setPassword(passwordFormRef)"
          :disabled="!passwordForm.password_1 || !passwordForm.password_2"
          class="w-full"
          type="primary"
          size="large"
          plain
          >Set Password</el-button
        >
      </el-form>
    </div>
  </PartnersLoginLayout>
</template>

<script>
import PartnersLoginLayout from "@/layouts/partners/Login.vue";
import { ref, reactive } from "vue";
import { useUserStore } from "@/stores/UserStore";
import { mapActions } from "pinia";

export default {
  components: {
    PartnersLoginLayout,
  },

  data() {
    return {
      loading: true,
      user: {},
      stateDecoded: {},
      passwordForm: reactive({
        password_1: null,
        password_2: null,
      }),
      errors: reactive({
        password_1: null,
        password_2: null,
      }),
      passwordFormRules: reactive({
        password_1: [
          {
            required: true,
            message: "Please input a password",
            trigger: "blur",
          },
        ],
        password_2: [
          {
            required: true,
            message: "Please retype your password",
            trigger: "blur",
          },
        ],
      }),
    };
  },
  setup() {
    const passwordFormRef = ref(null);
    return { passwordFormRef };
  },
  beforeMount() {
    const { query } = this.$route;

    if (!query.state) {
      this.$router.push({ name: "partners-login" });
      return;
    } else {
      this.stateDecoded = JSON.parse(
        decodeURIComponent(
          atob(query.state.replace(/-/g, "+").replace(/_/g, "/"))
        )
      );
      if (
        this.stateDecoded &&
        this.stateDecoded.email &&
        this.stateDecoded.otp
      ) {
        this.login();
      }
      // state has email and otp now affiliate login in background
    }
  },
  methods: {
    ...mapActions(useUserStore, ["authenticateAffiliate"]),

    async setPassword(passwordFormRef) {
      this.loading = true;
      await passwordFormRef.validate((valid, fields) => {
        if (valid) {
          this.$axiosAuth
            .post("affiliates/password/change/", {
              old_password: this.stateDecoded.otp,
              new_password1: this.passwordForm.password_1,
              new_password2: this.passwordForm.password_2,
            })
            .then((response) => {
              this.authenticateAffiliate(true, this.user);

              ElNotification({
                message: "Affiliate account created successfully",
                type: "success",
              });
              this.$router.push({ name: "partners-dashboard" });
            })
            .catch((error) => {
              this.loading = false;
              ElNotification({
                title: "Error",
                message:
                  "Error setting up affiliate account. Please contact support",
                type: "error",
              });
            });
        }
      });
    },
    login() {
      this.$axiosAuth
        .post("affiliates/login/", {
          email: this.stateDecoded.email,
          password: this.stateDecoded.otp,
        })
        .then((response) => {
          this.loading = false;

          this.user.email = response.data.user.email;
          this.user.name = response.data.name;
          this.user.affiliateCode = response.data.affiliate_code;
        })
        .catch((error) => {
          ElNotification({
            title: "Error",
            message:
              "Error setting up affiliate account. Please contact support",
            type: "error",
          });
          this.$router.push({ name: "partners-login" });
          console.log(error);
        });
    },
  },
};
</script>
