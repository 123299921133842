<template>
  <div class="overflow-x-hidden bg-white 2xl:px-36">
    <div class="">
      <div class="lg:grid lg:grid-cols-12 lg:gap-8">
        <div class="lg:col-span-4">
          <h2
            :data-aos="event.properties.animate ? 'fade-in' : null"
            data-aos-once="true"
            data-aos-duration="1000"
            class="text-3xl leading-10 tracking-tight text-gray-900"
            :style="
              event.properties.fontAll
                ? {
                    fontFamily: event.properties.font,
                  }
                : null
            "
          >
            {{ event.properties.customFieldsTitle }}
          </h2>
          <!-- <p class="text mt-4 text-base leading-7 text-gray-600">The most important details of the special day!</p> -->
        </div>
        <div class="mt-6 lg:col-span-8 lg:mt-0">
          <dl
            class="grid grid-cols-1 gap-y-4 lg:grid-cols-2 lg:gap-x-16 lg:gap-y-12"
          >
            <div
              v-for="(field, index) in event.custom_user_fields"
              :key="index"
              class="cols-span-2"
            >
              <div class="flex items-center gap-x-2 lg:gap-x-3">
                <img
                  v-if="field.icon"
                  :data-aos="event.properties.animate ? 'fade-left' : null"
                  data-aos-once="true"
                  data-aos-duration="1000"
                  data-aos-delay="100"
                  :src="`/icons/${field.icon}.svg`"
                  class="h-auto w-8 object-cover object-center"
                />
                <dt
                  :data-aos="event.properties.animate ? 'fade-left' : null"
                  data-aos-once="true"
                  data-aos-duration="1000"
                  class="text-base font-semibold leading-7 text-gray-900 lg:text-xl"
                >
                  {{ field.title }}
                </dt>
              </div>
              <dd
                :data-aos="event.properties.animate ? 'fade-left' : null"
                data-aos-once="true"
                data-aos-duration="1000"
                class="text mt-1 text-base leading-7 text-gray-600 lg:mt-3 lg:text-lg"
              >
                {{ field.description }}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["event"],
};
</script>
