<template>
  <el-tooltip
    v-if="admin && !gift.draft"
    class="box-item"
    effect="dark"
    content="Remove gift from showcase"
    placement="bottom"
  >
    <el-button
      @click="$emit('pinGift', false)"
      type="warning"
      circle
      class="absolute right-2 top-1 z-[8]"
      ><el-icon><Star /></el-icon
    ></el-button>
  </el-tooltip>
  <div
    @click="$emit('openSlide')"
    class="group block aspect-video w-full overflow-hidden rounded-lg border border-gray-200 bg-white transition-opacity focus-within:ring-2 focus-within:ring-primary focus-within:ring-offset-2 focus-within:ring-offset-gray-100 hover:opacity-60"
    :class="[
      notFocus ? 'opacity-30' : 'opacity-100',
      gift.amazon_blob ? ' p-3' : '',
    ]"
  >
    <div
      v-lazy:background-image="
        gift.imgPreview && gift.properties.img_source == 'img'
          ? gift.imgPreview
          : giftImageUrl(gift)
      "
      class="pointer-events-none flex h-full w-full items-center justify-center bg-cover bg-center object-scale-down"
      :class="gift.amazon_blob ? ' bg-contain bg-no-repeat ' : 'bg-cover'"
    >
      <div
        v-if="gift.is_funded"
        class="flex h-full w-full items-center justify-center bg-black/50"
      >
        <p class="text-xl font-bold uppercase text-white">Funded</p>
      </div>
    </div>
  </div>
  <div
    class="flex flex-col justify-center space-y-0 md:space-y-3"
    :class="notFocus ? 'opacity-30' : 'opacity-100'"
  >
    <el-tag v-if="admin && gift.draft" type="primary" class="w-16" round
      >Draft</el-tag
    >
    <h3
      class="pointer-events-none mt-2 block truncate text-lg font-medium text-gray-900 md:mt-0 md:pr-16 md:text-4xl"
    >
      {{ gift.name }}
    </h3>

    <p class="hidden text-xl md:block">{{ gift.description }}</p>
    <p
      v-if="showPrices && gift.type != 'unlimited'"
      class="text-md text-gray-900 md:text-xl"
    >
      {{
        gift.total_contributions
          ? `${formatCurrency(
              gift.total_contributions_fmt,
              gift.country_code,
              gift.currency
            )}`
          : `${formatCurrency(0, gift.country_code, gift.currency)}`
      }}
      <span class="mt-6 text-xs text-gray-500 md:text-base">
        {{
          `of ${
            gift.amount_fmt
              ? `${formatCurrency(
                  gift.amount_fmt,
                  gift.country_code,
                  gift.currency
                )}`
              : `${formatCurrency(0, gift.country_code, gift.currency)}`
          } funded`
        }}
      </span>
      <img
        v-if="admin && gift.amazon_blob && !gift.draft"
        src="../../assets/img/amazon-logo.png"
        class="ml-4 inline-block w-12"
        alt=""
      />
    </p>
  </div>
</template>

<script>
import { Star } from "@element-plus/icons-vue";
import { Bars3Icon } from "@heroicons/vue/24/outline";

export default {
  components: { Star, Bars3Icon },
  props: ["gift", "showPrices", "admin", "notFocus"],
  emits: ["pinGift", "openSlide"],
};
</script>