<script setup>
import { onMounted, ref, useSlots } from "vue";

defineProps([
  "modelValue",
  "label",
  "placeholder",
  "type",
  "size",
  "iconPadding",
  "validation",
]);

defineEmits(["update:modelValue"]);

const input = ref(null);
const slots = useSlots();
</script>

<template>
  <div>
    <label
      v-if="label"
      :for="label"
      :class="[size == 'sm' ? 'text-sm ' : 'text-md ']"
      class="mb-1 block font-medium text-gray-700"
      >{{ label }}</label
    >
    <div>
      <div class="relative rounded-md shadow-sm">
        <div
          class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 text-lg text-gray-500"
        >
          <slot
            v-if="slots.icon"
            name="icon"
          >
          </slot>
        </div>
        <input
          :type="type ? type : 'text'"
          :id="label"
          :placeholder="placeholder"
          :class="[
            slots.icon && iconPadding ? `pl-${iconPadding}` : '',
            slots.icon && !iconPadding ? 'pl-9' : '',
            size == 'sm' ? 'h-10 text-sm' : 'h-12 text-xl',
          ]"
          class="placeholder:text-md block w-full rounded-md border border-gray-300 px-4 placeholder:text-slate-400 focus:border-primary focus:ring-primary"
          :value="modelValue"
          @input="$emit('update:modelValue', $event.target.value)"
          ref="input"
        />
      </div>
    </div>
    <ul class="mt-2 text-red-500">
      <li
        v-for="error in validation"
        :key="error"
        :class="[size == 'sm' ? 'text-sm ' : 'text-md ']"
        class="space-y-1"
      >
        {{ error }}
      </li>
    </ul>
  </div>
</template>
