<template>
  <InformationCircleIcon
    v-if="html"
    v-tooltip.right-start="{ content: value, html: true }"
    class="inline text-gray-700"
    :class="sizeClass"
    aria-hidden="true"
  />
  <InformationCircleIcon
    v-else
    v-tooltip="value"
    class="inline text-gray-700"
    :class="sizeClass"
    aria-hidden="true"
  />
</template>

<script>
import { InformationCircleIcon } from "@heroicons/vue/24/outline";
export default {
  components: { InformationCircleIcon },
  props: {
    value: {
      default: false,
      type: String,
    },
    size: {
      default: 5,
      type: Number,
    },
    html: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    sizeClass() {
      return `h-${this.size} w-${this.size}`;
    },
  },
};
</script>
