import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import * as Sentry from "@sentry/vue";
import axios from "./plugins/axios";
import dayjs from "./plugins/dayjs";
import VueGravatar from "vue3-gravatar";
import { vfmPlugin } from "vue-final-modal";
import FloatingVue from "floating-vue";
import VueConfetti from "vue-confetti";
import Hotjar from "@hotjar/browser";
import VueCookies from "vue-cookies";

import AOS from "aos";
import "aos/dist/aos.css";

import helpers from "./mixins/helpers";
import countries from "./mixins/countries";
import VueLazyload from "vue-lazyload";

// css imports
import "./assets/main.css";
import "floating-vue/dist/style.css";

// element plus to so tailwind doesn't override styles
import element from "./plugins/element";

// ui components
import Input from "./components/ui/Input.vue";
import TextArea from "./components/ui/TextArea.vue";
import Tooltip from "./components/ui/Tooltip.vue";
import StatCard from "./components/admin/StatCard.vue";

// pinia
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

// create vue app
const app = createApp(App);

// sentry only in production
if (import.meta.env.PROD) {
  Sentry.init({
    app,
    dsn: "https://e49759a7857e95332f92e2c939c96d43@o4506404885430272.ingest.sentry.io/4506404887527424",
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

// hotjar
const siteId = 3851862;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

//add components properties and plugins
app.component("TextArea", TextArea);
app.component("Input", Input);
app.component("Tooltip", Tooltip);
app.component("StatCard", StatCard);

app.use(router);
app.use(pinia);
app.use(axios);
app.use(dayjs);
app.use(element);
app.use(VueGravatar);
app.use(vfmPlugin);
app.use(FloatingVue, {
  themes: {
    tooltip: {
      triggers: ["hover", "focus", "touch", "click"],
    },
  },
});
app.use(VueConfetti);
app.use(VueCookies, { expires: "30d" });

app.mixin({
  updated() {
    this.$nextTick(function () {
      AOS.refreshHard(); // This is needed to avoid the un-animate aos effect
    });
  },
});

class AosPlugin {
  config = {
    disable: function () {
      return ["/", "/register", "/registry"].includes(window.location.pathname);
    },
  };

  install(Vue) {
    AOS.init(this.config);

    Vue.mixin({
      updated() {
        this.$nextTick(function () {
          AOS.refreshHard(); // This is needed to avoid the un-animate aos effect
        });
      },
    });
  }
}

app.use(new AosPlugin());

app.use(VueLazyload);

app.mixin(helpers);
app.mixin(countries);

//mount app
app.mount("#app");
