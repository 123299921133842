<template>
  <div class="min-h-full">
    <div class="flex flex-1 flex-col">
      <main class="flex-1">
        <div>
          <!-- Activity list (smallest breakpoint only) -->
          <div class="shadow md:hidden">
            <GiftCards v-if="gifts" :gifts="descendingGifts" />
          </div>
          <!-- Activity table (small breakpoint and up) -->
          <div class="hidden md:block">
            <div>
              <div class="flex flex-col">
                <div
                  class="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg"
                >
                  <table class="min-w-full divide-y divide-gray-200 bg-white">
                    <thead>
                      <tr>
                        <th
                          class="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                          scope="col"
                        >
                          Gift
                        </th>
                        <th
                          class="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                          scope="col"
                        >
                          Funded
                        </th>
                        <th
                          class="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                          scope="col"
                        >
                          Contributions
                        </th>
                      </tr>
                    </thead>
                    <tbody
                      v-if="descendingGifts.length > 0"
                      class="divide-y divide-gray-200 bg-white"
                    >
                      <tr
                        v-for="gift in descendingGifts"
                        :key="gift.id"
                        class="bg-white"
                      >
                        <td class="px-6 py-4 text-sm text-gray-900">
                          <div class="flex items-center gap-x-4">
                            <img
                              :src="giftImageUrl(gift)"
                              class="h-16 w-16 rounded-full object-cover object-center"
                            />
                            <p>
                              {{ gift.name }}
                            </p>
                          </div>
                        </td>
                        <td class="px-6 py-4 text-sm text-gray-500">
                          <span class="text-md text-gray-900">
                            {{ `$${gift.total_contributions_fmt}` }}
                            <span
                              v-if="gift.type != 'unlimited'"
                              class="mt-6 text-sm text-gray-500"
                            >
                              {{ `of $${gift.amount_fmt} funded` }}
                            </span>
                          </span>
                          <span
                            v-if="gift.type != 'unlimited'"
                            class="text-md text-gray-900"
                          >
                            ({{
                              Math.round(
                                (gift.total_contributions_fmt /
                                  gift.amount_fmt) *
                                  100
                              )
                            }}%)
                          </span>
                        </td>
                        <td class="max-w-md px-6 py-4 text-sm text-gray-900">
                          <div v-if="!gift.contributions.showAll">
                            <p>
                              {{
                                pluralize(
                                  filteredContributions(gift.contributions)
                                    .length,
                                  "Contribution"
                                )
                              }}
                            </p>
                            <el-button
                              v-if="
                                filteredContributions(gift.contributions)
                                  .length > 0
                              "
                              @click="gift.contributions.showAll = true"
                              link
                              type="danger"
                              >Show all</el-button
                            >
                          </div>
                          <ul
                            v-if="gift.contributions.showAll"
                            class="space-y-1"
                          >
                            <li
                              v-for="contribution in filteredContributions(
                                gift.contributions
                              )"
                              :key="contribution.id"
                            >
                              {{
                                `${toTitleCase(contribution.name)}: $${
                                  contribution.amount_fmt
                                }`
                              }}
                            </li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                    <tr v-else>
                      <p
                        class="w-full overflow-visible whitespace-nowrap bg-transparent p-6"
                      >
                        No gifts added yet
                      </p>
                    </tr>
                  </table>
                  <!-- Pagination -->
                  <!-- <nav
                    class="
                      flex
                      items-center
                      justify-between
                      border-t border-gray-200
                      bg-white
                      px-4
                      py-3
                      sm:px-6
                    "
                    aria-label="Pagination"
                  >
                    <div class="hidden sm:block">
                      <p class="text-sm text-gray-700">
                        Showing
                        {{ " " }}
                        <span class="font-medium">1</span>
                        {{ " " }}
                        to
                        {{ " " }}
                        <span class="font-medium">10</span>
                        {{ " " }}
                        of
                        {{ " " }}
                        <span class="font-medium">20</span>
                        {{ " " }}
                        results
                      </p>
                    </div>
                    <div class="flex flex-1 justify-between sm:justify-end">
                      <a
                        href="#"
                        class="
                          relative
                          inline-flex
                          items-center
                          rounded-md
                          border border-gray-300
                          bg-white
                          px-4
                          py-2
                          text-sm
                          font-medium
                          text-gray-700
                          hover:bg-gray-50
                        "
                        >Previous</a
                      >
                      <a
                        href="#"
                        class="
                          relative
                          ml-3
                          inline-flex
                          items-center
                          rounded-md
                          border border-gray-300
                          bg-white
                          px-4
                          py-2
                          text-sm
                          font-medium
                          text-gray-700
                          hover:bg-gray-50
                        "
                        >Next</a
                      >
                    </div>
                  </nav> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import GiftCards from "@/components/admin/GiftCards.vue";
export default {
  components: { GiftCards },
  props: ["gifts"],
  data() {
    return {};
  },
  computed: {
    // todo: check /100 formatting after Dave handles money conversions
    descendingGifts() {
      return this.gifts.sort((a, b) => {
        return (
          b.total_contributions / b.amount / 100 -
          a.total_contributions / a.amount / 100
        );
      });
    },
  },
  methods: {
    filteredContributions(contributions) {
      return contributions.filter(
        (contribution) => contribution.status != "incomplete"
      );
    },
  },
};
</script>
