<template>
  <div class="partners-dashboard min-h-screen bg-gray-50">
    <el-menu
      class="el-menu-demo"
      mode="horizontal"
      :ellipsis="false"
      @select="handleSelect"
    >
      <el-menu-item index="0">
        <h3 class="font-heading text-4xl text-primary">reggie</h3>
        <h2
          class="ml-2 mt-2 font-subheading text-base font-bold tracking-tight text-gray-900"
        >
          Partner Program
        </h2>
      </el-menu-item>
      <el-sub-menu index="1">
        <template #title>{{ user.name }}</template>
        <el-menu-item index="logout">Logout</el-menu-item>
      </el-sub-menu>
    </el-menu>
    <div
      class="md:px-30 w-full space-y-3 bg-white px-6 py-3 md:flex md:items-center md:justify-between md:py-10 lg:px-40"
    >
      <div>
        <h1
          class="text-2xl font-bold leading-7 text-black sm:truncate sm:leading-9"
        >
          {{ `Hey, ${user.name}.` }}
        </h1>
        <div class="mt-1 md:mt-0">
          <p class="flex items-center text-red-700">
            Here's your referral summary
            <span class="md:hidden">&nbsp;for this month</span>.
          </p>
        </div>
      </div>
      <div class="hidden md:block">
        <el-alert
          type="info"
          effect="dark"
          :closable="false"
          @click="copyText()"
          class="cursor-pointer"
        >
          <template #title> Your Referral Link </template>
          <div>
            {{ referralLink }}
          </div>
        </el-alert>
      </div>
    </div>
    <div class="bg-gray-50">
      <div class="md:px-30 h-full px-6 py-6 md:py-12 lg:px-40">
        <div class="grid w-full grid-cols-1 gap-3 sm:grid-cols-3">
          <StatCard
            v-for="card in cards"
            :key="card.title"
            :label="card.title"
            :value="card.value"
            :tooltip="card.tooltip"
          />
        </div>
        <div class="mt-6 md:mt-8">
          <div class="bg-white p-4">
            <div class="mb-4 hidden justify-between md:flex">
              <div>
                <el-input
                  v-model="search"
                  :disabled="loading"
                  placeholder="Type to search"
                />
              </div>
              <div>
                <el-date-picker
                  v-model="date"
                  @change="getData"
                  :disabled="loading"
                  :disabled-date="
                    function (date) {
                      return date > Date.now();
                    }
                  "
                  type="daterange"
                  :clearable="false"
                  :unlink-panels="false"
                  format="D MMM YYYY"
                  range-separator="To"
                  start-placeholder="Start date"
                  end-placeholder="End date"
                  :shortcuts="shortcuts"
                  size="large"
                />
              </div>
            </div>
            <el-table v-loading="loading" :data="filterTableData" stripe>
              <el-table-column label="Email" prop="email">
                <template #default="scope">
                  <p
                    @click="scope.row.show = !scope.row.show"
                    :class="{ truncate: !scope.row.show }"
                  >
                    {{ scope.row.email }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column label="Account Created Date" prop="created">
                <template #default="scope">
                  {{ formatDate(scope.row.created, "DD/MM/YYYY") }}
                </template>
              </el-table-column>
              <el-table-column label="Paid Date" prop="paid_datetime">
                <template #default="scope">
                  {{
                    scope.row.is_paid
                      ? formatDate(scope.row.paid_datetime, "DD/MM/YYYY")
                      : "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column label="Amount" prop="value">
                <template #default="scope">
                  {{
                    scope.row.is_paid
                      ? formatCurrency(scope.row.value / 100, "AU", "AUD")
                      : "-"
                  }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useUserStore } from "@/stores/UserStore";
import { mapActions } from "pinia";

export default {
  mounted() {
    const userStore = useUserStore();
    this.user = userStore.user;

    //set default date to month to date
    const end = new Date();
    const start = new Date(end.getFullYear(), end.getMonth(), 1);
    this.date = [start, end];
    this.getData();
  },
  data() {
    return {
      loading: false,
      search: "",
      user: "",
      tableData: [],
      date: "",
      shortcuts: [
        {
          text: "Today",
          value: () => {
            const end = new Date();
            const start = new Date();
            return [start, end];
          },
        },
        {
          text: "Last 7 days",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            return [start, end];
          },
        },

        {
          text: "Last 4 weeks",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            return [start, end];
          },
        },
        {
          text: "Last 3 months",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            return [start, end];
          },
        },
        {
          text: "Month to date",
          value: () => {
            const end = new Date();
            const start = new Date(end.getFullYear(), end.getMonth(), 1);
            return [start, end];
          },
        },
        {
          text: "Year to date",
          value: () => {
            const end = new Date();
            const start = new Date(end.getFullYear(), 0, 1);
            return [start, end];
          },
        },
      ],
    };
  },
  computed: {
    filterTableData() {
      return this.tableData.filter(
        (data) =>
          !this.search ||
          data.email.toLowerCase().includes(this.search.toLowerCase())
      );
    },
    cards() {
      const totalAccounts = this.tableData.length;
      const totalPaid = this.tableData.filter((data) => data.is_paid);
      const totalReferralAmount = totalPaid.reduce(
        (acc, data) => acc + data.value,
        0
      );
      return [
        {
          title: "Total Accounts",
          value: totalAccounts,
          tooltip:
            "Number of referrals that created a Reggie account (unpaid).",
        },
        {
          title: "Total Paid",
          value: totalPaid.length,
          tooltip: "Number of accounts that went on to pay.",
        },
        {
          title: "Total Referral Amount (AUD)",
          value: this.formatCurrency(totalReferralAmount / 100, "AU", "AUD"),
          tooltip: "Number of paid users x referral commission ($70).",
          prefix: "$",
        },
      ];
    },
    referralLink() {
      return `https://lovereggie.com?ref=${this.user.affiliateCode}`;
    },
  },
  methods: {
    ...mapActions(useUserStore, ["authenticateAffiliate"]),

    getData() {
      this.loading;
      let APIUrl = "affiliates/referred-events/";
      if (this.date) {
        APIUrl = `${APIUrl}?from=${this.$dayjs(this.date[0]).format(
          "YYYY-MM-DD"
        )}&to=${this.$dayjs(this.date[1]).format("YYYY-MM-DD")}`;
      }
      this.$axiosAuth
        .get(APIUrl)
        .then((res) => {
          this.tableData = res.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    copyText() {
      navigator.clipboard.writeText(this.referralLink);
      ElMessage.success("Referral link copied to clipboard");
    },
    handleSelect(index) {
      if (index === "logout") {
        this.logout();
      }
    },
    logout() {
      this.$axiosAuth.post(`affiliates/logout/`).then((res) => {
        this.authenticateAffiliate(false, null);
        this.$router.push({ name: "partners-login" });
      });
    },
  },
};
</script>

<style>
.partners-dashboard {
  .el-menu--horizontal > .el-menu-item:nth-child(1) {
    margin-right: auto;
  }

  .el-alert {
    @apply min-w-min border border-[#eda391] bg-[#fbede9] md:min-w-[350px]	!important;
  }
  .el-alert__title {
    @apply text-sm font-bold text-primary !important;
  }
  .el-alert__description {
    @apply text-xs text-primary !important;
  }

  .el-range-input {
    @apply cursor-pointer !important;
  }

  .el-range-input:focus {
    @apply border-none ring-0 !important;
  }
}
</style>