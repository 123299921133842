<template>
  <div
    v-loading.fullscreen.lock="loading"
    element-loading-background="#ffffff"
  ></div>
  <AdminLayout v-if="!loading">
    <div class="mx-auto max-w-7xl px-4 pt-8 sm:px-6 lg:px-8">
      <div class="space-y-6">
        <div class="flex items-center gap-x-2">
          <h1 class="text-2xl font-bold leading-7 text-black">
            Thank You Emails
          </h1>
          <el-button
            @click="exportContributions()"
            type="primary"
            size="small"
            plain
            >Export list</el-button
          >
        </div>
        <div class="overflow-hidden rounded-lg bg-white shadow">
          <div class="flex items-center justify-between px-6 py-4">
            <div
              class="flex w-full items-center justify-between text-sm font-medium text-gray-900 sm:block sm:text-gray-700"
            >
              <div>
                Use this page to send your guests a thank you email. Click the
                <strong>draft email button</strong> to start composing an email.
                Once you have sent it, check the
                <strong>email sent checkbox</strong> so you can keep track of
                who you have sent to.
              </div>
              <div class="flex items-center gap-x-2"></div>
            </div>
          </div>
        </div>

        <div class="space-y-4">
          <EmailTable
            v-if="event && contributions"
            :event="event"
            :contributions="contributions"
          />
        </div>
      </div>
    </div>
  </AdminLayout>
</template>

<script>
import AdminLayout from "@/layouts/Admin.vue";
import EmailTable from "@/components/admin/EmailTable.vue";

export default {
  components: {
    AdminLayout,
    EmailTable,
  },
  data() {
    return {
      loading: false,
      event: null,
      contributions: null,
      gifts: null,
      showGiftTable: null,
    };
  },
  mounted() {
    this.loading = true;
    this.getData();
  },

  methods: {
    getData() {
      Promise.all([
        this.$axiosAuth.get(`host/event/`),
        this.$axiosAuth.get(`host/contribution/`),
      ])
        .then((res) => {
          this.event = res[0].data[0];
          this.contributions = res[1].data;
        })
        .then((res) => {
          this.loading = false;
        })
        .catch((err) => {
          ElNotification({
            title: "Error",
            message: "Something went wrong, contact support for help",
            type: "error",
          });
          this.loading = false;
        });
    },

    exportContributions() {
      this.$axiosAuth
        .get(`/host/download-contributions`, { responseType: "blob" })
        .then((response) => {
          // create file link in browser's memory
          const href = URL.createObjectURL(response.data);

          // create "a" HTML element with href to file & click
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute("download", "contributions.csv"); //or any other extension
          document.body.appendChild(link);
          link.click();

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        });
    },
  },
};
</script>
