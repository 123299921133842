<template>
  <div>
    <el-radio-group
      v-model="event.properties.mood"
      size="large"
    >
      <el-radio-button
        class="capitalize"
        v-for="mood in moods"
        :key="mood.title"
        :label="mood.title"
      />
    </el-radio-group>
  </div>
</template>

<script>
export default {
  props: ["event"],
  data() {
    return {
      moods: [{ title: "romantic" }, { title: "minimal" }],
    };
  },
};
</script>
