<template>
  <ul
    role="list"
    class="divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl"
  >
    <router-link
      v-for="event in sortedEvents"
      :key="event.domain"
      :to="{ name: 'kiosk-event', params: { eventId: event.event_id } }"
      class="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6"
    >
      <div class="flex min-w-0 gap-x-4">
        <div class="min-w-0 flex-auto">
          <p class="text-sm font-semibold leading-6 text-gray-900">
            <a>
              <span class="absolute inset-x-0 -top-px bottom-0" />
              {{ `${event.name_host1} & ${event.name_host2}` }}
            </a>
          </p>
          <p class="mt-1 flex text-xs leading-5 text-gray-500">
            <a
              :href="`https://lovereggie.com/${event.domain_name}`"
              class="relative truncate hover:underline"
              >/{{ event.domain_name }}</a
            >
          </p>
          <p class="mt-1 flex text-xs leading-5 text-gray-500">
            {{ event.referred_by }}
          </p>
        </div>
      </div>
      <div class="flex shrink-0 items-center gap-x-4">
        <div class="hidden sm:flex sm:flex-col sm:items-end">
          <country-flag :country="event.country_code" size="small" />
          <p class="mt-1 text-xs leading-5 text-gray-500">
            <template v-if="date == 'paid_datetime'"> paid </template>
            <template v-else-if="date == 'created'"> created </template>
            <template v-else-if="date == 'start_datetime'"> starts </template>
            <time
              v-if="['paid_datetime', 'created'].includes(event[date])"
              :datetime="event[date]"
              >{{ this.$dayjs(event[date]).toNow() }}</time
            >
            <time v-else :datetime="event[date]">{{
              this.$dayjs(event[date]).fromNow()
            }}</time>
          </p>
        </div>
        <ChevronRightIcon
          class="h-5 w-5 flex-none text-gray-400"
          aria-hidden="true"
        />
      </div>
    </router-link>
  </ul>
</template>

<script>
import { ChevronRightIcon } from "@heroicons/vue/20/solid";
import CountryFlag from "vue-country-flag-next";

export default {
  components: {
    ChevronRightIcon,
    CountryFlag,
  },
  props: {
    events: {
      type: Array,
    },
    date: {
      type: String,
    },
  },
  computed: {
    //order events by date in ascending order
    sortedEvents() {
      return this.events.sort((a, b) => {
        return this.$dayjs(b[this.date]).diff(this.$dayjs(a[this.date]));
      });
    },
  },
};
</script>
