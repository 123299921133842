<template>
  <div>
    <h2 class="mb-3">Recently Created</h2>
    <ListCard :events="events" date="created" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      events: [],
    };
  },
  mounted: function () {
    this.getData();
  },
  methods: {
    getData() {
      const params = {
        //event in past 7 days, use day js
        created_from: this.$dayjs().subtract(7, "day").format("YYYY-MM-DD"),
      };
      this.$axiosAuth
        .get("kiosk/events/", {
          params: params,
        })
        .then((res) => {
          this.events = res.data;
        })
        .catch((err) => {
          ElNotification({
            title: "Error",
            message: "Error getting data",
            type: "error",
          });
        });
    },
  },
};
</script>

<style>
</style>