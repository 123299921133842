<template>
  <div class="verify-stripe">
    <!-- loading full screen -->
    <div v-loading.fullscreen.lock="loading"></div>

    <el-button
      v-if="page == 'settings'"
      :loading="loading"
      @click="
        this.event.stripe_registration_status == 'incomplete'
          ? (this.countryDialogVisible = true)
          : this.postStripe()
      "
      type="primary"
      plain
      >Update Bank Details</el-button
    >
    <el-button
      v-else-if="page == 'payment'"
      :loading="loading"
      @click="
        this.event.stripe_registration_status == 'incomplete'
          ? (this.countryDialogVisible = true)
          : this.postStripe()
      "
      type="primary"
      plain
      >Verify details</el-button
    >
    <el-button
      v-else-if="
        page != 'payment' &&
        page != 'settings' &&
        event.stripe_registration_status != 'complete'
      "
      :loading="loading"
      @click="
        this.event.stripe_registration_status == 'incomplete'
          ? (this.countryDialogVisible = true)
          : this.postStripe()
      "
      type="primary"
      size="large"
      round
      plain
    >
      <el-icon :size="20">
        <Warning />
      </el-icon>
      <span
        v-if="this.event.stripe_registration_status === 'incomplete'"
        class="text-lg"
      >
        Verify Details </span
      ><span v-else class="text-lg"> Further verification needed </span>
    </el-button>
    <el-dialog v-model="countryDialogVisible" width="40%">
      <template #header="{}">
        <div>
          <h4>Confirm Your Country</h4>
        </div>
      </template>
      <div class="space-y-4">
        <p>
          Confirm the country that will be used for your registry. The country
          chosen will also determine the currency of your registry.
        </p>
        <p>
          Once you click <span class="font-bold">Verify Details</span>, you
          won't be able to change your country anymore.
        </p>
        <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div class="col-span-full space-y-4">
            <div>
              <el-select
                v-model="event.country_code"
                filterable
                class="mt-1 w-full"
                size="large"
                @change="updateCountry"
              >
                <el-option
                  v-for="item in countryDropdownOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                  <span style="float: left">{{ item.label }}</span>
                  <span style="float: right"
                    ><country-flag
                      :country="item.value.toLowerCase()"
                      size="small"
                  /></span>
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <el-button
          @click="postStripe()"
          :disabled="loading"
          type="primary"
          size="large"
          plain
          class="w-full"
          >Verify Details</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import CountryFlag from "vue-country-flag-next";
import { Warning } from "@element-plus/icons-vue";
import { mapActions } from "pinia";
import { useUserStore } from "@/stores/UserStore";

export default {
  components: {
    CountryFlag,
    Warning,
  },
  props: ["event", "page"],
  data() {
    return {
      loading: false,
      countryDialogVisible: false,
    };
  },
  methods: {
    ...mapActions(useUserStore, ["updateUserCountryCode"]),
    updateCountry() {
      this.$axiosAuth
        .patch(`host/event/${this.event.event_id}/`, {
          country_code: this.event.country_code,
        })
        .then((res) => {
          this.updateUserCountryCode(this.event.country_code);
          ElNotification({
            title: "Success",
            message: "Country updated successfully",
            type: "success",
          });
        })
        .catch((err) => {
          ElNotification({
            title: "Error",
            message: err.response.data.non_field_errors[0],
            type: "error",
          });
        });
    },

    postStripe() {
      this.loading = true;
      this.$axiosAuth
        .post(`/host/stripe/account-onboard/`, {
          country: this.event.country_code,
        })
        .then((res) => {
          let stripeURL = res.data.url;
          window.location.replace(stripeURL);
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
.verify-stripe {
  .el-dialog__headerbtn {
    @apply mr-2 w-auto !important;
  }
}
</style>
