<template>
  <div class="mb-4 flex space-x-4">
    <el-input
      @keyup.enter="searchImage(search)"
      v-model="search"
      class="w-full"
      placeholder="Search for an image"
    />
    <el-button
      :loading="loading"
      type="primary"
      size="large"
      plain
      @click="searchImage(search)"
      :disabled="search.length == 0"
      >Search</el-button
    >
  </div>
  <ul
    role="list"
    :class="
      registration
        ? 'max-h-[calc(100vh/3)]'
        : 'max-h-[calc(100vh-430px)] 2xl:max-h-[calc(100vh/2px)]'
    "
    class="grid grid-cols-1 gap-x-4 gap-y-8 overflow-y-scroll p-1 lg:grid-cols-2 xl:gap-x-2"
  >
    <li v-for="(file, index) in files" :key="file.source" class="relative">
      <div
        class="group block h-32 w-full overflow-hidden rounded-lg bg-gray-100"
        :class="index == selected ? 'ring-2 ring-red-500 ring-offset-2' : ' '"
      >
        <img
          @click="
            $emit('imageSelected', {
              full: file.urls.full,
              small: file.urls.regular,
              download: file.links.download_location,
            }),
              (selected = index)
          "
          :src="file.urls.small"
          alt=""
          class="h-32 w-full cursor-pointer object-cover group-hover:opacity-75"
        />
      </div>
      <div class="mt-2 text-xs font-medium text-gray-900">
        <span class=""> Unsplash Photo by </span>
        <a
          class="font-bold"
          :href="`${file.user.links.html}?utm_source=reggie&utm_medium=referral`"
          target="_blank"
          >{{ file.user.name }}</a
        >
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  props: ["initialSearch", "registration"],
  emits: ["imageSelected"],
  data() {
    return {
      search: "",
      files: null,
      loading: false,
      selected: null,
    };
  },
  mounted() {
    if (this.initialSearch && this.initialSearch !== "New Gift") {
      this.search = this.initialSearch;
      this.searchImage(this.initialSearch);
    } else {
      this.searchImage("beautiful landscapes");
    }
  },
  methods: {
    searchImage(query) {
      if (query.length == 0) return;

      this.loading = true;
      this.$axios
        .get(`search/photos/?query=${query}`)
        .then((res) => {
          this.files = res.data.results;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          ElNotification({
            title: "Error",
            message: "Something went wrong",
            type: "error",
          });
        });
    },
  },
};
</script>
