<template>
  <div class="flex min-h-screen">
    <div
      class="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24"
    >
      <div class="mx-auto w-full max-w-sm lg:w-96">
        <div class="flex gap-x-2">
          <h3 class="font-heading text-6xl text-primary">reggie</h3>
          <h2
            class="mt-6 font-subheading text-xl font-bold tracking-tight text-gray-900"
          >
            Partner Program
          </h2>
        </div>
        <slot />
      </div>
    </div>
    <div class="relative hidden w-0 flex-1 lg:block">
      <img
        class="absolute inset-0 h-full w-full object-cover"
        src="../../assets/img/partners-login.jpg"
        alt=""
      />
    </div>
  </div>
</template>
