<template>
  <nav aria-label="Progress">
    <ol
      role="list"
      class="flex items-center"
    >
      <li
        @click="
          step.status === 'complete' ||
          step.status === 'current' ||
          (stepIdx == steps.length - 1 &&
            steps[stepIdx - 1].status == 'complete')
            ? $emit('changeStep', stepIdx)
            : '',
            (stepClicked = true)
        "
        v-for="(step, stepIdx) in steps"
        :key="step.stepIdx"
        :class="[stepIdx !== steps.length - 1 ? 'pr-8' : '', 'relative']"
      >
        <template v-if="step.status === 'complete'">
          <!-- <div class="absolute inset-0 flex items-center" aria-hidden="true">
            <div class="h-0.5 w-full bg-primary" />
          </div> -->
          <a
            class="relative flex h-8 w-8 items-center justify-center rounded-full bg-primary transition-all duration-200 hover:brightness-125"
          >
            <CheckIcon
              class="h-5 w-5 text-white"
              aria-hidden="true"
            />
          </a>
        </template>
        <template v-else-if="step.status === 'current'">
          <!-- <div class="absolute inset-0 flex items-center" aria-hidden="true">
            <div class="h-0.5 w-full bg-gray-200" />
          </div> -->
          <a
            class="relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-primary bg-white transition-all duration-200 hover:brightness-125"
            aria-current="step"
          >
            <span
              class="h-2.5 w-2.5 rounded-full bg-primary"
              aria-hidden="true"
            />
            <span class="sr-only">{{ step.name }}</span>
          </a>
        </template>
        <template v-else>
          <!-- <div class="absolute inset-0 flex items-center" aria-hidden="true">
            <div class="h-0.5 w-full bg-gray-200" />
          </div> -->
          <a
            class="group relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white"
          >
            <span
              class="h-2.5 w-2.5 rounded-full bg-transparent"
              aria-hidden="true"
            />
            <span class="sr-only">{{ step.name }}</span>
          </a>
        </template>
      </li>
    </ol>
  </nav>
</template>

<script>
import { CheckIcon } from "@heroicons/vue/20/solid";

export default {
  components: { CheckIcon },
  props: ["total", "current"],
  mounted() {
    for (let i = 0; i < this.total; i++) {
      i == 0
        ? this.steps.push({ status: "current" })
        : this.steps.push({ status: "upcoming" });
    }
  },
  data() {
    return {
      steps: [],
      stepClicked: false,
    };
  },
  watch: {
    current(newVal, oldVal) {
      if (!this.stepClicked) {
        this.steps[oldVal].status = "complete";
        if (this.steps[newVal].status != "complete") {
          this.steps[newVal].status = "current";
        }
      }
    },
    stepClicked() {
      if (this.stepClicked == true) {
        this.stepClicked = false;
      }
    },
  },
};
</script>
