<template>
  <button
    v-if="platform == 'google'"
    type="button"
    class="dark:focus:ring-[#4285F4]/55 flex h-10 w-full items-center justify-center gap-x-2 rounded-lg border border-gray-300 bg-white px-4 text-center text-sm font-medium text-gray-500 focus:outline-none focus:ring-4 focus:ring-[#4285F4]/50"
  >
    <span>{{ type == "register" ? "Register with" : "Sign in with" }}</span>
    <svg
      class="h-6 w-6"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M43.2857 23.1363C43.2857 21.5924 43.1471 20.1079 42.8898 18.6827H22.3839V27.1048H34.1015C33.5968 29.8264 32.0628 32.1323 29.7569 33.6762V39.1392H36.7934C40.9105 35.3488 43.2857 29.767 43.2857 23.1363Z"
        fill="#4285F4"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22.3839 44.4142C28.2625 44.4142 33.191 42.4645 36.7934 39.1392L29.7569 33.6763C27.8072 34.9826 25.3133 35.7546 22.3839 35.7546C16.713 35.7546 11.9131 31.9245 10.201 26.7783H2.92695V32.4194C6.50955 39.5351 13.8727 44.4142 22.3839 44.4142Z"
        fill="#34A853"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.201 26.7782C9.76556 25.4719 9.51814 24.0764 9.51814 22.6414C9.51814 21.2064 9.76556 19.8109 10.201 18.5046V12.8635H2.92694C1.45233 15.8028 0.611115 19.1281 0.611115 22.6414C0.611115 26.1547 1.45233 29.48 2.92694 32.4193L10.201 26.7782Z"
        fill="#FBBC05"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22.3839 9.52826C25.5805 9.52826 28.4505 10.6268 30.707 12.7843L36.9518 6.53946C33.1811 3.02613 28.2526 0.868652 22.3839 0.868652C13.8727 0.868652 6.50955 5.74772 2.92695 12.8634L10.201 18.5046C11.9131 13.3583 16.713 9.52826 22.3839 9.52826Z"
        fill="#EA4335"
      />
    </svg>
    <span class="capitalize">{{ platform }}</span>
  </button>
  <button
    v-else-if="platform == 'facebook'"
    type="button"
    class="dark:focus:ring-[#4285F4]/55 inline-flex h-12 items-center gap-x-2 rounded-lg bg-white px-4 text-center text-sm font-medium text-gray-500 focus:outline-none focus:ring-4 focus:ring-[#4285F4]/50"
  >
    <svg
      class="h-7 w-7"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0,0,256,256"
      fill-rule="nonzero"
    >
      <g
        fill="#1877f2"
        fill-rule="nonzero"
        stroke="none"
        stroke-width="1"
        stroke-linecap="butt"
        stroke-linejoin="miter"
        stroke-miterlimit="10"
        stroke-dasharray=""
        stroke-dashoffset="0"
        font-family="none"
        font-weight="none"
        font-size="none"
        text-anchor="none"
        style="mix-blend-mode: normal"
      >
        <g transform="scale(5.12,5.12)">
          <path
            d="M25,3c-12.15,0 -22,9.85 -22,22c0,11.03 8.125,20.137 18.712,21.728v-15.897h-5.443v-5.783h5.443v-3.848c0,-6.371 3.104,-9.168 8.399,-9.168c2.536,0 3.877,0.188 4.512,0.274v5.048h-3.612c-2.248,0 -3.033,2.131 -3.033,4.533v3.161h6.588l-0.894,5.783h-5.694v15.944c10.738,-1.457 19.022,-10.638 19.022,-21.775c0,-12.15 -9.85,-22 -22,-22z"
          ></path>
        </g>
      </g>
    </svg>
    <span class="capitalize">{{ platform }}</span>
  </button>
</template>

<script>
export default {
  props: ["platform", "type"],
};
</script>

<style></style>
