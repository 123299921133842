<template>
  <Switch
    v-model="isEnabled"
    class="red:ring-indigo-500 focus:ring-offset-2', relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2"
  >
    <span class="sr-only">Use setting</span>
    <span
      aria-hidden="true"
      :class="[
        enabled ? 'translate-x-5' : 'translate-x-0',
        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
      ]"
    />
  </Switch>
</template>

<script>
import { Switch } from "@headlessui/vue";

export default {
  components: { Switch },
  props: ["enabled"],
  mounted() {
    this.isEnabled = this.enabled;
  },
  data() {
    return {
      isEnabled: null,
    };
  },
};
</script>

<style></style>
