<template>
  <LoginLayout>
    <div>
      <h3 class="font-heading text-6xl text-primary">reggie</h3>
      <h2
        class="mt-2 font-subheading text-3xl font-bold tracking-tight text-gray-900"
      >
        Forgot your password?
      </h2>
      <p class="mt-4 text-sm text-gray-600">
        No problem. Enter your email below and we will email you a link to reset
        it.
      </p>
    </div>

    <div class="mt-4">
      <el-form
        @submit.prevent
        :model="forgotPasswordForm"
        :rules="forgotPasswordFormRules"
        :status-icon="true"
        label-position="top"
        ref="forgotPasswordFormRef"
      >
        <el-form-item
          v-if="!forgotPasswordSuccess"
          label="Email"
          :label-width="100"
          prop="email"
        >
          <el-input
            v-model="forgotPasswordForm.email"
            type="email"
            size="large"
            @keyup.enter="forgotPassword(forgotPasswordFormRef)"
          />
        </el-form-item>
        <el-alert
          v-if="forgotPasswordError"
          :title="forgotPasswordErrorMessage"
          @close="handleErrorAlertClose"
          type="error"
        ></el-alert>
        <el-button
          v-if="!forgotPasswordSuccess"
          @click="forgotPassword(forgotPasswordFormRef)"
          class="w-full"
          type="primary"
          size="large"
          plain
          >Send Password Reset Link</el-button
        >
      </el-form>
      <el-alert
        v-if="forgotPasswordSuccess"
        title="Email Sent"
        :description="`If ${forgotPasswordForm.email} is an active Reggie account,
               then we have just sent a reset link to your email.`"
        type="success"
        :closable="false"
      />
      <div class="mt-4 text-sm">
        <router-link
          class="font-medium text-primary hover:opacity-70"
          :to="{ name: 'login' }"
        >
          Remember your password?
        </router-link>
      </div>
    </div>
  </LoginLayout>
</template>

<script>
import LoginLayout from "@/layouts/Login.vue";
import { ref, reactive } from "vue";

export default {
  components: {
    LoginLayout,
  },

  data() {
    return {
      forgotPasswordSuccess: false,
      forgotPasswordError: false,
      forgotPasswordForm: reactive({
        email: null,
      }),
      forgotPasswordFormRules: reactive({
        email: [
          {
            required: true,
            message: "Please input your email",
            trigger: "blur",
          },
          {
            type: "email",
            message: "Please input a valid email",
            trigger: "blur",
          },
        ],
      }),
    };
  },
  setup() {
    const forgotPasswordFormRef = ref(null);
    return { forgotPasswordFormRef };
  },
  methods: {
    async forgotPassword(forgotPasswordFormRef) {
      await forgotPasswordFormRef.validate((valid, fields) => {
        if (valid) {
          this.$axiosAuth
            .post("users/password/reset/", {
              email: this.forgotPasswordForm.email,
            })
            .then((response) => {
              ElNotification({
                message: "Email sent!",
                type: "success",
              });
              this.forgotPasswordEmail = "";
              this.forgotPasswordError = false;
              this.forgotPasswordSuccess = true;
            })
            .catch((error) => {
              if (error.response.status === 500) {
                this.forgotPasswordError = true;
                this.forgotPasswordErrorMessage =
                  "Server error. Please try again.";
              } else {
                ElNotification({
                  message: "Email sent!",
                  type: "success",
                });
                this.forgotPasswordEmail = "";
                this.forgotPasswordError = false;
                this.forgotPasswordSuccess = true;
              }
            });
        }
      });
    },
    handleErrorAlertClose() {
      this.forgotPasswordError = false;
    },
  },
};
</script>
