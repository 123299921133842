<template>
  <div
    class="relative"
    :style="{ background: event.properties.colors.background }"
  >
    <main
      class="flex flex-col lg:flex-row"
      :class="[dashboardPreview ? '2xl:px-24' : '2xl:px-36']"
    >
      <div
        :class="[
          dashboardPreview
            ? 'min-h-fit py-12 pl-8'
            : 'min-h-fit px-6 py-6 lg:h-[100vh] lg:px-12 lg:py-10',
        ]"
        class="order-2 w-full max-w-7xl text-center lg:order-1 lg:w-1/2"
      >
        <div
          class="mb-4 flex h-full flex-col justify-center break-words pr-4 text-left lg:pt-0"
          :class="extraPadding ? 'pb-16 lg:pb-0' : null"
        >
          <Details
            layout="split"
            :event="event"
            :dashboardPreview="dashboardPreview"
          />
        </div>
      </div>
      <div
        :data-aos="
          !dashboardPreview && event.properties.animate ? 'fade-left' : null
        "
        data-aos-once="true"
        data-aos-duration="1000"
        data-aos-delay="1000"
        class="order-1 block lg:order-2 lg:grid lg:w-1/2 lg:place-items-center"
        :class="[
          event.properties.clipPath ? 'px-4 pt-5 ' : null,
          dashboardPreview ? 'lg:p-7' : null,
          event.properties.clipPath == 'circle(50% at 50% 50%)'
            ? 'h-auto'
            : 'h-80 lg:h-auto',
        ]"
      >
        <div
          v-if="event[event.properties.img_source]"
          :style="{
            backgroundImage:
              'url(' +
              `${
                event.imgPreview && event.properties.img_source == 'img'
                  ? event.imgPreview
                  : event[event.properties.img_source]
              }` +
              ')',
            clipPath: event.properties.clipPath,
          }"
          :class="[
            dashboardPreview ? 'rounded-tr-lg' : '',
            event.properties.clipPath == 'circle(50% at 50% 50%)'
              ? 'aspect-square w-full '
              : ' aspect-square h-full w-full lg:h-auto',
          ]"
          class="bg-cover bg-center bg-no-repeat"
        ></div>
      </div>
    </main>
  </div>
</template>

<script>
import Details from "@/layouts/event/snippets/Details.vue";

export default {
  components: { Details },
  props: ["event", "dashboardPreview"],
};
</script>
