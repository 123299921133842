

<template>
  <div>
    <NavBar v-if="$route.name && showNavbar" />
  </div>
  <RouterView />
</template>

<script>
export default {
  computed: {
    showNavbar() {
      return ![
        "register",
        "event",
        "login",
        "forgot",
        "password-reset",
        "gift-preview",
        "404",
        "google",
        "browserLoggedIn",
        "facebook",
        "preview",
        "partners-dashboard",
        "partners-login",
        "partners-welcome",
      ].includes(this.$route.name);
    },
  },
};
</script>
