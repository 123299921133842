<template>
  <main class="min-h-screen bg-gray-50 bg-cover bg-center">
    <h1>Facebook</h1>
  </main>
</template>

<script>
import { useUserStore } from "@/stores/UserStore";
import { mapActions } from "pinia";
export default {
  mounted() {
    const access_token = this.$route.query.code;
    const eventFormEncoded = this.$route.query.state;
    this.eventForm = JSON.parse(
      window.atob(decodeURIComponent(eventFormEncoded))
    );
    if (access_token) {
      this.authenticate(access_token);
    }
  },
  data() {
    return {
      eventForm: {},
    };
  },
  methods: {
    ...mapActions(useUserStore, ["authenticateUser"]),
    getHashValue(key) {
      var matches = window.location.hash.match(new RegExp(key + "=([^&]*)"));
      return matches ? matches[1] : null;
    },
    authenticate(access_token) {
      this.$axiosAuth
        .post("users/auth/facebook/", { code: access_token })
        .then((response) => {
          this.createEvent();
        })
        .catch((error) => {});
    },
    createEvent() {
      let formData = new FormData();

      for (const item in this.eventForm) {
        let toAppend = this.eventForm[item];
        if (item == "properties") JSON.stringify(toAppend);
        formData.append(`${item}`, toAppend);
      }

      this.$axiosAuth
        .post("host/event/", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          this.authenticateUser(true, response.data.user); //where should this go?
          this.loading = false;
          ElNotification({
            title: "Success",
            message: "Nice, we are creating your registry for you now!",
            type: "success",
          });
          setTimeout(() => {
            this.$router.push({ name: "dashboard" });
          }, 4000);
        })
        .catch((error) => {
          ElNotification({
            title: "Error",
            message: "Something went wrong",
            type: "error",
          });
        });
    },
  },
};
</script>
