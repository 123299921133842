<template>
  <div class="bg-gray-50">
    <div
      class="min-h-screen"
      :class="user && !user.email_verified ? 'pt-32 lg:pt-24' : 'pt-12'"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import { useUserStore } from "@/stores/UserStore";

export default {
  components: {},
  mounted() {
    const userStore = useUserStore();
    this.user = userStore.user;
  },

  data() {
    return {
      user: null,
    };
  },
};
</script>
