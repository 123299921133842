<template>
  <div>
    <div class="my-2 space-y-2">
      <div class="flex space-x-2">
        <div class="w-2/3">
          <p class="el-form-item__label" style="justify-content: flex-start">
            Title
          </p>
          <el-form-item>
            <el-input
              v-model="field.title"
              size="large"
              placeholder="Title (optional)"
            />
          </el-form-item>
        </div>
        <div v-if="currentLayout != 'split'" class="w-1/3">
          <p class="el-form-item__label" style="justify-content: flex-start">
            Icon
          </p>
          <el-select
            v-model="field.icon"
            placeholder="Select"
            placement="left"
            class="icon"
            clearable
          >
            <div class="grid grid-cols-3 gap-y-3">
              <el-option v-for="item in icons" :key="item" :value="item">
                <img
                  :src="`/icons/${item}.svg`"
                  class="h-auto w-8 object-cover object-center"
                />
              </el-option>
            </div>
            <template #prefix>
              <div>
                <img
                  v-if="field.icon"
                  :src="`/icons/${field.icon}.svg`"
                  class="w-8 object-cover object-center"
                />
                <span v-else>Select</span>
              </div>
            </template>
          </el-select>
        </div>
      </div>
      <el-form-item label="Content">
        <el-input :rows="3" type="textarea" v-model="field.description" />
      </el-form-item>
    </div>
  </div>
</template>

<script>
export default {
  props: ["field", "index", "event"],
  emits: ["click"],
  data() {
    return {
      icons: [
        "Alarm_Clock",
        "Bar",
        "Bicycle",
        "Burger",
        "Cafe",
        "Car",
        "Cash_Point",
        "Chat",
        "Coat_Hanger",
        "Conference_Room",
        "Day_Night",
        "Day",
        "Do_Not_Disturb",
        "Double_Bed",
        "Face_Mask",
        "Female",
        "Food",
        "Guest_Area",
        "Ice_Cold",
        "Juice",
        "Key_Card",
        "Key",
        "Luggage",
        "Male",
        "Map_Marker",
        "Music",
        "Night",
        "Parking",
        "Person",
        "Phone",
        "Plane",
        "Pool_Chair",
        "Pool",
        "Restroom",
        "Shopping",
        "Takeaway",
        "Train",
        "Wheelchair",
        "Wine_Bottle",
      ],
    };
  },
};
</script>

<style>
.icon {
  .el-input {
    height: 40px;
  }
  .el-input__inner {
    display: none;
  }
}
.el-select-dropdown__item {
  @apply px-3 !important;
}
</style>
