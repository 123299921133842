import axios from "axios";
import VueAxios from "vue-axios";
import { useUserStore } from "../stores/UserStore";
import router from "../router";

export default {
  install: (app, options) => {
    const userStore = useUserStore();

    const baseURL = import.meta.env.VITE_API_URL;

    const publicAxiosInstance = axios.create({
      baseURL: baseURL,
    });

    const authAxiosInstance = axios.create({
      baseURL: baseURL,
      withCredentials: true,
    });

    // code taken from https://medium.com/swlh/handling-access-and-refresh-tokens-using-axios-interceptors-3970b601a5da

    authAxiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      function (error) {
        const originalRequest = error.config;

        if (
          error.response.status === 401 &&
          originalRequest.url === "users/token/refresh/"
        ) {
          userStore.authenticateUser(false, null);
          router.push({ name: "login" });
          return Promise.reject(error);
        }

        if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest.headers = { ...originalRequest.headers };
          originalRequest._retry = true;
          return authAxiosInstance.post("users/token/refresh/").then((res) => {
            if (res.status === 200) {
              return authAxiosInstance(originalRequest);
            }
          });
        }

        // return Error object with Promise
        return Promise.reject(error);
      }
    );

    app.use(VueAxios, {
      $axiosAuth: authAxiosInstance,
      $axios: publicAxiosInstance,
    });
  },
};
