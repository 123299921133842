export default {
  data() {
    return {
      colorPickers: [
        {
          uiElement: "accent",
        },
        {
          uiElement: "background",
        },
        {
          uiElement: "heading",
        },
        {
          uiElement: "paragraph",
        },
        // {
        //   uiElement: "additionalDetails",
        // },
      ],
      colorPalettes: {
        reggie: {
          background: "#FEFBF0",
          paragraph: "#DB4623",
        },
        peachMaroon: {
          background: "#F7F0F5",
          paragraph: "#72082B",
        },
        earthyGreen: {
          background: "#ECEBE4",
          paragraph: "#16302B",
        },
        hipsterPeach: {
          background: "#FFF5E4",
          paragraph: "#FF9494",
        },
        lightBlu: {
          background: "#FFDDE2",
          paragraph: "#143642",
        },
        lavendarCyan: {
          background: "#FAE8EB",
          paragraph: "#0D8B96",
        },
        greenAzure: {
          background: "#14281D",
          paragraph: "#DAE7E7",
        },
        blueYellow: {
          background: "#36070F",
          paragraph: "#FBDADF",
        },
        violetGhost: {
          background: "#7B517B",
          paragraph: "#F7F7FF",
        },
        deftAsh: {
          background: "#102342",
          paragraph: "#C1D7D2",
        },
        gunHoney: {
          background: "#122C34",
          paragraph: "#EDFCF0",
        },
        madderPink: {
          background: "#A71D31",
          paragraph: "#F5CCE8",
        },
      },
    };
  },
};
