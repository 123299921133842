<template>
  <div class="">
    <ul
      v-if="gifts.length > 0"
      role="list"
      class="divide-y divide-gray-200 rounded-lg bg-white shadow"
    >
      <li v-for="gift in gifts" :key="gift.id" class="px-4 py-4">
        <div class="items-top flex gap-x-4">
          <img
            :src="giftImageUrl(gift)"
            class="h-16 w-16 rounded-full object-cover object-center"
          />
          <div>
            <p>
              {{ gift.name }}
            </p>
            <span class="text-sm text-gray-900">
              {{ `$${gift.total_contributions_fmt}` }}
              <span class="mt-6 text-sm text-gray-500">
                {{ `of $${gift.amount_fmt} funded` }}
              </span>
            </span>
            <span v-if="gift.type != 'unlimited'" class="text-sm text-gray-900">
              ({{
                Math.round(
                  (gift.total_contributions_fmt / gift.amount_fmt) * 100
                )
              }}%)
            </span>
            <div class="p-1">
              <div v-if="!gift.contributions.showAll">
                <h3 class="text-sm font-bold">
                  {{
                    pluralize(
                      filteredContributions(gift.contributions).length,
                      "Contribution"
                    )
                  }}
                </h3>
                <el-button
                  v-if="filteredContributions(gift.contributions).length > 0"
                  @click="gift.contributions.showAll = true"
                  link
                  type="danger"
                  >Show all</el-button
                >
              </div>
              <ul v-if="gift.contributions.showAll" class="space-y-1 text-xs">
                <h3 class="text-sm font-bold">Contributions</h3>
                <li
                  v-for="contribution in filteredContributions(
                    gift.contributions
                  )"
                  :key="contribution.id"
                >
                  {{
                    `${toTitleCase(contribution.name)}: $${
                      contribution.amount_fmt
                    }`
                  }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div v-else class="rounded-lg bg-white p-4 shadow">No gifts added yet</div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["gifts"],

  methods: {
    filteredContributions(contributions) {
      return contributions.filter(
        (contribution) => contribution.status != "incomplete"
      );
    },
  },
};
</script>
