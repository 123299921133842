<template>
  <AdminLayout>
    <div class="mx-auto max-w-7xl px-4 pt-8 sm:px-6 lg:px-8">
      <div class="grid grid-cols-3 gap-x-10">
        <div>
          <RecentlyCreated />
        </div>
        <div>
          <RecentlyPaid />
        </div>
        <div>
          <Upcoming />
        </div>
      </div>
    </div>
  </AdminLayout>
</template>

<script>
import AdminLayout from "@/layouts/Admin.vue";

export default {
  components: {
    AdminLayout,
  },
};
</script>

