<template>
  <div
    v-loading.fullscreen.lock="loading"
    element-loading-background="#ffffff"
  ></div>
  <div
    v-if="!loading"
    :class="slideOverOpen ? 'w-[calc(100%-390px)]' : 'w-full'"
    class="transition-all duration-300 ease-in-out"
  >
    <div class="mb-6 flex items-center justify-between">
      <div class="flex items-center gap-x-4">
        <h1 class="text-2xl font-bold leading-7 text-black">Gifts</h1>
        <AddGift
          @add-gift="(gift) => openSlide(-1, gift)"
          @dropdown-closed="openDropdown = false"
          @multi-gift-add="getData()"
          :countryCode="countryCode"
          :openDropdownProp="openDropdown"
          :event="event"
        />
      </div>
      <div v-if="gifts.length > 0" class="hidden md:block">
        <el-button
          v-if="!editingGiftOrder"
          type="primary"
          size="large"
          round
          plain
          @click="(editingGiftOrder = true), (originalGifts = [...gifts])"
          >Change Gift Order</el-button
        >
        <div v-else>
          <el-button type="success" size="large" round @click="updateGiftOrder"
            >Save Order</el-button
          >
          <el-button
            type="info"
            size="large"
            round
            @click="(editingGiftOrder = false), (gifts = [...originalGifts])"
            >Cancel</el-button
          >
        </div>
      </div>
    </div>

    <!-- Showcase gift -->
    <ul
      v-if="!editingGiftOrder && gifts.length > 0 && pinnedGift.length > 0"
      role="list"
      class="mb-8 md:mb-16"
    >
      <li
        v-show="gifts.length > 0 && pinnedGift.length > 0"
        v-for="gift in pinnedGift"
        :key="gift.gift_id"
        class="relative grid grid-cols-1 gap-x-0 md:grid-cols-2 md:gap-x-16"
      >
        <ShowcaseGift
          v-if="gift"
          @pin-gift="
            (pin) => {
              pinGift(gift, pin);
            }
          "
          @open-slide="
            openSlide(gifts.findIndex((g) => g.gift_id == gift.gift_id))
          "
          :gift="gift"
          :showPrices="showPrices"
          :notFocus="
            slideOverOpen &&
            clickedGiftIndex !=
              gifts.findIndex((g) => g.gift_id == gift.gift_id)
          "
          :admin="true"
        />
      </li>
    </ul>

    <!-- Main gifts list -->
    <draggable
      v-if="event"
      v-model="gifts"
      class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-6"
      @start="drag = true"
      @end="drag = false"
      item-key="id"
      :animation="150"
      :disabled="!editingGiftOrder"
    >
      <template #item="{ element, index }">
        <div
          v-if="
            gifts.length > 0 &&
            !element.properties.pinned &&
            (!event.properties.moveFundedGiftEnd || !element.is_funded)
          "
        >
          <SingleGift
            @pin-gift="
              (pin) => {
                pinGift(element, pin);
              }
            "
            @open-slide="openSlide(index)"
            :gift="element"
            :showPrices="showPrices"
            :admin="true"
            :notFocus="slideOverOpen && clickedGiftIndex !== index"
            :editingGiftOrder="editingGiftOrder"
          />
        </div>
      </template>
    </draggable>

    <!-- funded gifts -->
    <div
      v-if="!editingGiftOrder"
      class="mt-8 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-6"
    >
      <div
        v-show="event.properties.moveFundedGiftEnd"
        v-for="gift in gifts.filter(
          (gift) => gift.is_funded && !gift.properties.pinned
        )"
        :key="gift.gift_id"
      >
        <SingleGift
          @pin-gift="
            (pin) => {
              pinGift(element, pin);
            }
          "
          :gift="gift"
          :showPrices="showPrices"
          :notFocus="slideOverOpen"
        />
      </div>
    </div>
    <!-- empty gift state -->
    <EmptyGiftState
      v-if="gifts.length == 0"
      gifts="gifts"
      @emit-click="openDropdown = true"
    />

    <!-- gift slide over -->
    <GiftSlideOver
      v-if="clickedGift"
      @close-slide="(params) => closeSlide(params)"
      @submit="resetPage()"
      @toggle-drawer="(param) => (drawerState = param)"
      @remove-new-gift="() => this.gifts.shift()"
      :gift="clickedGift"
      :event="event"
      :index="clickedGiftIndex"
      :method="method"
      :open="slideOverOpen"
    />
  </div>
</template>

<script>
import GiftSlideOver from "@/components/admin/GiftSlideOver.vue";
import AddGift from "@/components/admin/AddGift.vue";
import SingleGift from "@/components/admin/SingleGift.vue";
import ShowcaseGift from "@/components/admin/ShowcaseGift.vue";
import EmptyGiftState from "@/components/admin/EmptyGiftState.vue";
import draggable from "vuedraggable";

export default {
  components: {
    GiftSlideOver,
    AddGift,
    SingleGift,
    ShowcaseGift,
    EmptyGiftState,
    draggable,
  },

  mounted() {
    this.loading = true;
    this.getData();
  },

  emits: ["get-gifts"],
  data() {
    return {
      loading: false,
      gifts: [],
      originalGifts: [], //copy of gifts for when user cancels editing gift order
      event: null,
      event_id: null,
      showPrices: null,
      countryCode: null,
      currency: null,
      slideOverOpen: false,
      clickedGift: null,
      clickedGiftIndex: null,
      method: null,
      drag: false,
      originalGiftOrder: null,
      editingGiftOrder: false,
      openDropdown: false,
    };
  },

  computed: {
    pinnedGift() {
      return this.gifts.filter((gift) => gift.properties.pinned);
    },
    orderedGifts() {
      // move gifts where is_funded is true to the end of the list
      if (!this.event.properties.moveFundedGiftEnd) {
        return this.gifts;
      } else {
        return this.gifts.sort((a, b) =>
          a.is_funded === b.is_funded ? 0 : a.is_funded ? 1 : -1
        );
      }
    },
  },

  methods: {
    getData() {
      this.$axiosAuth
        .get(`host/event/`)
        .then((res) => {
          this.event = res.data[0];
          this.countryCode = res.data[0].country_code;
          this.currency = res.data[0].currency;
          this.event_id = res.data[0].event_id;
          this.showPrices = res.data[0].properties.showGiftPrices;
          this.originalGiftOrder = res.data[0].gift_order;
          if (res.data[0].gifts) {
            res.data[0].gifts.forEach((gift) => {
              let index = this.originalGiftOrder.indexOf(gift.gift_id);
              if (index !== -1)
                this.gifts[this.originalGiftOrder.indexOf(gift.gift_id)] = gift;
              else this.gifts.push(gift);
            }); //if there is a gift in the database that somehow is not in the gift_order array, then indexOf() will return -1 and the gift will be placed at the end of the array (i.e. this.gifts[-1]) this is good forces the gift_order and actual gifts to be synchronized
          } else {
            this.gifts = [];
          }
        })
        .then((res) => {
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    pinGift(gift, status) {
      //if we are pinning a new gift and there's currently a pinned gift, unpin it first
      if (status && this.pinnedGift.length > 0) {
        let currentPinnedGift = this.gifts.find(
          (gift) => gift.gift_id == this.pinnedGift[0].gift_id
        );
        let toUnpinProperties = currentPinnedGift.properties;
        toUnpinProperties.pinned = false;

        this.$axiosAuth
          .patch(`host/gift/${currentPinnedGift.gift_id}/`, {
            properties: toUnpinProperties,
          })
          .catch(() => {
            ElNotification({
              title: "Error",
              message: "Something went wrong",
              type: "error",
            });
          });
      }

      let toPinProperties = gift.properties;
      toPinProperties.pinned = status;

      this.$axiosAuth
        .patch(`host/gift/${gift.gift_id}/`, {
          properties: toPinProperties,
        })
        .then((res) => {
          ElNotification({
            title: "Success",
            message: status
              ? "Gift added to showcase"
              : "Gift removed from showcase",
            type: "success",
          });
        })
        .catch((err) => {
          ElNotification({
            title: "Error",
            message: err.response.data,
            type: "error",
          });
        });
    },

    imageSource(gift) {
      let imgSource;
      gift.properties.img_source == "img_url_stock"
        ? (imgSource = gift.img_url_stock.small)
        : (imgSource = gift[gift.properties.img_source]);
      return imgSource;
    },

    openSlide(giftIndex, giftTemplate = null) {
      //if index >= 0, then we are selecting an existing gift
      if (giftIndex >= 0) {
        this.clickedGift = this.gifts[giftIndex];
        this.clickedGiftIndex = giftIndex;
        this.clickedGift.draft && this.clickedGift.new
          ? (this.method = "add")
          : (this.method = "edit"); //handle if user adds new gift, then clicks away, then opens again
        this.slideOverOpen = true;
        //if index < 0, then we are adding a new gift
      } else {
        let gift = {};
        // Using a gift template
        if (giftTemplate) {
          // amazon gift
          if (giftTemplate.hasOwnProperty("asin")) {
            gift = {
              draft: true,
              new: true,
              name: giftTemplate.title,
              type: "multi",
              amount_fmt: Math.floor(giftTemplate.price.value),
              img_url: giftTemplate.mainImageUrl,
              country_code: this.countryCode,
              currency: giftTemplate.price.currency,
              properties: { img_source: "img_url", pinned: false },
              amazon_blob: giftTemplate,
            };
          }
          //gift template
          else {
            gift = {
              draft: true,
              new: true,
              name: giftTemplate.brand
                ? `${giftTemplate.brand} - ${giftTemplate.name}`
                : giftTemplate.name,
              type: `${giftTemplate.type}`,
              description: `${giftTemplate.description}`,
              amount_fmt: Math.floor(giftTemplate.amount_fmt),
              img_url: giftTemplate.img ? giftTemplate.img : "",
              country_code: this.countryCode,
              currency: this.currency,
              img_url_stock: giftTemplate.img_url_stock,
              template_id: giftTemplate.template_id,
              properties: giftTemplate.properties,
            };
          }

          //New gift from scratch
        } else {
          gift = {
            draft: true,
            new: true,
            name: "New Gift",
            type: "multi",
            description: "",
            amount_fmt: "",
            img_url: "",
            country_code: this.countryCode,
            currency: this.currency,
            img_url_stock: "",
            properties: {
              img_source: "img",
              pinned: false,
            },
          };
        }

        this.gifts.unshift(gift);

        this.clickedGift = this.gifts[0];
        this.clickedGiftIndex = 0;
        this.method = "add";
        this.slideOverOpen = true;
      }
    },

    closeSlide(params = null) {
      this.clickedGiftIndex = null;
      this.slideOverOpen = false;

      if (params && params.delete) {
        this.gifts.splice(params.index, 1);
      }

      this.clickedGift = null;
    },

    resetPage() {
      this.slideOverOpen = false;
      this.clickedGift = null;
      this.method = null;
    },

    updateGiftOrder() {
      let currentGiftOrder = this.gifts
        .filter((gift) => gift.hasOwnProperty("gift_id"))
        .map((gift) => gift.gift_id); // ignores new drafts without ignoring gifts that already existed currently in draft mode
      if (
        JSON.stringify(this.originalGiftOrder) !==
        JSON.stringify(currentGiftOrder)
      ) {
        this.$axiosAuth
          .patch(`host/event/${this.event_id}/`, {
            gift_order: currentGiftOrder,
          })
          .then((res) => {
            ElNotification({
              title: "Success",
              message: "Gift order updated",
              type: "success",
            });
            this.editingGiftOrder = false;
          })
          .catch((err) => {
            ElNotification({
              title: "Error",
              message: err.response.data,
              type: "error",
            });
          });
      }
    },
  },
};
</script>

<style scoped>
.drag:hover ~ .gift-overlay {
  opacity: 60%;
}
</style>
